import {Injectable} from "@angular/core";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {CouponTemplateStatus, CouponTemplateType, CouponTemplateTypeOfUseVO} from "../enum/coupons-enums";
import {CtDateAdapterService} from "@ctsolution/ct-framework";

@Injectable()
export class BusinessDiscountController {

  controller: string = '/BusinessDiscount/';

  constructor(private ctWebapiService: CtWebapiService) {
  }

  get(): Promise<Array<BusinessDiscount>> {

    return new Promise<Array<BusinessDiscount>>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}List`)

      this.ctWebapiService
        .get(request).subscribe((d: CtWebapiGenericResponse<any>) => resolve(<Array<BusinessDiscount>>d.Result))

    });

  }

  create(parameter: BusinessDiscount): Promise<any> {

    return new Promise<any>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}SpecialDiscount`)

      this.ctWebapiService
        .post(request, parameter).subscribe((d: CtWebapiGenericResponse<any>) => resolve(d.Result))

    });

  }

}

export class BusinessDiscount {

  public Status?: CouponTemplateStatus;
  public CustomerNote: string | null = null; // Il campo customer note è la motivazione del Rejected della richiesta da visualizzare solo se compilato e in quello stato.
  public CreateAt?: Date;
  public ApprovalDate?: Date;
  public OID: number | null = null;
  public Title: string | null = null;
  public Description: string | null = null;
  public Discount: number | null = null; // scontistica, da 1 a 100
  public TypeOfUse: CouponTemplateTypeOfUseVO | null = null;
  public Start: Date | null = null;
  public End: Date | string | null = null;

  Type?: CouponTemplateType;

  constructor(public ProductsOid: number, formValue: any) {

    if (formValue) {

      this.Title = formValue.Title;
      this.Description = formValue.Description;
      this.Discount = formValue.Discount;
      this.TypeOfUse = formValue.TypeOfUse;

      this.Start = this.formatDate(formValue.Start);
      this.End = this.formatDate(formValue.End);

    }

  }

  private formatDate = (date: Date) => new CtDateAdapterService().getTimeZoneOffsetFormat(date);

}
