import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './footer.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    CtFrameworkModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule {
}
