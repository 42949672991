import {Injectable} from "@angular/core";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {ICategory} from "../interfaces/category";

const CATEGORY_CONTROLLER: string = 'Category';

@Injectable({
  providedIn: 'root'
})
export class CategoryController {

  categories: ICategory[] = [];

  constructor(private ctWebapiService: CtWebapiService) {
  }

  list(numberOfElement?: number): Promise<ICategory[]> {

    const params: any = {hideSpinner: true}

    if (numberOfElement) params.numberOfelement = numberOfElement;

    return new Promise<ICategory[]>((resolve) => {

      if (this.categories.length > 0) {

        resolve(this.categories);
        return;

      }

      this.ctWebapiService
        .get(new DataRequest(`/${CATEGORY_CONTROLLER}/List`, params))
        .subscribe((response: CtWebapiGenericResponse<ICategory[]>) => {

          this.categories = response.Result;
          resolve(this.categories);

        });

    });

  }

}
