import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SpecialDiscountCreateComponent} from "./special-discount-create.component";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [
    SpecialDiscountCreateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatDialogModule,
    CtButtonModule,
    MatRadioModule,
    CtFrameworkModule,
    MatNativeDateModule
  ]
})
export class SpecialDiscountCreateModule {
}
