import { WeekDays } from "./week-days.enum";

export interface IDay {

    id: WeekDays;
    selected: boolean;

}

export const Days: IDay[] = [
    { id: WeekDays.MONDAY, selected: false },
    { id: WeekDays.TUESDAY, selected: false },
    { id: WeekDays.WEDNESDAY, selected: false },
    { id: WeekDays.THURSDAY, selected: false },
    { id: WeekDays.FRIDAY, selected: false },
    { id: WeekDays.SATURDAY, selected: false },
    { id: WeekDays.SUNDAY, selected: false }
]