import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {Product} from 'src/app/core/classes/product';
import {CtFileManagementService, IFile} from "@ctsolution/ct-file-management";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {ShopTimeTableComponent} from "./shop-time-table/shop-time-table.component";
import {Coupon} from "../../../../../../core/classes/coupon";
import {RoleHelperService} from "../../../../../../core/library/role-helper.service";
import {
  ScannerQrHelperService
} from "../../../../../personal-area/business-area/_components/qr-scanner/scanner-qr-helper.service";
import {
  CouponRequestService
} from "../../../../../personal-area/customer-area/coupon-section/VPoint-card/VPoint-coupon-request/coupon-request.service";
import {CouponHelperService} from "../../../../../../core/library/coupon-helper.service";
import {AppGeneralService} from "../../../../../../core/library/app-general.service";
import {CouponTemplateType} from "../../../../../../core/enum/coupons-enums";
import {FileType} from "../../../../../../core/enum/file-type.enum";
import {UserHelperService} from "../../../../../../core/library/user-helper.service";

@Component({
  selector: 'app-info-section',
  templateUrl: './info-section.component.html',
  styleUrls: ['./info-section.component.scss']
})
export class InfoSectionComponent implements OnInit {

  @Input() product: Product | null = null;
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

  standardButtons: CtButtonParameters[] = [];
  specialDiscountsButtons: CtButtonParameters[] = [];

  viewModel: any = {

    maxDiscount: 0,
    brochure: null,
    hasVPointsPurchasableCoupons: false

  }

  constructor(
    private generalService: AppGeneralService,
    private couponRequest: CouponRequestService,
    private ctFileManagementService: CtFileManagementService,
    private dialog: MatDialog,
    private couponHelper: CouponHelperService,
    private scannerQrHelperService: ScannerQrHelperService,
    public roleHelper: RoleHelperService,
    private userHelper: UserHelperService) {
  }

  ngOnInit() {

    this.setupProductCoupons();
    this.setupFiles();

  }

  setupProductCoupons() {

    const coupons: Coupon[] = (this.product?.Coupons ?? []).map((coupon: Coupon) => new Coupon(coupon));

    this.viewModel.maxDiscount = this.couponHelper.getMaxDiscountValueFromCouponList(coupons);

    this.viewModel.hasVPointsPurchasableCoupons = coupons.filter((coupon: Coupon) => coupon.Type === CouponTemplateType.Purchasable).length > 0;

    this.standardButtons = coupons.filter((coupon: Coupon) => coupon.Type === CouponTemplateType.Standard)
      .map((coupon: Coupon) => this.getDiscountButton(coupon.getCouponDiscountName(), () => this.generateQR(), "generalButton"));

    this.specialDiscountsButtons = coupons.filter((coupon: Coupon) => coupon.Type === CouponTemplateType.Special)
      .map((coupon: Coupon) => this.getDiscountButton(coupon.getCouponDiscountName(), () => this.specialDiscount(coupon), "generalButtonSpecial"));

  }

  getDiscountButton = (title: string, action: () => any, additionalCssClass?: string) => CtButtonParameters.create(() => {
    
    this.userHelper
      .checkIfUserProfileIsEnabled()
      .then((enabled: boolean) => {

        if (enabled) {

          action()

        }

      })

  })
    .setContent(title)
    .setCSS(additionalCssClass + " mb-2", true);

  setupFiles() {

    const files: IFile[] = this.product?.Files ?? [];

    const brochureFiles: IFile[] = this.ctFileManagementService.getFilesFromListByType(files, FileType.BROCHURE);

    if (brochureFiles.length > 0) this.viewModel.brochure = brochureFiles[0];

  }

  generateQR = () => this.scannerQrHelperService.openQrGenerator();

  specialDiscount(coupon: Coupon) {

    this.couponRequest
      .request(coupon)
      .afterClosed()
      .subscribe((result?: boolean) => {

        if (result) this.refresh.emit();

      })

  }

  downloadBrochure() {

    if (this.viewModel.brochure) {

      window.open(this.viewModel.brochure.PreviewUrl, '_blank');

    }

  }

  call = () => window.open('tel:' + this.product?.PhoneNumber, '_blank');

  googleMaps = () => window.open('https://maps.google.com/?q=' + this.product?.Place?.Name, '_blank');

  timeRates = () => this.dialog.open(ShopTimeTableComponent, {data: this.product})

}






