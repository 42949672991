<div class="info-section-cntr position-relative">

  <div class="container">

    <div class="mt-5 mb-5">

      <h1 class="title-stile mb-2">{{ product?.Name ?? '' }}</h1>

      <div class="d-flex justify-content-between">

        <app-price-category-button *ngIf="product" [price]="product"></app-price-category-button>

        <h2 *ngIf="viewModel.maxDiscount > 0" class="discount-content d-flex justify-content-end bold">

          {{'GENERAL.DISCOUNT_UNTIL'|translate:{discount: viewModel.maxDiscount} }}

        </h2>

      </div>

      <div class="mt-1">

        <div class="icon-text" (click)="timeRates()">

          <img src="/assets/images/icons/info-detail/clock.svg" class="info-detail-icon">
          {{ product?.IsOpen ? ['GENERAL.OPEN' | translate] :
          ['GENERAL.CLOSE' | translate] }}

        </div>

        <div *ngIf="product?.Place" class="icon-text" (click)="googleMaps()">

          <img src="/assets/images/icons/info-detail/location.svg" class="info-detail-icon">
          {{ product?.Place?.Name ?? ''}}

        </div>

        <div *ngIf="product?.PhoneNumber" class="icon-text" (click)="call()">

          <img src="/assets/images/icons/info-detail/phone.svg" style="height: 20px; width: auto; margin-right: 12px;">
          {{ product?.PhoneNumber ?? '' }}

        </div>

        <div *ngIf="viewModel.brochure" class="icon-text" (click)="downloadBrochure()">

          <img src="/assets/images/icons/info-detail/brochure.svg" class="info-detail-icon">
          {{ 'GENERAL.BROCHURE_DOWNLOAD' | translate }}

        </div>

      </div>

    </div>


    <ng-container *ngIf="roleHelper.isCustomer()">

      <div class="mb-5 w-75 m-auto">

        <ng-container *ngFor="let standard of standardButtons">

          <ct-button [parameters]="standard"></ct-button>

        </ng-container>

        <ng-container *ngFor="let specialDiscount of specialDiscountsButtons">

          <ct-button [parameters]="specialDiscount"></ct-button>

        </ng-container>

        <ng-container *ngIf="viewModel.hasVPointsPurchasableCoupons">

          <div class="text-center">

            <p [innerHTML]="['GENERAL.OR'|translate]"></p>

            <a class="link-stile primary-color" [routerLink]="['/personal-area/customer/coupons']"
               [queryParams]="{ ProductId: product?.Id, ProductName: product?.Name }"> {{ 'DETAIL.USE_VPOINT' | translate
              }}</a>

          </div>

        </ng-container>

      </div>

    </ng-container>

  </div>

  <hr/>

</div>
