import {Injectable} from "@angular/core";
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {CompletionStatusEnum} from "../enum/completion-status";

const DEFAULT_LANG = 'it';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor(private ctAuthenticationService: CtAuthenticationService) {
  }

  getBalance(): string {

    let balance = this.ctAuthenticationService.getJwtValueByContainedKey('MyVirtualPoint');

    if (balance) {

      return parseFloat(balance.replace(',', '.')).toFixed(2)

    }

    return '';

  }


  getNotificationsCount(): number {

    const notificationsNumber: string | null = this.ctAuthenticationService.getJwtValueByContainedKey('NotificationsToRead');

    if (!!notificationsNumber) {

      return +notificationsNumber;

    }

    return 0;

  }

  getEmail = (): string => this.ctAuthenticationService.getJwtValueByContainedKey('email');

  getName = (): string => this.ctAuthenticationService.getJwtValueByContainedKey('name');

  getSurname = (): string => this.ctAuthenticationService.getJwtValueByContainedKey('surname');

  getCompletionStatus = (): CompletionStatusEnum | null => {

    let completionStatus: string | null = this.ctAuthenticationService.getJwtValueByContainedKey('CompletionStatus');

    let toReturn: CompletionStatusEnum | null = null;

    if (completionStatus) {

      toReturn = <CompletionStatusEnum>+completionStatus;

    }

    return toReturn;

  }

  getUserAvatarSrc = (): string => {

    let avatarCode = this.ctAuthenticationService.getJwtValueByContainedKey('AvatarCode');

    if (avatarCode && avatarCode != '' && avatarCode != ' ') {

      return `assets/images/avatars/avatar_${avatarCode}.svg`;

    } else {

      return `assets/images/avatars/avatar_1.svg`;

    }

  }

  getBrokerCode = (): string => this.ctAuthenticationService.getJwtValueByContainedKey("ExternalCode");

  async getLocationOid() {

    await this.ctAuthenticationService.claimsUploaded();

    const locationOid: string | null = this.ctAuthenticationService.getJwtValueByContainedKey("LocationOid");

    if (locationOid) return +locationOid;

    return 0;

  }

  async getDefaultLang() {

    if (!this.ctAuthenticationService.userValue) return DEFAULT_LANG;

    await this.ctAuthenticationService.claimsUploaded();

    const languageCode: string | null = this.ctAuthenticationService.getJwtValueByContainedKey("LanguageCode");

    if (languageCode && languageCode.length > 0) return languageCode;

    return DEFAULT_LANG; // navigator.language || (<any>navigator)?.userLanguage ||

  }

}

