import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DiscountsManagerComponent} from './discounts-manager/discounts-manager.component';
import {CtButtonModule, CtFrameworkModule, CtSkeletonModule} from "@ctsolution/ct-framework";
import {MatLegacySliderModule as MatSliderModule} from "@angular/material/legacy-slider";

@NgModule({
  declarations: [
    DiscountsManagerComponent
  ],
  exports: [
    DiscountsManagerComponent
  ],
  imports: [
    CommonModule,
    CtFrameworkModule,
    MatSliderModule,
    CtButtonModule,
    CtSkeletonModule
  ]
})
export class DiscountsManagerModule {
}
