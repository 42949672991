import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchActiveFiltersComponent} from './search-active-filters/search-active-filters.component';
import {CtButtonModule} from "@ctsolution/ct-framework";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {ActiveFiltersListModule} from "./active-filters-list/active-filters-list.module";


@NgModule({
  declarations: [
    SearchActiveFiltersComponent
  ],
  exports: [
    SearchActiveFiltersComponent
  ],
    imports: [
        CommonModule,
        CtButtonModule,
        MatBottomSheetModule,
        ActiveFiltersListModule
    ]
})
export class SearchActiveFiltersModule {
}
