<div class="delete-image-cntr">
  <h1 mat-dialog-title> {{ 'COPY.WARNING_TITLE' | translate}}!</h1>

  <div mat-dialog-content>
    <p [innerHtml]="'COPY.DELETE_IMAGE' | translate">
    </p>
  </div>

  <div mat-dialog-actions>

    <button mat-raised-button class="warning-button" [mat-dialog-close]="false">
      {{'BUTTON.CANCEL'|translate}}
    </button>

    <button mat-raised-button class="generalButton" [mat-dialog-close]="true">
      {{'BUTTON.CONTINUE' | translate}}
    </button>

  </div>

</div>
