import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DiscountComponent} from "./discount.component";
import {SpecialDiscountListComponent} from "./special-discount-list/special-discount-list.component";
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {MatLegacyTabsModule as MatTabsModule} from "@angular/material/legacy-tabs";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {SpecialDiscountCreateModule} from "./special-discount-create/special-discount-create.module";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {DiscountListItemComponent} from './special-discount-list/discount-list-item/discount-list-item.component';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatDividerModule} from "@angular/material/divider";
import {MatLegacyChipsModule as MatChipsModule} from "@angular/material/legacy-chips";
import {RouterModule} from "@angular/router";
import {BusinessDiscountController} from "../../../../core/controllers/business-discount.controller";

@NgModule({
  declarations: [
    DiscountComponent,
    SpecialDiscountListComponent,
    DiscountListItemComponent
  ],
  imports: [
    CommonModule,
    CtButtonModule,
    MatTabsModule,
    MatListModule,
    SpecialDiscountCreateModule,
    MatDialogModule,
    MatCardModule,
    MatDividerModule,
    MatChipsModule,
    CtFrameworkModule,
    RouterModule.forChild([
      {
        path: '',
        component: DiscountComponent
      }
    ])
  ],
  providers: [
    BusinessDiscountController
  ]
})
export class DiscountModule {
}
