<div class="order-filter-cntr">

  <h3><b [innerHTML]="['FILTER.ORDERFILTER_TITLE' | translate]"></b></h3>

  <mat-radio-group class="row" [formControl]="control" (change)="selectValue()">

    <div
      *ngFor="let option of options"
      class="col-6 col-md-3">

      <mat-radio-button [value]="option.value">  {{ option.key | translate}}</mat-radio-button>

    </div>

  </mat-radio-group>

</div>
