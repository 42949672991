import {Component, OnInit} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {BusinessProductController} from 'src/app/core/controllers/business-product.controller';
import { FileService} from 'src/app/core/library/file.service';
import {IFile} from "@ctsolution/ct-file-management";
import { MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {FileType} from "../../../../../../../core/enum/file-type.enum";


@Component({
  selector: 'app-edit-gallery',
  templateUrl: './edit-gallery.component.html',
  styleUrls: ['./edit-gallery.component.scss']
})
export class EditGalleryComponent implements OnInit {


  viewModel: any = {

    product: null,

    btnBack: CtButtonParameters.create(() => this.dialogRef.close(this.viewModel.product))
      .setCSS("generalButton box-shadow", true)
      .setContent("CTBUTTON.BACKSHOP"),

    fileTypeToAccept: ".jpg, .jpeg, .png",

    uploadSettings: {

      box: [],
      counter: 6

    }

  }

  constructor(
    private businessProductController: BusinessProductController,
    private fileService: FileService,
    private dialogRef: MatDialogRef<EditGalleryComponent>
  ) {

    this.setupUploaderSection();

  }

  ngOnInit() {

    this.getProductData();

  }

  private setupUploaderSection() {

    this.viewModel.uploadSettings.box = [];
    for (let i = 0; i < this.viewModel.uploadSettings.counter; i++) {

      this.viewModel.uploadSettings.box.push({})

    }

  }

  getProductData() {

    this.businessProductController
      .list()
      .then((data: any) => {

        if (data.Result && data.Result.length > 0) {

          this.viewModel.product = data.Result[0];

        }

        this.setupProductImages();

      });


  }

  setupProductImages() {


    this.setupUploaderSection();

    if ((this.viewModel.product.Files ?? []).length > 0) {

      this.viewModel.product.Files
        .filter((element: IFile) => [FileType.OTHER, FileType.COVER].includes(element.Type))
        .forEach((file: IFile) => {

          let box: IFile = this.viewModel.uploadSettings.box[file.Position];

          if (box) this.viewModel.uploadSettings.box[file.Position] = file

        });

    }

  }

  manageFile(event: any, position: number) {

    if (this.viewModel.product?.Id) {

      let type: FileType = FileType.OTHER;

      if (position === 0) {

        type = FileType.COVER;

      }

      this.fileService
        .uploadFile(event, type, this.viewModel.product, position)
        .subscribe(() => this.getProductData());

    }

  }

  delete(file: IFile) {

    this.fileService
      .deleteFile(file)
      ?.subscribe(() => this.getProductData());

  }

}
