import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {CategoriesModule} from "./categories/categories.module";
import {SearchListModule} from "./search-list/search-list.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CategoriesModule,
    RouterModule.forChild([
      {
        path: '',
        children: [
          {path: 'list', loadChildren: () => SearchListModule},
          {path: 'categories', loadChildren: () => CategoriesModule},
        ]
      }
    ])
  ]
})
export class ResearchModule {
}
