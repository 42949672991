import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor() { }

  addTime(time: string, hourToAdd?: number, minutesToAdd?: number): string {

    let timeNew: Array<number> = [0, 0];
    let timeSplitted = time.split(':');

    if (hourToAdd) {

      timeNew[0] = +timeSplitted[0] + hourToAdd;
      timeNew[1] = +timeSplitted[1];

    }

    if (minutesToAdd) {

      timeNew[1] = +timeSplitted[1] + minutesToAdd;

    }

    let timeToReturn: string = '';

    timeToReturn = timeNew[0] <= 9 ? '0' + timeNew[0] + ':' : timeNew[0].toString() + ':';

    timeToReturn = timeNew[1] <= 9 ? timeToReturn + '0' + timeNew[1] : timeToReturn + timeNew[1].toString();

    return timeToReturn;

  }

}