export class TransactionData {

  public Code: string | null = null;

  constructor(
    public Amount: number = 0,
    public Place: Place
  ) {
  }

}


export class Place {

  public Region?: string;
  public Province?: string;
  public City?: string;
  public Country?: string;
  public PostalCode?: string;

  constructor(
    public Latitude: number = 44.405894703618856,
    public Longitude: number = 8.936481572029226,
    public Name?: string
  ) {
  }

}
