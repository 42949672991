import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CouponSectionComponent} from './coupon-section.component';
import {CtButtonModule, CtFrameworkModule, CtSkeletonModule} from '@ctsolution/ct-framework';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {VPointCardModule} from './VPoint-card/VPoint-card.module';
import {VpointLabelModule} from "../../../../components/vpoint-label/vpoint-label.module";
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {RouterModule} from "@angular/router";
import {UserPointBalanceModule} from "../../_components/user-point-balance/user-point-balance.module";
import { VpointFiltersComponent } from './vpoint-filters/vpoint-filters.component';
import {CategoriesListModule} from "../../../research/categories/categories-list/categories-list.module";
import { NoVpointsDialogComponent } from './no-vpoints-dialog/no-vpoints-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        CtFrameworkModule,
        MatCardModule,
        VPointCardModule,
        UserPointBalanceModule,
        CtButtonModule,
        VpointLabelModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        CtSkeletonModule,
        RouterModule.forChild([
            {
                path: '',
                component: CouponSectionComponent
            }
        ]),
        CategoriesListModule
    ],
  declarations: [CouponSectionComponent, VpointFiltersComponent, NoVpointsDialogComponent]
})
export class CouponSectionModule {
}
