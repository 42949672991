import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ChartConfiguration, ChartData, ChartEvent, ChartType} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

  @Input() chartType: ChartType = 'bar';
  @Input() chartData: ChartData = {
    labels: [],
    datasets: [
      {data: []}
    ]
  };
  @Input() chartOptions: ChartConfiguration['options'] = {};

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  constructor() {
  }

  ngOnInit() {
  }

  public barChartPlugins = [DataLabelsPlugin];

  public chartClicked({event, active}: { event?: ChartEvent, active?: {}[] }): void {
  }

  public chartHovered({event, active}: { event?: ChartEvent, active?: {}[] }): void {
  }

}
