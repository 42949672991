import { Injectable } from "@angular/core";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";

@Injectable()
export class NotificationController{

    private controller: string = '/Notification/';

    constructor(
        private ctWebApiService: CtWebapiService
    ){}

    list(): Promise<any> {

        return new Promise<any>((resolve) => this.ctWebApiService
            .get(new DataRequest(`${this.controller}List`)).subscribe((data: CtWebapiGenericResponse<any>) => resolve(data)));

    }
}
