import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SlideToggleConfiguration} from "./slide-toggle.configuration";

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent implements OnInit {

  @Input() configuration!: SlideToggleConfiguration;

  @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  onChangeEvent() {

    this.onChange.emit(this.configuration.control.value);

  }

}
