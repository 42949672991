import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompletePersonalDataEntryComponent } from './complete-personal-data-entry.component';
import { CtButtonModule, CtFrameworkModule } from '@ctsolution/ct-framework';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    CtFrameworkModule,
    CtButtonModule,
    MatIconModule
  ],
  declarations: [CompletePersonalDataEntryComponent],
  exports: [CompletePersonalDataEntryComponent]
})
export class CompletePersonalDataEntryModule { }
