import {Component, OnInit} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {AppGeneralService} from 'src/app/core/library/app-general.service';


@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {

  getProfileMenuButton = (label: string, action: any) => CtButtonParameters
    .create(() => action())
    .setCSS("menuIconBox personal-menu", true)
    .setMaterialIcon("keyboard_arrow_right")
    .setContent(label);


  buttons = [
    this.getProfileMenuButton("CTBUTTON.EDIT-PROFILE", () => this.appGeneralService.navigateTo('/personal-area/profile/edit')),
    this.getProfileMenuButton("CTBUTTON.EDIT-PSW", () => this.appGeneralService.navigateTo('/personal-area/profile/password')),
  ];

  constructor(private appGeneralService: AppGeneralService) {
  }

  ngOnInit() {
  }

}
