import {IFile} from "@ctsolution/ct-file-management";
import {Coupon} from "./coupon";
import {FileType} from "../enum/file-type.enum";
import {CouponRequestEnum} from "../enum/coupon-request";
import {CTBase} from "@ctsolution/ct-framework";

export class UserCoupon extends CTBase<UserCoupon> {

  public Code: string | null = null;
  public CouponRequest: CouponRequestEnum | null = null;
  public CreateAt: Date | null = null;
  public Files: IFile[] = [];
  public UpdateAt: number | null = null;
  public CouponTemplate: Coupon = new Coupon();
  public ProductOid: number = 0;

  override createListElementClassByProperty(value: any, attribute: string): IFile | null {

    switch (attribute) {

      case "Files":
        return value as IFile;

      default:
        super.createListElementClassByProperty(value, attribute);
        return null;

    }

  }

  constructor();
  constructor(model: UserCoupon);
  constructor(model?: UserCoupon) {

    super();

    if (model) {

      this.getClass(model);

    }

  }

  getLogo = () => this.Files.find((file: IFile) => file.Type === FileType.LOGO)?.PreviewUrl ?? '/assets/images/placeholder-images/generic-logo/logo-w-background.png';

}
