<div class="vPoint-rendeem-cntr container pb-2">

  <div class="ps-2 pe-2 mb-1 mt-2">

    <h1 class="primary-color text-center">{{coupon.Title}}</h1>

    <h2 class="description" *ngIf="coupon.Discount"
        [innerHTML]="['COUPON.PURCHASE-TITLE' | translate:{ percentage: coupon.Discount }]"></h2>

  </div>

  <div class="ps-2 pe-2 mb-3">

    <p [innerHTML]="coupon.Description"></p>

  </div>

  <div class="d-flex justify-content-between">

    <button mat-button [class]="coupon.Type ? 'background-primary-color' : 'background-special-discount'"
            class="purchase-button ms-1 me-1 mb-2" [innerHTML]="['COUPON.PURCHASE-RENDEEM' | translate]"
            (click)="redeem()"></button>

    <button mat-button (click)="save()"
            [class]="coupon.Type ? 'background-primary-color' : 'background-special-discount'"
            class="coupon-save ms-1 me-1 purchase-button"
            [innerHTML]="['COUPON.PURCHASE-SAVE' | translate]"></button>

  </div>

  <div class="mt-2">

    <p class="link-style" (click)="cancel()"> {{ 'BUTTON.CANCEL' | translate }}</p>

  </div>

</div>
