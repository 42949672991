import { Component, Input, OnInit} from '@angular/core';
import { CtExpansionPanelParameter } from '../ct-expansion-panel/class/CtExpansionPanelParameter';
import { CtFaqParameter } from './class/CtFaqParameters';


@Component({
  selector: 'app-ct-faq',
  templateUrl: './ct-faq.component.html',
  styleUrls: ['./ct-faq.component.scss']
})
export class CtFaqComponent implements OnInit {

 faqs:Array<CtFaqParameter> = [
  CtFaqParameter.Create("FAQ.QUESTION_1", "FAQ.ANSWER_1"),
  CtFaqParameter.Create("FAQ.QUESTION_2", "FAQ.ANSWER_2"),
  CtFaqParameter.Create("FAQ.QUESTION_3", "FAQ.ANSWER_3"),
  CtFaqParameter.Create("FAQ.QUESTION_4", "FAQ.ANSWER_4"),
  CtFaqParameter.Create("FAQ.QUESTION_5", "FAQ.ANSWER_5"),
  CtFaqParameter.Create("FAQ.QUESTION_6", "FAQ.ANSWER_6"),
  CtFaqParameter.Create("FAQ.QUESTION_7", "FAQ.ANSWER_7"),
  CtFaqParameter.Create("FAQ.QUESTION_8", "FAQ.ANSWER_8"),
  CtFaqParameter.Create("FAQ.QUESTION_9", "FAQ.ANSWER_9"),
  CtFaqParameter.Create("FAQ.QUESTION_10", "FAQ.ANSWER_10"),
  CtFaqParameter.Create("FAQ.QUESTION_11", "FAQ.ANSWER_11")
 ]
    
  constructor(
   
  ) {}

  ngOnInit() {
  }

  getExpansionPanel(faq: CtFaqParameter): CtExpansionPanelParameter{

    var panel = CtExpansionPanelParameter.Create("").setTitle(faq.title);

    if(faq.description){

      panel.setDescription(faq.description)

    }
    
    return panel;
  }

}











