import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {SearchFiltersComponent} from "../../search-filters/search-filters/search-filters.component";

@Component({
  selector: 'app-search-active-filters',
  templateUrl: './search-active-filters.component.html',
  styleUrls: ['./search-active-filters.component.scss']
})
export class SearchActiveFiltersComponent implements OnInit {

  @Output() toggleMapHeight: EventEmitter<any> = new EventEmitter<any>();

  btnShowAllFilters: CtButtonParameters = CtButtonParameters
    .create(() => this.openFiltersPanel())
    .setImage('/assets/images/icons/filter.svg')
    .setCSS('mat-raised-button mat-icon-button')
    .setImageCss('generalFilterIcon');

  constructor(private _bottomSheet: MatBottomSheet) {
  }

  ngOnInit(): void {
  }

  onAccordionClick = () => this.toggleMapHeight.emit();

  openFiltersPanel() {

    this._bottomSheet
      .open(SearchFiltersComponent, {panelClass: 'search-filters-panel', disableClose: true})

  }

}
