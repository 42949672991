<div class="button-section-cntr container">

  <div class="row">

    <div class="col-3">

      <ct-button [parameters]="mapButton"></ct-button>

    </div>

  </div>

  <div class="row">

    <ng-container
      *ctSkeleton="viewModel.loading;
           repeat: 6;
           className: 'button-section-skeleton';">

      <div class="col-4 text-center p-1" *ngFor="let button of categoryListButtons">

        <ct-button [parameters]="button"></ct-button>

      </div>

    </ng-container>

  </div>

</div>
