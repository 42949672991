import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrderFilterComponent} from './order-filter.component';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CtFrameworkModule} from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    CtFrameworkModule
  ],
  declarations: [OrderFilterComponent],
  exports: [OrderFilterComponent]
})
export class OrderFilterModule {
}
