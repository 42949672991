import {Component, EventEmitter, Output} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {PriceCategoryEnum} from "../../../../../../../../core/enum/price-category.enum";

const DEFAULT_BUTTON_CSS: string = 'filterIcon mat-icon-button text-center';
const COUNTER: number = 3;
const CURRENCY: string = '€';

@Component({
  selector: 'app-price-filter',
  templateUrl: './price-filter.component.html',
  styleUrls: ['./price-filter.component.scss']
})
export class PriceFilterComponent {

  @Output() update: EventEmitter<PriceCategoryEnum | null> = new EventEmitter<PriceCategoryEnum | null>();

  buttons: Array<PriceCategoryChip> = [
    {
      value: PriceCategoryEnum.ReallyCheap,
      parameters: CtButtonParameters.create(() => this.select(PriceCategoryEnum.ReallyCheap)),
      selected: false
    },
    {
      value: PriceCategoryEnum.Economic,
      parameters: CtButtonParameters.create(() => this.select(PriceCategoryEnum.Economic)),
      selected: false
    },
    {
      value: PriceCategoryEnum.Moderate,
      parameters: CtButtonParameters.create(() => this.select(PriceCategoryEnum.Moderate)),
      selected: false
    },
  ]

  constructor() {

    this.setup();

  }

  select(value: PriceCategoryEnum | null) {

    this.buttons.forEach((elm: PriceCategoryChip) => elm.selected = elm.value === value);
    this.update.emit(value);

  }

  getSelectedValue = (): PriceCategoryEnum | undefined => this.buttons.find((elm: PriceCategoryChip) => elm.selected)?.value;

  setup() {

    for (let i = 0; i < COUNTER; i++) {

      this.buttons
        .forEach((element: PriceCategoryChip) => {

          element.parameters
            .setContent(this.getIcon(element.value))
            .setCSS(DEFAULT_BUTTON_CSS)

        });

    }

  }

  private getIcon(value: PriceCategoryEnum): string {

    switch (value) {

      case PriceCategoryEnum.ReallyCheap:
      default:
        return CURRENCY;

      case PriceCategoryEnum.Economic:
        return CURRENCY + CURRENCY;

      case PriceCategoryEnum.Moderate:
        return CURRENCY + CURRENCY + CURRENCY;

    }

  }

}

interface PriceCategoryChip {
  value: PriceCategoryEnum,
  parameters: CtButtonParameters,
  selected: boolean
}
