import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CategoriesListComponent} from './categories-list/categories-list.component';
import {CtButtonModule, CtFrameworkModule, CtSkeletonModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    CategoriesListComponent
  ],
  exports: [
    CategoriesListComponent
  ],
  imports: [
    CommonModule,
    CtButtonModule,
    CtSkeletonModule,
    CtFrameworkModule  ]
})
export class CategoriesListModule {
}
