import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UserCoupon} from "../../../../../../core/classes/user-coupon";
import {ProfileController} from "../../../../../../core/controllers/profile.controller";
import {CouponTemplateType} from "../../../../../../core/enum/coupons-enums";
import {MyCouponConfiguration} from "./my-coupon/my-coupon.configuration";
import {Coupon} from "../../../../../../core/classes/coupon";

const MY_VICTORIA_CARD: UserCoupon = new UserCoupon(<UserCoupon>{
  CouponTemplate: {
    Title: 'La mia Victoria Card',
    Description: 'Usa la tua card per generare sconti',
    Type: CouponTemplateType.MyVictoriaCard
  }
});

@Component({
  selector: 'app-my-coupons-list',
  templateUrl: './my-coupons-list.component.html',
  styleUrls: ['./my-coupons-list.component.scss'],
  providers: [ProfileController]
})
export class MyCouponsListComponent implements OnInit {

  private createCouponConfigurationCarouselByUserCouponInterface = (userCoupon: UserCoupon): MyCouponConfiguration => MyCouponConfiguration.create(userCoupon);

  viewModel: { loading: boolean, coupons: MyCouponConfiguration[], carouselSettings: any } = {

    loading: false,
    coupons: new Array<MyCouponConfiguration>(this.createCouponConfigurationCarouselByUserCouponInterface(MY_VICTORIA_CARD)),
    carouselSettings: {
      dots: false,
      cellWidth: 330,
      height: 235,
      arrows: true,
      arrowsOutside: true,
      autoplay: false
    }

  }

  resetCoupons = () => this.viewModel.coupons = new Array<MyCouponConfiguration>(this.createCouponConfigurationCarouselByUserCouponInterface(MY_VICTORIA_CARD));

  constructor(private profileController: ProfileController, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {

    this.getCoupons();

  }

  ngAfterViewInit() {

    this.cdr.detectChanges();

  }

  getCoupons() {

    this.viewModel.loading = true;

    this.resetCoupons();

    this.profileController
      .coupon()
      .then((result: Array<UserCoupon>) => {

        if (result.length > 0) {

          const couponsConfigurations: MyCouponConfiguration[] = result.map((userCoupon: UserCoupon) => this.createCouponConfigurationCarouselByUserCouponInterface(new UserCoupon(userCoupon)));
          this.viewModel.coupons = this.viewModel.coupons.concat(couponsConfigurations);

        }

        this.viewModel.loading = false;

      })

  }

  isVisibleInViewport(id: string) {

    const element = document.getElementById(id);
    const rect = element?.getBoundingClientRect();

    if (!rect) return false;

    return (
      rect!.top >= 0 &&
      rect!.left >= 0 &&
      rect!.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect!.right <= (window.innerWidth || document.documentElement.clientWidth)
    );

  }

}
