import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { AppGeneralService } from 'src/app/core/library/app-general.service';
import { ContactsComponent } from './contacts/contacts.component';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  getProfileMenuButton = (label: string, action: any, icon: string) => CtButtonParameters
  .create(() => action())
  .setCSS("menuIconBox personal-menu", true)
  .setMaterialIcon(icon)
  .setContent(label);


buttons = [
  this.getProfileMenuButton("CTBUTTON.FAQ", () => this.appGeneralService.navigateTo('/faq'), "live_help"),
  this.getProfileMenuButton("GENERAL.CONTACTS", () => this.openContactModule(), "contact_phone"),
];

  constructor(private appGeneralService: AppGeneralService, private dialog: MatDialog) { }

  ngOnInit() {
  }

  
  private openContactModule() {

    this.dialog
    .open(ContactsComponent);

  }

}
