import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtFaqComponent } from './ct-faq.component';
import { CtExpansionPanelModule } from '../ct-expansion-panel/ct-expansion-panel.module';
import {CtFrameworkModule} from "@ctsolution/ct-framework";

@NgModule({
  imports: [
    CommonModule,
    CtExpansionPanelModule,
    CtFrameworkModule
  ],
  declarations: [CtFaqComponent],
  exports: [CtFaqComponent]
})
export class CtFaqModule { }
