<section class="notification-area-cntr">

  <mat-card>

    <ng-container *ngIf="notifications.length > 0; else noNotificationsTmpl">

      <mat-list role="list" class="row">

        <mat-list-item role="listitem" *ngFor="let content of notifications">

          <div class="col-11">

            <app-notification-content [content]="content"></app-notification-content>

          </div>

        </mat-list-item>

      </mat-list>

    </ng-container>

    <ng-template #noNotificationsTmpl>

      <small>

        <strong>{{'GENERAL.NO_AVAILABLE_NOTIFICATIONS' | translate }}</strong>

      </small>

    </ng-template>

  </mat-card>

</section>
