import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BusinessCreateComponent} from './business-create.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {EditGalleryModule} from './sections/images-section/edit-gallery/edit-gallery.module';
import {GeneralDataModule} from './sections/general-data/general-data.module';
import {FileUploadModule} from './sections/file-upload/file-upload.module';
import {SubcategoriesListModule} from './sections/subcategories-list/subcategories-list.module';
import {ImagesSectionModule} from './sections/images-section/images-section.module';
import {OpeningDatasModule} from './sections/opening-datas/opening-datas.module';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {RouterModule} from "@angular/router";
import {
  PriceFilterModule
} from 'src/app/pages/research/search-list/search-list/_components/search-filters/_components/price-filter/price-filter.module';

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    EditGalleryModule,
    FileUploadModule,
    GeneralDataModule,
    ImagesSectionModule,
    OpeningDatasModule,
    SubcategoriesListModule,
    CtFrameworkModule,
    MatIconModule,
    MatButtonModule,
    PriceFilterModule,
    RouterModule.forChild([
      {
        path: '',
        component: BusinessCreateComponent
      }
    ])
  ],
  declarations: [BusinessCreateComponent]
})
export class BusinessCreateModule {
}
