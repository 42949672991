<div class="shop-time-table-cntr">

  <ng-container *ngIf="viewModel.openingDays.length > 0; else noTmpl">

    <h1 class="text-center primary-color">{{'GENERAL.OPENING_DAYS' | translate}}</h1>

    <div class="mt-4 mb-4">

  <div class="row" *ngFor="let openingDay of viewModel.openingDays">

    <div class="col-5">

      <p><b> {{ 'WEEKDAYS.' + openingDay | translate }} : </b></p>

    </div>
    <div class="col-7">

      <p *ngIf="viewModel.openingTime" [innerHtml]="viewModel.openingTime"></p>

    </div>

  </div>

    </div>

  </ng-container>

</div>

<ng-template #noTmpl>  <h2> {{ 'SHOP.CLOSED' | translate }} </h2> </ng-template>

<div>

  <ct-button [parameters]="closeDialog"></ct-button>

</div>
