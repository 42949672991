import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CtFrameworkModule} from '@ctsolution/ct-framework';
import {DistanceFilterComponent} from './distance-filter.component';

@NgModule({
  imports: [
    CommonModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    CtFrameworkModule
  ],
  declarations: [DistanceFilterComponent],
  exports: [DistanceFilterComponent]
})
export class DistanceFilterModule {
}
