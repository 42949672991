import {Component, Input, OnInit} from '@angular/core';
import {Coupon} from 'src/app/core/classes/coupon';
import {TransactionResultDto} from 'src/app/core/classes/transaction-result-dto';
import {CouponQuote, TransactionController} from 'src/app/core/controllers/transaction.controller';
import {CouponTemplateType} from 'src/app/core/enum/coupons-enums';
import {TransactionStatus} from 'src/app/core/enum/transaction-status.enum';
import {TransactionCardParameter} from "../../../../_components/transaction-card/class/transaction-card-parameter";

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  transactionCards: Array<TransactionCardParameter> = []

  constructor(private transactionController: TransactionController
  ) {
  }

  ngOnInit() {
    this.transactionController.listInfos().then(data => {

      var result = data.Result as Array<TransactionResultDto>

      if (result) {

        const successTransaction = result.filter(transaction => transaction.Status == TransactionStatus.Success);

        successTransaction.forEach(transaction => {

          this.transactionCards.push(
            TransactionCardParameter
              .Create(transaction.ProductName)
              .setDate(new Date(transaction.CreateAt).toLocaleDateString())
              .setLocation(transaction.City)
              .setSaving(`${this.getSale(transaction)}€`)
              .setIconPath(transaction.Files?.shift() ?? "")
              .setVirtualPoint(transaction.VirtualPoint)
          );

        });
      }

    })
  }


  private getSale(transaction: TransactionResultDto): string {
    
    return ((transaction.Amount - transaction.TotalAmount))?.toFixed(2);

  }
}
