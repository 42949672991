import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {ProductController} from "../../../../core/controllers/product.controller";
import {Product} from "../../../../core/classes/product";
import {SearchService} from "./_components/search-filters/search-filters/search.service";
import {ActivatedRoute} from "@angular/router";
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {AppGeneralService} from "../../../../core/library/app-general.service";
import {GoogleAreaBounce, MapsComponent} from "./_components/maps/maps.component";

export const lastIndexKey = 'lastIndex';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss']
})
export class SearchListComponent {

  ID_PREFIX: string = 'CARD_';
  list: Array<Product> = new Array<Product>();

  @ViewChild(MapsComponent) map: MapsComponent | null = null;

  viewModel: any = {
    loading: false,
    isMapExpanded: null
  }

  constructor(
    private productController: ProductController,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private ctAuthenticationService: CtAuthenticationService,
    private cdr: ChangeDetectorRef,
    private general: AppGeneralService) {

    this.setup()

  }

  async setup() {

    await this.ctAuthenticationService.claimsUploaded();

    this.activatedRoute
      .queryParams
      .subscribe((qp: any) => {

        this.searchService.reset();

        Object
          .keys(qp)
          .forEach((key: string) => this.searchService.filters.setValue(key, qp[key]))

        this.search(true);

      });

  }

  search(bypassCache: boolean = false) {

    this.viewModel.loading = true;

    this.productController
      .list(this.searchService.filters, bypassCache)
      .then((productList: Product[]) => {

        this.list = productList;
        this.viewModel.loading = false;

        this.scrollToLastCard();

      });

  }

  onCenterChange(area: GoogleAreaBounce) {

    this.searchService
      .filters
      .setAreaBounce(area);

    this.search(true);

  }

  adjustMapHeightOnClick() {

    this.viewModel.isMapExpanded = !this.viewModel.isMapExpanded;

    setTimeout(() => this.cdr.detectChanges(), 350)

  }

  orderBySelectedProduct(product: Product) {

    const targetIndex = this.list.findIndex(item => item.Id === product.Id);

    if (targetIndex !== -1) {

      const targetItem = this.list.splice(targetIndex, 1)[0];
      this.list.unshift(targetItem);

    }

  }

  scrollToLastCard() {

    const lastIndex = sessionStorage.getItem(lastIndexKey);

    if (!lastIndex) return;

    sessionStorage.removeItem(lastIndexKey);

    setTimeout(() => {

      const id = `${this.ID_PREFIX}${lastIndex}`;
      this.general.scrollToElementById(id);

    }, 100)

  }

}
