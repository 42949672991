import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { StatisticsController } from 'src/app/core/controllers/statistics.controller';
@Component({
  selector: 'app-savings',
  templateUrl: './savings.component.html',
  styleUrls: ['./savings.component.scss']
})
export class SavingsComponent implements OnInit {

  chartType: ChartType = 'polarArea';

  chartBackgroundColors: any = [];

  chartBorderColors: any = [];

  categories = [

    '#0584C7',
    '#0AB1F2',
    '#1ED6A5',    
    '#F7425E',
    '#FFB81A',
    '#C5468C',
    '#7F5498',
    '#415481',
    '#00765D',
    '#00812F',
    '#248800',
    '#754C44',
    '#E9E937'
  ]

 

  chartData: ChartData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          
        ]
      }
    ]
  }



  chartOptions: ChartConfiguration['options'] = {


    responsive: true,
    plugins: {

      legend: {

        display: true,
        position: 'left',
      
        labels: {

          color: '#000000',
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
                         
        },

      },
      datalabels: {
        anchor: 'center',
        align: 'center'
      }
    }
  };

  totalEarnings: number = 0;

  constructor(
    private statisticsController: StatisticsController,
    private translateService: TranslateService
  ) {

    this.statisticsController.savingsByCategory().then(data => {

      this.chartData.labels = data.map((x: any) => this.translateService.instant('CATEGORY.' + `${x.CategoryCode}`));

      this.chartData.datasets[0].data = data.map((x: any) => x.Total);

       this.chartData.datasets[0].backgroundColor = this.categories;

       this.chartData.datasets[0].borderColor = this.categories;

      this.totalEarnings = this.chartData.datasets[0].data.reduce(
        (previousValue: any, currentValue: any) => previousValue + currentValue,
        this.totalEarnings
      );

    });

  }

  ngOnInit() {
   }

}

