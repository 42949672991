import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchCardComponent} from './search-card/search-card.component';
import {CtFrameworkModule} from "@ctsolution/ct-framework";
import {PriceCategoryButtonModule} from "../_components/price-category-button/price-category-button.module";
import {MatIconModule} from "@angular/material/icon";
import {ManageProductButtonModule} from "../_components/manage-product-button/manage-product-button.module";

@NgModule({
  declarations: [
    SearchCardComponent
  ],
  exports: [
    SearchCardComponent
  ],
  imports: [
    CommonModule,
    ManageProductButtonModule,
    CtFrameworkModule,
    PriceCategoryButtonModule,
    MatIconModule
  ]
})
export class SearchCardModule {
}
