<div class="photo-gallery-cntr position-relative">

  <ct-carousel-ivy [parameter]="carouselParameter"></ct-carousel-ivy>

  <div class="edit-button-cntr">

    <ct-button [parameters]="editHeaderImages"></ct-button>

  </div>

  <app-logo-business-create [product]="productData" (refresh)="getProductData()"></app-logo-business-create>

</div>
