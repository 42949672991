import {Component, OnInit} from '@angular/core';
import {NotificationResult} from 'src/app/core/classes/notification';
import {NotificationController} from 'src/app/core/controllers/notification.controller';
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";


@Component({
  selector: 'app-notification-area',
  templateUrl: './notification-area.component.html',
  styleUrls: ['./notification-area.component.scss']
})
export class NotificationAreaComponent implements OnInit {

  notifications: Array<NotificationResult> = new Array<NotificationResult>();

  constructor(private notificationController: NotificationController, private ctAuthenticationService: CtAuthenticationService) {
  }

  ngOnInit() {

    this.getNotifications();

  }

  getNotifications() {

    this.notificationController
      .list()
      .then(((data: CtWebapiGenericResponse<any>) => {

        this.notifications = data.Result;

        this.ctAuthenticationService
          .getClaims(); // per aggiornare la notifica nel menu

      }));


  }

}


