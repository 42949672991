<div class="row">
  <div class="mt-auto mb-auto col-8">

    <h3>
      <strong> {{ viewModel.name }} {{ viewModel.surname ?? '' }} </strong>
    </h3>

    <p>
      {{ viewModel.email }}
    </p>

  </div>

  <div class="mt-auto mb-auto col-4">

    <div class="profile-image-cntr">

      <ct-button [parameters]="personalImageButton"></ct-button>

    </div>

  </div>

</div>
