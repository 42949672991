import {Injectable} from "@angular/core";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";

import {TransactionData} from "../classes/transactionData";

@Injectable()

export class TransactionController {

  private controller: string = '/Transaction/';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  initialize(transactionData: TransactionData): Promise<CtWebapiGenericResponse<any>> {
    let request: DataRequest = new DataRequest(`${this.controller}initialize`, {hideSpinner: true});
    return new Promise<CtWebapiGenericResponse<any>>((resolve) => this.ctWebApiService.post(request, transactionData).subscribe(d => {
      resolve(d as CtWebapiGenericResponse<any>)
    }));
  }

  bind(transactionCode: string): Promise<CtWebapiGenericResponse<any>> {

    const request: DataRequest = new DataRequest(`${this.controller}bind`, {
      TransactionCode: transactionCode,
      hideError: true,
      hideSpinner: true
    });

    return new Promise<CtWebapiGenericResponse<any>>((resolve, reject) => {

      this.ctWebApiService
        .get(request)
        .subscribe({

          next: (result: CtWebapiGenericResponse<any>) => resolve(result),
          error: (error: any) => reject(error)

        })

    });

  }

  success(parameter: TransactionRequestParameter): Promise<CtWebapiGenericResponse<any>> {

    const request: DataRequest = new DataRequest(`${this.controller}success`);

    return new Promise<CtWebapiGenericResponse<any>>((resolve) => this.ctWebApiService.post(request, parameter).subscribe(d => resolve(d as CtWebapiGenericResponse<any>)));

  }

  cancelled(parameter: TransactionRequestParameter): Promise<CtWebapiGenericResponse<any>> {

    const request: DataRequest = new DataRequest(`${this.controller}cancelled`);

    return new Promise<CtWebapiGenericResponse<any>>((resolve) => this.ctWebApiService.post(request, parameter).subscribe(d => resolve(d as CtWebapiGenericResponse<any>)));

  }

  infos(transactionCode: string): Promise<CtWebapiGenericResponse<any>> {
    let request: DataRequest = new DataRequest(`${this.controller}infos`, {
      TransactionCode: transactionCode,
      hideSpinner: true
    });
    return new Promise<CtWebapiGenericResponse<any>>((resolve) => this.ctWebApiService.get(request).subscribe(d => resolve(d as CtWebapiGenericResponse<any>)));
  }

  listInfos(): Promise<CtWebapiGenericResponse<any>> {
    let request: DataRequest = new DataRequest(`${this.controller}listInfos`);
    return new Promise<CtWebapiGenericResponse<any>>((resolve) => this.ctWebApiService.get(request).subscribe(d => resolve(d as CtWebapiGenericResponse<any>)));
  }

  listInfosBusiness(): Promise<CtWebapiGenericResponse<any>> {
    let request: DataRequest = new DataRequest(`${this.controller}listInfosBusiness`);
    return new Promise<CtWebapiGenericResponse<any>>((resolve) => this.ctWebApiService.get(request).subscribe(d => resolve(d as CtWebapiGenericResponse<any>)));
  }
}

export class TransactionRequestParameter {

  constructor(
    public TransactionCode: string,
    public Quotes: CouponQuote[] = []
  ) {
  }

}

export class CouponQuote {

  constructor(
    public CouponOid: number,
    public Quote: number = 0
  ) {
  }

}
