import {Routes} from '@angular/router';
import HomeComponent from './pages/home/home.component';
import {CtAuthenticationGuard} from '@ctsolution/ct-authentication';
import {FullComponent} from "./layout/full/full.component";

import {PersonalAreaModule} from "./pages/personal-area/personal-area.module";
import {AuthenticationModule} from './authentication/authentication.module';
import {ResearchModule} from "./pages/research/research.module";
import { SupportComponent } from './pages/support/support.component';
import { CtFaqComponent } from './components/ct-framework/ct-faq/ct-faq.component';

export const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {path: 'home', component: HomeComponent},
      {
        path: 'research',
        loadChildren: () => ResearchModule
      },
      {
        path: 'personal-area',
        canActivate: [CtAuthenticationGuard],
        loadChildren: () => PersonalAreaModule
      },
      {
        path: 'authentication',
        loadChildren: () => AuthenticationModule
      },
      {path: 'support', component: SupportComponent},
      {path: 'faq', component: CtFaqComponent},
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
