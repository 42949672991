import {Component, OnDestroy, OnInit} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {DrawerService} from 'src/app/layout/drawer.service';
import {AppGeneralService} from 'src/app/core/library/app-general.service';
import {CtAuthenticationService} from '@ctsolution/ct-authentication';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {JwtService} from "../../../core/library/jwt.service";
import {RoleHelperService} from "../../../core/library/role-helper.service";
import {ProfileController} from "../../../core/controllers/profile.controller";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SignOutDialogComponent } from './sign-out-dialog/sign-out-dialog.component';
import {SlideToggleConfiguration} from "../../../components/ct-framework/slide-toggle/slide-toggle.configuration";
import { GuideComponent } from 'src/app/pages/guide/guide.component';

@Component({
  selector: 'app-menu-navigation-buttons',
  templateUrl: './menu-navigation-buttons.component.html',
  styleUrls: ['./menu-navigation-buttons.component.scss']
})
export class MenuNavigationButtonsComponent implements OnInit, OnDestroy {

  //#region menu buttons

    getNavigationMenuButton = (label: string, action: any, image: string) => CtButtonParameters
    .create(() => action())
    .setCSS("menu-content-box mat-icon-button general-menu-icon", true)
    .setImage(image)
    .setImageCss('box-shadow')
    .setContent(label);

  profileButton = this.getNavigationMenuButton("CTBUTTON.PROFILE", () => this.redirect('/personal-area/profile'), "/assets/images/icons/menu/account-active.svg");
  favoriteButton = this.getNavigationMenuButton("CTBUTTON.FAVORITE", () => this.redirect('/personal-area/customer/favorites'), "/assets/images/icons/menu/favorite-icon.svg");
  notificationsButton = this.getNavigationMenuButton("CTBUTTON.NOTIFICATION", () => this.redirect('/personal-area/notifications'), "/assets/images/icons/menu/notifications.svg");
  friendsButton = this.getNavigationMenuButton("CTBUTTON.MENU-FRIENDS", () => this.redirect('/personal-area/customer/share'), "/assets/images/icons/menu/friends.svg");
  reportButton = this.getNavigationMenuButton("CTBUTTON.REPORT", () => this.redirect('/personal-area/business/reports'), "/assets/images/icons/menu/statistics.svg");
  faqButton = this.getNavigationMenuButton("CTBUTTON.SUPPORT", () => this.redirect('/support'), "/assets/images/icons/menu/support.svg");
  guideButton = this.getNavigationMenuButton("CTBUTTON.GUIDE", () => this.openGuide(), "/assets/images/icons/menu/guide.svg");
  
  logoutButton = this.getNavigationMenuButton("CTBUTTON.EXIT", () => this.signOut(), "/assets/images/icons/menu/logout.svg");
  itButton = this.getNavigationMenuButton("CTBUTTON.LANGITA", () => this.setLanguage('it'), "/assets/images/flags/italy.png");
  enButton = this.getNavigationMenuButton("CTBUTTON.LANGENG", () => this.setLanguage('en'), "/assets/images/flags/united-kingdom.png");

  termsConditionsButton: CtButtonParameters = CtButtonParameters
  .create((event) => window.open('/assets/privacy_policy.pdf', '_blank'))
    .setCSS("link-style", true)
    .setContent("SETTINGS.TERMS_CONDITIONS");
  //#endregion

  slideToggleConfiguration = SlideToggleConfiguration.create();

  subscription?: Subscription;

  viewModel: any = {

    loggedUser: false,
    businessProfile: false,
    notificationCount: null

  }

  constructor(
    private ctAuthenticationService: CtAuthenticationService,
    private appGeneralService: AppGeneralService,
    private drawerService: DrawerService,
    private translate: TranslateService,
    private jwt: JwtService,
    private roleHelper: RoleHelperService,
    private profileController: ProfileController,
    private dialog: MatDialog
  ) {

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => this.setupViewModel())

  }

  private setupViewModel() {

    this.viewModel.loggedUser = !!this.ctAuthenticationService.userValue;
    this.viewModel.businessProfile = this.roleHelper.isBusiness();
    this.viewModel.customerProfile = this.roleHelper.isCustomer();

    this.slideToggleConfiguration
      .setControlValue(this.viewModel.customerProfile)
      .setPrefixLabel("OFF")
      .setLabel("ON");

    const notificationCount: number = this.jwt.getNotificationsCount();
    this.viewModel.notificationCount = notificationCount ? notificationCount : null;

  }

  ngOnInit() {
  }

  setLanguage(lang: string) {

    if (this.ctAuthenticationService.userValue) {

      this.profileController
        .changeLanguage(lang)
        .then(() => {

          this.ctAuthenticationService
            .getClaims()

        })

    } else {

      this.translate.use(lang)

    }

  }

  private signOut() {

    this.dialog
    .open(SignOutDialogComponent);

  }

  private openGuide(){
    this.dialog.open(GuideComponent, {panelClass: 'dialog-guide-container'})
  }

  private redirect(url: string) {

    this.appGeneralService
      .navigateTo(url)
      .then(() => this.drawerService.toggle());

  }

  toggleUserMode = () => this.roleHelper.toggleUserMode();

  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }


}
