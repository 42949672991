import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimeSelectComponent} from './time-select.component';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {CtFrameworkModule} from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgxMatTimepickerModule,
    ReactiveFormsModule,
    CtFrameworkModule
  ],
  declarations: [TimeSelectComponent],
  exports: [TimeSelectComponent]
})
export class TimeSelectModule {
}
