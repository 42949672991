import {Component, OnInit} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {AppGeneralService} from 'src/app/core/library/app-general.service';

@Component({
  selector: 'app-activities-section',
  templateUrl: './activities-section.component.html',
  styleUrls: ['./activities-section.component.scss']
})
export class ActivitiesSectionComponent implements OnInit {

  vpoints: CtButtonParameters = CtButtonParameters
    .create((event) => this.appGeneralService.navigateTo('/personal-area/customer/coupons'))
    .setCSS('generalButton')
    .setContent('CTBUTTON.REDEEM-VPOINTS');

  status: boolean = false;
  toggleActionsViewButton: CtButtonParameters = CtButtonParameters
    .create((event) => this.toggleActionsView())
    .setCSS('generalButton mt-4 mb-4')
    .setContent('CTBUTTON.SHOW-ACTIONS')

  constructor(private appGeneralService: AppGeneralService) {
  }

  ngOnInit() {
  }

  toggleActionsView() {

    this.status = !this.status;
    this.toggleActionsViewButton.setContent(!this.status ? 'CTBUTTON.SHOW-ACTIONS' : 'CTBUTTON.HIDE-ACTIONS');

  }


}
