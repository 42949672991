import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-remove-filter-button',
  templateUrl: './remove-filter-button.component.html',
  styleUrls: ['./remove-filter-button.component.scss']
})
export class RemoveFilterButtonComponent implements OnInit {

  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

}
