import { Injectable } from "@angular/core";
import { CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";
import { AppGeneralService } from "../library/app-general.service";
import {RegistrationGuest} from "../classes/registration-guest";

@Injectable()
export class AccountController {

    controller: string = '/Account/';

    params: any = {}

    constructor(
        private appGeneralService: AppGeneralService,
        private ctWebapiService: CtWebapiService
    ) { }

    registration(registrationForm : RegistrationGuest): void {

        this.ctWebapiService.post(new DataRequest(`${this.controller}RegistrationGuest`),registrationForm)
            .subscribe((r: any) => {

                this.appGeneralService.navigateTo('/authentication/sign-up/welcome');

            });

    }

}
