import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {CtButtonParameters, FormHelperService, passwordStrengthValidator} from '@ctsolution/ct-framework';
import {AccountController} from 'src/app/core/controllers/account.controller';
import {CtDateAdapterService} from "@ctsolution/ct-framework";
import {RegistrationGuest} from "../../../core/classes/registration-guest";
import {
  LocalizationSwitchSelectComponent
} from "../../../layout/full/navbar/localization-switch-select/localization-switch-select.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {

  @ViewChild('localization') localizationSelect?: LocalizationSwitchSelectComponent;

  form: FormGroup;

  maxDate: Date;
  iconVisibility: string = 'visibility_off';

  viewModel: any = {

    passwords: {

      type1: 'password',
      type2: 'password'

    }

  }

  btnSubmit: CtButtonParameters = CtButtonParameters
    .create((event) => {
      this.registration()
    })
    .setCSS("generalButton box-shadow", true)
    .setContent("LOGIN.SIGN_UP")


  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  passwordType: string = 'password';
  brokerCode?: string;

  constructor(
    private accountController: AccountController,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dateService: CtDateAdapterService,
    public _validator: FormHelperService,
    private translate: TranslateService
  ) {

    this.activatedRoute
      .queryParams
      .subscribe(params => this.brokerCode = params['c']);

    const currentYear = new Date().getFullYear();
    this.maxDate = new Date(currentYear - 16, 11, 31);

    this.form = this.formBuilder.group({

      Name: new FormControl('', [Validators.required]),
      Surname: new FormControl('', [Validators.required]),
      Birthday: new FormControl('', Validators.required),
      Email: new FormControl('', [Validators.required, Validators.email]),
      Phone: new FormControl('', [Validators.required]),
      Password: new FormControl('', [Validators.required, Validators.minLength(6), passwordStrengthValidator]),
      confirmPsw: new FormControl('', [Validators.required]),
      NewsLetter: new FormControl(false),
      TermsAndConditions: new FormControl('', [Validators.requiredTrue]),
      BrokerCode: new FormControl(this.brokerCode),
      LanguageCode: new FormControl(this.translate.currentLang, Validators.required)

    });
  }

  ngOnInit() {

    this.activatedRoute
      .queryParams
      .subscribe(
        p => {

          if (p['Email']) {

            this.form.get('username')?.setValue(p['Email'])

          }

        });
  }

  ngAfterViewInit() {

    this.form.addControl('Location', this.localizationSelect?.control);

  }

  changePasswordType() {

    this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    this.iconVisibility = this.iconVisibility === 'visibility' ? 'visibility_off' : 'visibility';

  }

  get passwordNotEqual() {

    const formValue = this.form.value;

    return this.form.touched && !(formValue.Password === formValue.confirmPsw);

  }

  registration() {

    this.form.markAllAsTouched();

    if (this.form.valid && !this.passwordNotEqual) {

      const birthdate = this.dateService.getTimeZoneOffsetFormat(this.form.get('Birthday')?.value);

      this.form.value.LocationOid = this.form.value.Location.Oid;
      delete this.form.value.Location;

      const registrationValue: RegistrationGuest = new RegistrationGuest(<RegistrationGuest>this.form.value);

      registrationValue
        .setBirthday(birthdate);

      if (this.brokerCode) {

        registrationValue.setBrokerCode(this.brokerCode);

      }

      this.accountController.registration(registrationValue);

    }

    this._validator.scrollToError();

  }

}
