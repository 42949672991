import { Injectable } from "@angular/core";
import { CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";

@Injectable()
export class StatisticsController {

    controller: string = '/Statistic/';

    params: any = {}

    constructor(
        private ctWebapiService: CtWebapiService
    ) { }


    gainOverTime = () => this.getStatistics('GainOverTime');

    savingsByCategory = () => this.getStatistics('SavingsByCategory');

    getStatistics(action: string): Promise<any> {

        return new Promise<any>((resolve) => {

            this.ctWebapiService
                .post(new DataRequest(`${this.controller}` + action), {})
                .subscribe((p: any) => {

                    resolve(p.Result as any);

                });
        });
    }

}