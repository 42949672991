import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeneralDataComponent} from './general-data.component';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {CtMapsModule} from '@ctsolution/ct-maps';
import {AgmCoreModule} from '@agm/core';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {CtFrameworkModule} from '@ctsolution/ct-framework';
import {InputTextAreaModule} from "../../../../../../components/ct-framework/input-text-area/input-text-area.module";
import {FormInputModule} from "../../../../../../components/ct-framework/form-input/form-input.module";

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextAreaModule,
    FormInputModule,
    MatInputModule,
    AgmCoreModule,
    CtMapsModule,
    MatTabsModule,
    CtFrameworkModule
  ],
  declarations: [GeneralDataComponent],
  exports: [GeneralDataComponent]
})
export class GeneralDataModule {
}
