<div (click)="parameter.action">


  <div class="card-detail-cntr box-shadow mb-3" role="button">


    <div class="pt-3">

      <div *ngIf="parameter.previewLogoImageUrl"
        [ngStyle]="{'background': 'url(' + parameter.previewLogoImageUrl +') no-repeat top center'}" class="coverImage">
      </div>
      

          <p *ngIf="parameter.title"><b>{{parameter.title}}</b></p>

          <ng-container *ngIf="parameter.infoTemplate != null">
    
            <ng-template *ngTemplateOutlet="parameter.infoTemplate"></ng-template>
    
          </ng-container>
    

    </div>


  </div>

</div>