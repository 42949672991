<div class="review-cntr container">

    <div class="mb-4">

        <ng-container *ngFor="let i of [].constructor(5);">

            <mat-icon class="text-secondary">star</mat-icon>

        </ng-container>

    </div>

    <app-input-text-area [textAreaViewModel]="{ control: form.get('description'), label: 'FORM.LEAVE_REVIEW_CONTENT' | translate}">

    </app-input-text-area>

    <ct-button [parameters]="saveReview"></ct-button>

</div>