import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit {

  @Input() textInputViewModel: ITextInput | undefined;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {

    if (this.textInputViewModel && !this.textInputViewModel.type)

      this.textInputViewModel.type = 'text';

  }

}


export interface ITextInput {

  control: any;
  label?: string;
  type?: string;
  placeholder?: string;
  hint?: string;
  pattern?: string;
  readonly?: boolean;
  img? :string;
  imgCSS?:string;
  value?:string;

}
