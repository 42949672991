import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CtButtonParameters }from '@ctsolution/ct-framework';
import { DialogLeaveReviewComponent } from './dialog-leave-review/dialog-leave-review.component';
import { TransactionCardParameter } from './class/transaction-card-parameter';
import {CtCardParameters} from "../../../../components/ct-framework/ct-card/class/CtCardParameters";

@Component({
  selector: 'app-transaction-card',
  templateUrl: './transaction-card.component.html',
  styleUrls: ['./transaction-card.component.scss']
})
export class TransactionCardComponent implements OnInit {

  buttonsViewModel: any = {

    btnReview: CtButtonParameters
    .create((event)=>{this.LeaveReview()})
    .setCSS("cardButton box-shadow mat-icon-button", true)
    .setContent("CTBUTTON.LEAVEREVIEW"),
  }

@Input() parameter!: TransactionCardParameter

cardParameter: CtCardParameters = CtCardParameters.Create(()=>{});

  @ViewChild("cardTemplate") template!: TemplateRef<any>;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {

    setTimeout(() => {

    this.cardParameter.setInfoTemplate(this.template);

    }, 500);

  }

  LeaveReview() {
    this.dialog.open(DialogLeaveReviewComponent);
  }

}
