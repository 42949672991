<div class="mb-4 text-right" *ngIf="!viewModel.specialDiscountCreationDisabled">

  <ct-button [parameters]="create"></ct-button>

</div>

<div class="discount-list-cntr">

  <mat-tab-group [(selectedIndex)]="viewModel.selectedIndex">

    <mat-tab [label]="'BUSINESS.YOUR_DISCOUNTS'| translate">

      <ng-container *ngIf="viewModel.approved.length > 0; else EMPTY_LIST">

        <mat-list role="list">

          <ng-container
            *ngFor="let item of viewModel.approved">

            <app-discount-list-item [item]="item"></app-discount-list-item>

          </ng-container>

        </mat-list>

      </ng-container>

    </mat-tab>

    <mat-tab [label]="'BUSINESS.APPROVAL_DISCOUNTS'| translate">

      <ng-container *ngIf="viewModel.approval.length > 0; else EMPTY_LIST">

        <mat-list role="list">

          <ng-container
            *ngFor="let item of viewModel.approval">

            <app-discount-list-item [item]="item"></app-discount-list-item>

          </ng-container>

        </mat-list>

      </ng-container>

    </mat-tab>

  </mat-tab-group>

</div>

<ng-template #EMPTY_LIST>

  <p class="text-center"> {{ 'GENERAL.NO_AVAILABLE_DATA' | translate }}</p>

</ng-template>
