import {Injectable} from '@angular/core';
import {AppGeneralService} from "../../../../../../../core/library/app-general.service";
import {PriceCategoryEnum} from "../../../../../../../core/enum/price-category.enum";
import {GoogleAreaBounce} from "../../maps/maps.component";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  public filters: FilterProduct = new FilterProduct();

  constructor(private appGeneralService: AppGeneralService) {
  }

  search = () => this.appGeneralService.navigateTo('/research/list', {queryParams: this.filters.getCleaned()});

  reset = () => this.filters = new FilterProduct();

}


export class FilterProduct {

  Name: string | null = null;
  CategoryCodes: string[] | null = null;
  Latitude: number | null = null;
  Longitude: number | null = null;
  DistanceMeter: number | null = null;
  Latest: boolean = false;
  IsOpen: boolean = false;
  Discount: number | null = null;
  Location: string | null = null;
  Favorites: boolean = false;
  SpecialDiscount: boolean = false;
  OnlyFavorites: boolean = false;
  PriceCategory: PriceCategoryEnum | null = null;

  BounceLatitudeX: number | null = null; // sud
  BounceLatitudeY: number | null = null; // nord
  BounceLongitudeX: number | null = null; // ovest
  BounceLongitudeY: number | null = null; // est

  constructor() {
  }

  setValue(key: string, value: any): FilterProduct {

    if (key === 'CategoryCodes' && !!value) {

      (<any>this)[key] = Array.isArray(value) ? value : [value];

    } else {

      (<any>this)[key] = value;

    }

    return this;

  }

  setAreaBounce(value: GoogleAreaBounce) {

    if (value.north) {

      this.BounceLatitudeX = value.south;

    }

    if (value.south) {

      this.BounceLatitudeY = value.north;

    }

    if (value.west) {

      this.BounceLongitudeX = value.west;

    }

    if (value.east) {

      this.BounceLongitudeY = value.east;

    }

    return this;

  }

  getCleaned = () => Object.fromEntries(Object.entries(this).filter(([key, value]) => (!!value || value === 0) && typeof value !== 'function'))

  hasValue = (key: string) => !!(<any>this)[key];

}
