<div class="recovery-password-cntr mt-5 container">

    <div class="fixed-container-height text-center d-flex flex-column">

        <div class="mt-auto mb-auto">

            <h1 [innerHtml]="'RECOVERY-PASSWORD.TITLE' | translate"></h1>

            <h3 [innerHtml]="'RECOVERY-PASSWORD.SUBTITLE' | translate"></h3>

            <form [formGroup]="form">

                <mat-form-field class="w-100 b-radius mb-2" appearance="outline">

                    <mat-label> {{ 'FORM.MAIL' | translate }} </mat-label>
                    <input matInput placeholder="username@company.it" type="email" autocomplete="off"
                        formControlName="mailRecovery" required>
                    <mat-error *ngIf="form.get('mailRecovery')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' |
                        translate }}</mat-error>
                    <mat-error *ngIf="form.get('mailRecovery')?.hasError('email')">* {{ 'FORM.VALID_MAIL' | translate }}
                    </mat-error>

                </mat-form-field>

                <div class="mt-5 mb-5 w-75 m-auto">

                    <ct-button *ngIf="viewModel.btnSubmit" [parameters]="viewModel.btnSubmit"></ct-button>

                </div>

            </form>

        </div>

    </div>

</div>