import {Component, OnInit} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CtProfileService} from '@ctsolution/ct-authentication';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {SnackbarService} from 'src/app/core/library/snackbar.service';


@Component({
  selector: 'app-delete-profile',
  templateUrl: './delete-profile.component.html',
  styleUrls: ['./delete-profile.component.scss']
})
export class DeleteProfileComponent implements OnInit {


  deleteButton = CtButtonParameters
    .create((event) => {
      this.deleteProfile()
    })
    .setCSS("warning-button", true)

    .setContent("CTBUTTON.DELETE-PROFILE")


  annulla = CtButtonParameters
    .create((event) => {
      this.close()
    })
    .setCSS("generalButton", true)

    .setContent("BUTTON.CANCEL")

  constructor(
    public dialogRef: MatDialogRef<DeleteProfileComponent>,
    private profileService: CtProfileService,
    private dialogService: SnackbarService
  ) {
  }

  ngOnInit() {
  }

  close() {

    this.dialogRef.close();

  }

  deleteProfile() {

    this.profileService
      .delete()
      .subscribe(() => {

        this.dialogRef
          .afterClosed()
          .subscribe(() => this.dialogService.generalMessage('DELETE-PROFILE.SUCCESS'));

        this.dialogRef.close();

      })

  }

}
