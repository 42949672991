import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditGalleryComponent} from './edit-gallery.component';
import {FileUploadModule} from '../../file-upload/file-upload.module';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@NgModule({
  imports: [
    CommonModule,
    FileUploadModule,
    CtButtonModule,
    CtFrameworkModule
  ],
  declarations: [EditGalleryComponent],
  exports: [EditGalleryComponent],
  providers: [
    {provide: MAT_DIALOG_DATA, useValue: {}},
    // { provide: MdDialogRef, useValue: {} }, --> deprecated
    {provide: MatDialogRef, useValue: {}}
  ]

})
export class EditGalleryModule {
}
