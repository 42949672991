import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SavingsChartComponent} from './savings-chart.component';
import {NgChartsModule} from 'ng2-charts';
import {CtFrameworkModule} from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    NgChartsModule,
    CtFrameworkModule
  ],
  declarations: [SavingsChartComponent],
  exports: [SavingsChartComponent]
})
export class SavingsChartModule {
}
