import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@ctsolution/ct-webapi';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loading: boolean | undefined;

  constructor(private loaderService: LoaderService) {

    this.loaderService.isLoading.subscribe((v) => this.loading = v);

  }

  ngOnInit() {
  }

}
