import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {MarketPlaceController} from 'src/app/core/controllers/market-place.controller';
import {SnackbarService} from "../../../../../../core/library/snackbar.service";
import {CouponHelperService} from "../../../../../../core/library/coupon-helper.service";
import {Observable} from "rxjs";
import {ProductController} from "../../../../../../core/controllers/product.controller";
import {Coupon} from "../../../../../../core/classes/coupon";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";

@Component({
  selector: 'app-VPoint-coupon-request',
  templateUrl: './VPoint-coupon-request.component.html',
  styleUrls: ['./VPoint-coupon-request.component.scss']
})
export class VPointCouponRequestComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<VPointCouponRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public coupon: Coupon,
    private ctAuthenticationService: CtAuthenticationService,
    private marketPlaceController: MarketPlaceController,
    private productController: ProductController,
    private snackbar: SnackbarService,
    private couponHelper: CouponHelperService
  ) {
  }

  ngOnInit() {
  }

  cancel() {

    this.dialogRef.close();

  }

  redeem() {

    this.purchase()
      .subscribe((result: CtWebapiGenericResponse<any>) => {

        //acquisto e reindirizzo al qr scanner
        this.ctAuthenticationService
          .getClaims()
          .then(() => {

            this.couponHelper
              .useCoupon(result.Result);

            this.dialogRef.close(true);

          });

      });

  }

  save() {

    this.purchase()
      .subscribe(() => {

        this.ctAuthenticationService
          .getClaims()
          .then(() => {

            this.dialogRef.close(true);

            this.snackbar.generalMessage('COUPON.SAVE_AFTER')

          });

      });

  }

  private purchase(): Observable<CtWebapiGenericResponse<any>> {

    if (this.coupon.isVPointCoupon()) {

      return this.marketPlaceController
        .purchase(this.coupon);

    } else {

      return this.productController
        .purchasedSpecialDiscount(this.coupon.Oid);

    }

  }

}
