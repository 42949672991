import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentAreaComponent } from './content-area.component';
import { CtFrameworkModule } from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    CtFrameworkModule
  ],
  declarations: [ContentAreaComponent],
  exports: [ContentAreaComponent]
})
export class TextAreaModule { }
