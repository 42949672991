import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs';
import {AppGeneralService} from "../../../core/library/app-general.service";

@Injectable({
  providedIn: 'root'
})
export class BackButtonService {

  latestUrl: string | null = null;

  constructor(private router: Router, private appGeneralService: AppGeneralService) {

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.latestUrl = this.router.url;
      });

  }

  resetLatestUrl() {

    this.latestUrl = null;

  }

  navigate(path: string | null = null) {

    if (path && path.includes('?')) {

      this.router.navigateByUrl(path);

    } else {

      this.appGeneralService.back();

    }

  }

}
