import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  form: FormGroup;

  passwordType: string = 'password';
  iconVisibility: string = 'visibility_off';

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({

      Email: new FormControl('', [Validators.required, Validators.email]),
      Password: new FormControl('', [Validators.required]),
      RememberMe: new FormControl(false)

    });

  }

  ngOnInit() {

    this.activatedRoute
      .queryParams
      .subscribe(
        p => {

          if (p['Email']) {

            this.form.get('username')?.setValue(p['Email'])

          }

        });    
        
       
  }

  changePasswordType() {

    this.passwordType = this.passwordType === 'password' ? 'text' : 'password';

    this.iconVisibility = this.iconVisibility === 'visibility' ? 'visibility_off' : 'visibility';

  }

}
