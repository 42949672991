import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SavingsComponent} from './savings.component';
import {MatIconModule} from '@angular/material/icon';
import {SavingsChartModule} from './savings-chart/savings-chart.module';
import {CtFrameworkModule} from '@ctsolution/ct-framework';
import {ChartModule} from "../../../../_components/chart/chart.module";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    SavingsChartModule,
    CtFrameworkModule,
    ChartModule
  ],
  declarations: [SavingsComponent],
  exports: [SavingsComponent]
})
export class SavingsModule {
}
