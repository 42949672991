import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShareCouponWithFriendsComponent} from './share-coupon-with-friends.component';
import {TicketDiscountCodeComponent} from './ticket-discount-code/ticket-discount-code.component';
import {ShareDiscountCodeModule} from './share-discount-code/share-discount-code.module';
import {CtFrameworkModule} from '@ctsolution/ct-framework';
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    ShareDiscountCodeModule,
    CtFrameworkModule,
    RouterModule.forChild([
      {
        path: '',
        component: ShareCouponWithFriendsComponent
      }
    ])
  ],
  declarations: [ShareCouponWithFriendsComponent, TicketDiscountCodeComponent]
})
export class ShareCouponWithFriendsModule {
}
