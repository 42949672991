<div class="edit-gallery-cntr">

  <h2 class="text-center mt-2" [innerHTML]="['UPLOAD.GALLERY_IMAGES'|translate]"></h2>

  <div class="row">

    <ng-container *ngFor="let element of viewModel.uploadSettings.box; index as i">

      <div class="col-6 mt-4 mb-4">

        <app-file-upload [picture]="element.PreviewUrl" [typeToAccept]="viewModel.fileTypeToAccept"

          (change)="manageFile($event, i)" (deletePicture)="delete(element)"></app-file-upload>

      </div>

    </ng-container>

    <div class="w-75 m-auto p-footer">

      <ct-button *ngIf="viewModel.btnBack" [parameters]="viewModel.btnBack"></ct-button>

    </div>

  </div>

</div>
