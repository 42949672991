<ng-container
  *ctSkeleton="!transaction;
           repeat: 6;
           className: 'scanner-result-skeleton';">

  <div class="container text-center">

    <div class="mb-5">

      <img height="100px" [src]='resultImageSrc()' class='roll-in-blurred-right'/>

    </div>

    <div class="mb-5">

      <h1>{{ (this.successfullyTransaction() ? 'SNACKBAR.TRANSACTION_SUCCESS' : 'SNACKBAR.TRANSACTION_REFUSED') | translate }}</h1>

    </div>

    <div class="mb-5">

      <ng-container
        *ngIf="this.successfullyTransaction() && transaction!.Amount > 0">

        <p>{{ 'GENERAL.TOTAL_AMOUNT' | translate }}: {{transaction!.Amount | currency:'EUR':'symbol' }}</p>
        <p>{{ 'GENERAL.DISCOUNTED_AMOUNT' | translate }}
          : {{transaction!.TotalAmount | currency:'EUR':'symbol'}}</p>

      </ng-container>

    </div>

    <div class="mb-5">

      <ct-button [parameters]="rescan"></ct-button>

      <ng-container
        *ngIf="this.successfullyTransaction()">

        <ct-button [parameters]="report"></ct-button>

      </ng-container>

    </div>

  </div>

</ng-container>
