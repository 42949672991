import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationSearchBarComponent } from './location-search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CtFrameworkModule } from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    CtFrameworkModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatGoogleMapsAutocompleteModule
  ],
  declarations: [LocationSearchBarComponent],
  exports: [LocationSearchBarComponent]
})
export class LocationSearchBarModule { }
