<div class="registration-form-cntr p-footer">

  <form [formGroup]="form" (keyup.enter)="submit.emit()">

    <mat-form-field class="w-100 b-radius mb-2" appearance="outline">

      <mat-label> {{ 'FORM.NAME' | translate }} </mat-label>
      <input matInput placeholder="Francesco" type="text" autocomplete="off" formControlName="Name" required>
      <mat-error>* {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>

    </mat-form-field>

    <mat-form-field class="w-100 b-radius mb-2" appearance="outline">

      <mat-label> {{ 'FORM.SURNAME' | translate }} </mat-label>
      <input matInput placeholder="Rossi" type="text" autocomplete="off" formControlName="Surname" required>
      <mat-error>* {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>

    </mat-form-field>

    <div class="birthdate-cntr">

      <mat-form-field appearance="outline" class="w-100 mb-2">

        <mat-label>{{ 'FORM.BIRTHDATE' | translate }}</mat-label>

        <input formControlName="Birthday" (click)="picker.open()" readonly [max]="maxDate" required matInput [matDatepicker]="picker">
        <mat-error>* {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>

        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

        <mat-datepicker #picker></mat-datepicker>

      </mat-form-field>

    </div>

    <mat-form-field class="w-100 b-radius mb-2" appearance="outline">

      <mat-label> {{ 'FORM.MAIL' | translate }} </mat-label>
      <input matInput placeholder="username@company.it" type="email" autocomplete="off" formControlName="Email"
             required>
      <mat-error *ngIf="form.get('Email')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate
        }}</mat-error>
      <mat-error *ngIf="form.get('Email')?.hasError('email')">* {{ 'FORM.VALID_MAIL' | translate }}</mat-error>

    </mat-form-field>

    <mat-form-field class="w-100 b-radius mb-2" appearance="outline">

      <mat-label> {{ 'FORM.PHONE' | translate }} </mat-label>
      <input matInput placeholder="344 9929384" type="number" autocomplete="off" formControlName="Phone" required>
      <mat-error>* {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>

    </mat-form-field>

    <mat-form-field class="w-100 b-radius mb-2" appearance="outline">

      <mat-label> {{ 'FORM.PASSWORD' | translate }} </mat-label>
      <input matInput [type]="viewModel.passwords.type1" autocomplete="off" formControlName="Password" required>
      <button mat-icon-button matSuffix
              (click)="viewModel.passwords.type1 = _validator.password.toggleType(viewModel.passwords.type1)"
              type="button">
        <mat-icon>{{_validator.password.toggleMaterialIcon(viewModel.passwords.type1)}}</mat-icon>
      </button>

      <mat-error *ngIf="form.get('Password')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate
        }}</mat-error>
      <mat-error *ngIf="form.get('Password')?.hasError('passwordStrength')">* {{
        'FORM.PASSWORD_STRENGTH_VALIDATOR' | translate }}</mat-error>


    </mat-form-field>

    <mat-form-field class="w-100 b-radius pb-0" appearance="outline">

      <mat-label> {{ 'FORM.CONFIRM_PASSWORD' | translate }} </mat-label>
      <input matInput [type]="viewModel.passwords.type2" autocomplete="off" formControlName="confirmPsw" required>
      <button mat-icon-button matSuffix
              (click)="viewModel.passwords.type2 = _validator.password.toggleType(viewModel.passwords.type2)"
              type="button">
        <mat-icon>{{_validator.password.toggleMaterialIcon(viewModel.passwords.type2)}}</mat-icon>
      </button>
      <mat-error *ngIf="form.get('confirmPsw')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate
        }}</mat-error>

    </mat-form-field>
    <mat-hint class="mt-0" *ngIf="passwordNotEqual && !form.get('confirmPsw')?.hasError('required')">* {{
      'GENERAL.PASSWORD_NOT_MATCH' | translate }}</mat-hint>

    <app-localization-switch-select #localization [registration]="true"></app-localization-switch-select>

    <mat-form-field class="w-100 b-radius mb-2" appearance="outline">

      <mat-label> {{ 'FORM.BROKERCODE' | translate }} </mat-label>
      <input matInput placeholder="Codice amico" type="text" formControlName="BrokerCode">

    </mat-form-field>

    <div>

      <mat-checkbox formControlName="NewsLetter">Newsletter</mat-checkbox>

    </div>
    <div>

      <mat-checkbox formControlName="TermsAndConditions"><span [innerHtml]="'SIGN_UP.TERMS_CONSENT' | translate"></span>
      </mat-checkbox>
      <div>

        <mat-hint
          *ngIf="form.touched && form.get('TermsAndConditions')?.hasError('required')">{{ 'GENERAL.REQUIRED_FIELD-true' | translate }}</mat-hint>

      </div>

    </div>

  </form>

  <div class="mt-4 p-footer">

    <ct-button *ngIf="btnSubmit" [parameters]="btnSubmit"></ct-button>

  </div>


</div>
