import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonSectionComponent} from './button-section.component';
import {CtButtonModule, CtFrameworkModule, CtSkeletonModule} from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    CtFrameworkModule,
    CtSkeletonModule
  ],
  declarations: [ButtonSectionComponent],
  exports: [ButtonSectionComponent]
})
export class ButtonSectionModule {
}
