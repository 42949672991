<div class="price-filter-cntr">

  <h3><b [innerHTML]="['FILTER.PRICE_TITLE' | translate]"></b></h3>

  <div class="row">

    <div
      *ngFor="let button of buttons"
      class="col-4 d-flex">

      <ct-button [parameters]="button.parameters" class="m-auto" [class.active]="button.selected"></ct-button>

    </div>

  </div>

</div>
