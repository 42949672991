import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AddressResult, Coordinates, CtMapsService} from '@ctsolution/ct-maps';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-location-search-bar',
  templateUrl: './location-search-bar.component.html',
  styleUrls: ['./location-search-bar.component.scss']
})
export class LocationSearchBarComponent implements OnInit {

  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;
  locationName: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private ctMapsService: CtMapsService
  ) {

    this.form = this.formBuilder.group({
      location: new FormControl(null),
      coordinates: new FormControl(null)
    });

  }

  ngOnInit() {

  }

  // FIRST
  onAutocompleteSelected(result: PlaceResult) {

    let address: AddressResult | undefined;

    if (result) {

      address = this.ctMapsService.getAddress(result);
      this.locationName = address?.Address ?? '';

    }

    this.form.get('location')?.setValue(address);

  }

  // SECOND
  getCoordinates(location: Coordinates) {

    this.form.get('coordinates')?.setValue({latitude: location.latitude, longitude: location.longitude});
    this.update.emit(this.form.value);

  }

  onEditChange(event: any) {

  }

}
