<div class="footer-cntr">

  <div class="container">

    <div class="row">

      <div
        class="col-4 icons-footer text-center"
        *ngFor="let button of UIButtons">

        <ct-button [parameters]="button"></ct-button>

      </div>

    </div>

  </div>

</div>
