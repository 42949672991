import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileMenuComponent } from './profile-menu.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    CtFrameworkModule
  ],
  declarations: [ProfileMenuComponent],
  exports: [ProfileMenuComponent]
})
export class ProfileMenuModule { }
