import {Injectable} from "@angular/core";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {ISubCategory} from "../interfaces/category";

const SUBCATEGORY_CONTROLLER: string = 'SubCategory';

@Injectable()
export class SubCategoryController {

  constructor(private webapi: CtWebapiService) {
  }

  list(categoryOid: number): Promise<ISubCategory[]> {

    return new Promise<ISubCategory[]>((resolve) => {

      const request: DataRequest = new DataRequest(`/${SUBCATEGORY_CONTROLLER}/List`, {categoryOid});

      this.webapi
        .get(request)
        .subscribe((response: CtWebapiGenericResponse<ISubCategory[]>) => resolve(response.Result ?? []));

    });

  }

}
