<div class="menu-navigation-button-cntr container d-flex flex-column justify-content-between">

  <div>

    <ng-container *ngIf="viewModel.loggedUser">

      <ng-container *ngIf="viewModel.businessProfile">

        <div class="mb-2">

          <label> {{ "ACCOUNT.PROFILE_TYPE_CLIENTE" | translate}}</label>
          <app-slide-toggle
            [configuration]="slideToggleConfiguration"
            (onChange)="toggleUserMode()"></app-slide-toggle>

        </div>

        <hr>

      </ng-container>

      <div class="mb-1">

        <ct-button [parameters]="profileButton"></ct-button>

      </div>

      <ng-container *ngIf="viewModel.customerProfile">

        <div class="mb-1">

          <ct-button [parameters]="favoriteButton"></ct-button>

        </div>

      </ng-container>

      <div class="mb-1">

        <ct-button
          mat-raised-button
          color="primary"
          [matBadge]="viewModel.notificationCount"
          matBadgePosition="before"
          matBadgeColor="primary"
          [parameters]="notificationsButton"></ct-button>

      </div>

      <ng-container *ngIf="viewModel.customerProfile">

        <div class="mb-1">

          <ct-button [parameters]="friendsButton"></ct-button>

        </div>

      </ng-container>

      <ng-container *ngIf="!viewModel.customerProfile">

        <div class="mb-1">

          <ct-button [parameters]="reportButton"></ct-button>

        </div>

      </ng-container>

    </ng-container>

    <div class="mb-1">

      <ct-button [parameters]="faqButton"></ct-button>

    </div>

    <div class="mb-1">

      <ct-button [parameters]="guideButton"></ct-button>

    </div>

  </div>

  <div>

    <hr>

    <div class="mb-2">

      <ct-button [parameters]="itButton"></ct-button>

    </div>

    <div class="mb-2">

      <ct-button [parameters]="enButton"></ct-button>

    </div>

    <ng-container *ngIf="viewModel.loggedUser">

      <hr>

      <div class="mb-2">

        <ct-button [parameters]="logoutButton"></ct-button>

      </div>

    </ng-container>

  </div>

  <div class="mt-3 mb-5" >

    <ct-button [parameters]="termsConditionsButton"></ct-button>

</div>

</div>
