<div class="scanner-cntr container">

  <h1 class="text-center primary-color mb-5" [innerHTML]="['FORM.SCAN'|translate]"></h1>

  <ng-container
    *ctSkeleton="!(viewModel.cameraPermissionEnabled && !transaction);
           repeat: 1;
           className: 'qr-scanner-skeleton';">

    <div class="qr-scanner-cntr text-center mb-5">

      <mat-progress-bar *ngIf="scanner.isAutostarting" mode="buffer"></mat-progress-bar>

      <zxing-scanner
        #scanner
        [(device)]="scannerModel.currentDevice"
        (camerasFound)="camerasFoundHandler($event)"
        (scanSuccess)="scanSuccessHandler($event)"></zxing-scanner>

      <button
        mat-fab
        *ngIf="!viewModel.production && scannerModel.availableDevices.length > 1"
        color="primary"
        class="switch-camera-button"
        #cameraButton
        (click)="changeCamera()">
        <mat-icon>cameraswitch</mat-icon>
      </button>

    </div>

  </ng-container>

  <div class="text-center">

    <ct-button [hidden]="viewModel.cameraPermissionEnabled" [parameters]="askPermissionsButton"></ct-button>

    <ct-button [parameters]="close"></ct-button>

  </div>

</div>
