<div class="slide-toggle-container business-change-role-container">

  <ng-container *ngIf="configuration.prefixLabel; else defaultToggleTemplate">

    <mat-label ngClass="labelBeforeSlide">
      {{ configuration.prefixLabel | translate }}
    </mat-label>

    <ng-container *ngTemplateOutlet="defaultToggleTemplate"></ng-container>

  </ng-container>

</div>

<ng-template #defaultToggleTemplate>

  <mat-slide-toggle [formControl]="configuration.control" (change)="onChangeEvent()" color="primary">

    {{ configuration.label | translate }}

  </mat-slide-toggle>

</ng-template>
