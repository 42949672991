<div class="search-filters-container pt-4 container">

  <div class="search-filters-title-cntr">

    <h1> {{ 'FILTER.GENERIC_TITLE' | translate }}</h1>
    <ct-button [parameters]="closeFiltersBtn"></ct-button>

  </div>

  <div class="mb-4">

    <app-order-filter #order></app-order-filter>

    <app-remove-filter-button
      *ngIf="form.get('Order')?.value"
      (onRemove)="removeGenericFilter(form.get('Order'))"></app-remove-filter-button>

  </div>

  <div class="mb-4">

    <app-distance-filter #distance></app-distance-filter>

    <app-remove-filter-button
      *ngIf="form.get('Distance')?.value"
      (onRemove)="removeGenericFilter(form.get('Distance'))"></app-remove-filter-button>

  </div>

  <div class="mb-4">

    <app-price-filter #price (update)="setPrice($event)"></app-price-filter>

    <app-remove-filter-button
      *ngIf="form.get('Price')?.value || form.get('Price')?.value === 0"
      (onRemove)="removePriceFilter()"></app-remove-filter-button>

  </div>

  <div class="mb-4">

    <app-categories-list #category [filtersPanel]="true" (update)="setCategory($event)"></app-categories-list>

    <app-remove-filter-button
      *ngIf="form.get('Category')?.value"
      (onRemove)="removeCategoryFilter()"></app-remove-filter-button>

  </div>

  <div class="mb-4 show-results-button-cntr">

    <button class="show-results-button generalButton" mat-button color="primary" (click)="closeWithResults()">
  
      <strong [innerHTML]="'GENERAL.SHOW' | translate"></strong>
  
      <strong *ngIf="!viewModel.loading; else spinnerTemplate">
        &nbsp;
        {{ viewModel.results.length }}&nbsp;
      </strong>
  
      <strong [innerHTML]="'GENERAL.RESULTS' | translate"></strong>
  
    </button>
  
    <ng-template #spinnerTemplate>
      <mat-spinner [diameter]="15"></mat-spinner>
    </ng-template>
  
  </div>

</div>

