<div class="search-list p-footer">

  <app-maps
    #map
    [isMapExpanded]="viewModel.isMapExpanded"
    [products]="list"
    (onFocus)="orderBySelectedProduct($event)"
    (boundsChange)="onCenterChange($event)"></app-maps>

  <div style="background: white; position: relative">

    <div class="search-list-map-cntr">

      <app-search-active-filters (toggleMapHeight)="adjustMapHeightOnClick()"></app-search-active-filters>

    </div>

    <div class="container products-list-cntr">

      <ng-container
        *ctSkeleton="viewModel.loading;
           repeat: 6;
           className: 'card-detail-skeleton';">

        <ng-container *ngIf="list.length > 0; else emptyListTplt">

          <app-search-card [product]="product" *ngFor="let product of list"
                           [id]="ID_PREFIX + this.product.Id"></app-search-card>

        </ng-container>

        <ng-template #emptyListTplt>

          <p class="text-center mt-5"> {{ 'NO_AVAILABLE_ELEMENTS' | translate }} </p>

        </ng-template>

      </ng-container>

    </div>

  </div>

</div>
