import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CtAuthenticationService} from '@ctsolution/ct-authentication';
import {CtButtonParameters, CtButtonRouterLink} from '@ctsolution/ct-framework';
import { Subscription} from 'rxjs';
import {AppGeneralService} from 'src/app/core/library/app-general.service';
import {RoleHelperService} from "../../../core/library/role-helper.service";
import {
  ScannerQrHelperService
} from "../../../pages/personal-area/business-area/_components/qr-scanner/scanner-qr-helper.service";


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  //#region buttons
  homeButton: CtButtonParameters = CtButtonParameters.create(() => {})
    .setRouterLinkConfiguration(CtButtonRouterLink.create(['home']))
    .setCSS('homeIcon')


  editProductButton: CtButtonParameters = CtButtonParameters.create(() => {})
    .setRouterLinkConfiguration(CtButtonRouterLink.create(['personal-area', 'business','store']))
    .setCSS('editProductIcon')


  specialDiscountButton = CtButtonParameters
    .create(() => this.redirectCouponDiscount())
    .setCSS('discountIcon')


  centralButton: CtButtonParameters = CtButtonParameters.create(() => {})
    .setRouterLinkConfiguration(CtButtonRouterLink.create(['personal-area', 'customer', 'report']))
    .setCSS('personalAreaIcon')


  couponButton = CtButtonParameters
    .create(() => this.redirectCouponDiscount())
    .setCSS('couponIcon')


  qrCentralButton = CtButtonParameters
    .create(() => this.businessScanService.openScanner())
    .setImage('/assets/images/icons/footer/qr-code.svg')
    .setImageCss('menuIconFooter')
    .setCSS('footerLogo')

  //#endregion

  private BUSINESS_ACTIONS: CtButtonParameters[] = [this.editProductButton, this.qrCentralButton, this.specialDiscountButton];
  private USER_ACTIONS: CtButtonParameters[] = [this.homeButton, this.centralButton, this.couponButton];

  UIButtons: CtButtonParameters[] = this.USER_ACTIONS;

  subscription?: Subscription;

  constructor(
    private appGeneralService: AppGeneralService,
    private ctAuthenticationService: CtAuthenticationService,
    public roleHelper: RoleHelperService,
    private router: Router,
    private businessScanService: ScannerQrHelperService
  ) {

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => this.setupFooterIcons());

  }

  setupFooterIcons = () => this.UIButtons = this.roleHelper.isBusiness() && !this.roleHelper.isCustomer() ? this.BUSINESS_ACTIONS : this.USER_ACTIONS;

  ngOnInit() {
  }

  private redirectCouponDiscount() {

   this.couponButton.setRouterLinkConfiguration(CtButtonRouterLink.create(['personal-area', 'customer', 'coupons']))

    if (this.roleHelper.isBusiness())  {

      this.specialDiscountButton.setRouterLinkConfiguration(CtButtonRouterLink.create(['personal-area', 'business', 'discounts']))

    }

  }

  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }

}
