import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FavoritesProductsComponent} from './favorites-products.component';
import {CtFrameworkModule, CtSkeletonModule} from '@ctsolution/ct-framework';
import {RouterModule} from "@angular/router";
import {SearchCardModule} from "../../../research/search-list/search-card/search-card.module";

@NgModule({
  imports: [
    CommonModule,
    SearchCardModule,
    CtFrameworkModule,
    CtSkeletonModule,
    RouterModule.forChild([
      {
        path: '',
        component: FavoritesProductsComponent
      }
    ])
  ],
  declarations: [FavoritesProductsComponent]
})
export class FavoritesProductsModule {
}
