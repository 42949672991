import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalDataComponent } from './personal-data.component';
import { ProfileImageModule } from './profile-image/profile-image.module';
import {CtFrameworkModule} from "@ctsolution/ct-framework";

@NgModule({
  imports: [
    CommonModule,
    ProfileImageModule,
    CtFrameworkModule
  ],
  declarations: [PersonalDataComponent],
  exports: [PersonalDataComponent]
})
export class PersonalDataModule { }
