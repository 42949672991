import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

export const DISTANCE_FILTERS: { key: string, value: number }[] = [
  {key: '500 m', value: 500},
  {key: '1 km', value: 1000},
  {key: '5 km', value: 5000},
  {key: '10 km', value: 10000},
];

@Component({
  selector: 'app-distance-filter',
  templateUrl: './distance-filter.component.html',
  styleUrls: ['./distance-filter.component.scss']
})
export class DistanceFilterComponent implements OnInit {

  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  control: FormControl = new FormControl('');

  options: { key: string, value: number }[] = DISTANCE_FILTERS;

  constructor() {
  }

  ngOnInit() {

  }

  initValue = (value: number) => this.control.setValue(value);

  selectValue = () => this.update.emit(this.control.value);

}
