<div class="avatar-cntr p-footer">

  <div class="mt-4 text-center">

    <h2 [innerHTML]="['PERSONAL.AVATAR' | translate]"></h2>

  </div>

  <div class="row container m-auto">

    <ng-container *ngFor="let avatar of avatarViewModel.avatars">

      <div class="col-4 mb-4">

          <ct-button [parameters]="avatar"></ct-button>

      </div>

  </ng-container>

  </div>

</div>
