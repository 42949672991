<section class="time-radio-button-cntr">

    <section class="example-section">

        <div class="row w-75 m-auto">

            <div class="col-6 mb-2" *ngFor="let value of model.values">

                <mat-checkbox [value]="value">{{value}}</mat-checkbox>

            </div>

        </div>

    </section>

</section>