<div class="coupon-section-cntr mt-3 p-footer container">

  <h1 class="text-center primary-color">Victoria Point</h1>
  <h2 class="text-center"> {{ "VPOINT.SUBTITLE" | translate }}</h2>

  <mat-card>

    <mat-card-content>

      <div class="d-flex justify-content-around align-baseline text-center">

        <span class="vpoint-balance-text pt-2" [innerHTML]="['BALANCE.TITLE'|translate]"></span>

        <h1 class="primary-color balance text-center">
          <app-vpoint-label [amount]="+balance"></app-vpoint-label>
        </h1>

      </div>

    </mat-card-content>

  </mat-card>

  <hr class="mt-4 mb-4">

  <div class="mb-4 container">

    <ng-container *ngIf="viewModel.ProductId; else defaultFilters">

      <mat-chip-list>
        <mat-chip disableRipple>
          {{viewModel.ProductName}}
          <button matChipRemove (click)="removeShopFilter()">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>

    </ng-container>

    <ng-template #defaultFilters>

      <ct-button [parameters]="btnCategoriesFilter"></ct-button>

    </ng-template>

  </div>

  <ng-container
    *ctSkeleton="viewModel.loading;
           repeat: 6;
           className: 'card-detail-skeleton';">

    <ng-container *ngIf="$productCoupons && $productCoupons.length > 0; else emptyListTplt">

      <app-vpoint-card
        *ngFor="let product of $productCoupons"
        [productCoupon]="product"
        [balance]="+balance"
        (refresh)="getBalance()"></app-vpoint-card>

    </ng-container>

  </ng-container>

  <ng-template #emptyListTplt>

      <p class="text-center mt-5 mb-5"> {{ 'NO_AVAILABLE_COUPONS' | translate }} </p>

      <ct-button [parameters]="btnRedirectHome"></ct-button>

  </ng-template>

</div>
