

<carousel class="container p-0" 
  [arrows]="parameter.componentSettings.arrows" 
  [cellsToShow]="parameter.componentSettings.cellsToShow" 
  [cellWidth]="parameter.componentSettings.cellWidth" 
  [height]="parameter.componentSettings.height" 
  [dots]="parameter.componentSettings.dots"
  [loop]="parameter.componentSettings.loop"
  [cellsToScroll]="parameter.componentSettings.cellsToScroll"
  [autoplay]="parameter.componentSettings.autoplay"
  [objectFit]="parameter.componentSettings.objectFit"
  [transitionDuration]="parameter.componentSettings.transitionDuration"
  [pauseOnHover]="parameter.componentSettings.pauseOnHover"

  [images]="parameter.pictures"
>
</carousel>