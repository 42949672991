import {Component, OnInit} from '@angular/core';
import {CtAuthenticationService} from '@ctsolution/ct-authentication';
import {Product} from 'src/app/core/classes/product';
import {ProductController} from 'src/app/core/controllers/product.controller';
import {
  FilterProduct
} from "../../../research/search-list/search-list/_components/search-filters/search-filters/search.service";
import {
  ORDER_FILTER
} from "../../../research/search-list/search-list/_components/search-filters/_components/order-filter/order-filter.component";

@Component({
  selector: 'app-favorites-products',
  templateUrl: './favorites-products.component.html',
  styleUrls: ['./favorites-products.component.scss']
})
export class FavoritesProductsComponent implements OnInit {

  list: Array<Product> = new Array<Product>();
  viewModel = {

    loading: false

  }

  constructor(private productController: ProductController) {
  }

  ngOnInit() {

    this.getOnlyFavorites();

  }


  getOnlyFavorites() {

    const parameter: FilterProduct = new FilterProduct().setValue(ORDER_FILTER.ONLY_FAVORITES, true);

    this.productController
      .list(parameter, true)
      .then((productList: Product[]) => this.list = productList);

  }

}
