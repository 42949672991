import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppGeneralService} from 'src/app/core/library/app-general.service';
import {BackButtonService} from "./back-button.service";

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {

  @Input() path: any;

  constructor(private backButtonService: BackButtonService) {
  }

  back = () => this.backButtonService.navigate(this.path);

}
