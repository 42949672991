import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';
import {SnackbarService} from '../library/snackbar.service';
import {AppGeneralService} from "../library/app-general.service";

@Injectable()
export class LoginController {

  controller: string = '/ct/Login/';

  params: any = {};

  constructor(
    private ctWebapiService: CtWebapiService,
    private router: Router,
    private snackBarService: SnackbarService,
    private general: AppGeneralService
  ) {
  }

  forgotPassword(email: string, callBackUrl: string): void {

    let params = {

      Email: email,
      CallBackUrlResetPassword: callBackUrl,

    };

    this.ctWebapiService.post(new DataRequest(`${this.controller}ResetPassword`), params)
      .subscribe((r: any) => {

        this.snackBarService.generalMessage('RECOVERY-PASSWORD.REQUEST_SNACKBAR');
        this.general.navigateTo('/authentication/sign-in');

      });

  }

  setNewPassword(email: string, token: string, password: string): Promise<any> {

    let params = {

      Email: email,
      Token: token,
      Password: password,

    };

    return new Promise<any>((resolve) => {
      this.ctWebapiService
        .put(new DataRequest(`${this.controller}ResetPasswordRequest`), params).subscribe(
        r => {
          resolve(r as any);
        });

    });

  }

}
