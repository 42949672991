import {Component, Input, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {ILocation, LocationController} from "../../../../core/controllers/location.controller";
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {JwtService} from "../../../../core/library/jwt.service";
import {Subscription} from "rxjs";
import {ProfileController} from "../../../../core/controllers/profile.controller";

@Component({
  selector: 'app-localization-switch-select',
  templateUrl: './localization-switch-select.component.html',
  styleUrls: ['./localization-switch-select.component.scss'],
  providers: [ProfileController]
})
export class LocalizationSwitchSelectComponent implements OnInit {

  @Input() registration: boolean = false;

  locations: ILocation[] = [];
  control: FormControl = new FormControl(null, Validators.required);

  subscription?: Subscription;

  constructor(
    public locationController: LocationController,
    private ctAuthenticationService: CtAuthenticationService,
    private jwt: JwtService,
    private profileController: ProfileController) {

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => {

        if (this.ctAuthenticationService.userValueContainsClaimsRegistry()) {

          this.setCurrentLocation();

        }

      })

  }

  ngOnInit(): void {

    this.getList();

  }

  private async setCurrentLocation() {

    const locationOid = await this.jwt.getLocationOid();

    let location: ILocation | undefined = this.locations.find((location: ILocation) => location.Oid === locationOid);

    if (!location && this.locations.length > 0) {

      location = this.locations[0];

    }

    if (location) {

      this.control.setValue(location)

    }

  }

  getList() {

    this.locationController
      .list()
      .then((locations: ILocation[]) => {

        this.locations = locations;
        this.setCurrentLocation();

      });

  }

  select() {

    if (this.registration) return;

    const value: ILocation = this.control.value;

    this.profileController
      .changeLocation(value.Oid)
      .then(() => {

        this.ctAuthenticationService
          .getClaims()
          .then()

      });
  }

}
