<div class="change-psw-cntr container p-footer">

  <h2 class="text-center mt-4"><b [innerHtml]="'FORM.EDIT_PSW' | translate"></b></h2>

  <form [formGroup]="form" (submit)="submit()">

    <mat-form-field class="w-100 b-radius mb-3" appearance="outline">

      <mat-label> {{ 'ACCOUNT.YOUR_PSW' | translate }} </mat-label>
      <input matInput [type]="viewModel.passwords.type1" autocomplete="off" formControlName="yourPsw" required>
      <button mat-icon-button matSuffix
              (click)="viewModel.passwords.type1 = formHelper.password.toggleType(viewModel.passwords.type1)"
              type="button">
        <mat-icon>{{formHelper.password.toggleMaterialIcon(viewModel.passwords.type1)}}</mat-icon>
      </button>
      <mat-error>* {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>

    </mat-form-field>

    <mat-form-field class="w-100 b-radius mb-3" appearance="outline">

      <mat-label> {{ 'ACCOUNT.NEW_PSW' | translate }} </mat-label>
      <input matInput [type]="viewModel.passwords.type2" autocomplete="off" formControlName="newPsw" required>
      <button mat-icon-button matSuffix
              (click)="viewModel.passwords.type2 = formHelper.password.toggleType(viewModel.passwords.type2)"
              type="button">
        <mat-icon>{{formHelper.password.toggleMaterialIcon(viewModel.passwords.type2)}}</mat-icon>
      </button>

        <mat-error *ngIf="form.get('newPsw')?.hasError('required')">
          * {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>
        <mat-error *ngIf="form.get('newPsw')?.hasError('passwordStrength')">
          * {{ 'FORM.PASSWORD_STRENGTH_VALIDATOR' | translate }}</mat-error>
        <mat-error *ngIf=" form.get('newPsw')?.hasError('notEqualTo')">
          * {{ 'FORM.PASSWORD_NO_EQUAL_TO' | translate }}</mat-error>

    </mat-form-field>

    <mat-form-field class="w-100 b-radius mb-3" appearance="outline">

      <mat-label> {{ 'ACCOUNT.CONFIRM_PSW' | translate }} </mat-label>
      <input matInput [type]="viewModel.passwords.type3" autocomplete="off" formControlName="confirmPsw" required>
      <button mat-icon-button matSuffix
              (click)="viewModel.passwords.type3 = formHelper.password.toggleType(viewModel.passwords.type3)"
              type="button">
        <mat-icon>{{formHelper.password.toggleMaterialIcon(viewModel.passwords.type3)}}</mat-icon>
      </button>
      <mat-error *ngIf="form.get('confirmPsw')?.hasError('required')">
        * {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>
    </mat-form-field>

    <mat-hint class="mt-0" *ngIf="passwordNotEqual && !form.get('confirmPsw')?.hasError('required')">
      * {{ 'GENERAL.PASSWORD_NOT_MATCH' | translate }}</mat-hint>
    <div class=" mt-4 col-11 m-auto ">

      <ct-button [parameters]="btnMore "></ct-button>

    </div>

  </form>

</div>
