import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {
  CompletePersonalDataEntryComponent
} from "src/app/pages/home/complete-personal-data-entry/complete-personal-data-entry.component";
import {CompletionStatusEnum} from "../enum/completion-status";
import {JwtService} from "./jwt.service";

@Injectable({
  providedIn: 'root'
})

export class UserHelperService {

  constructor(private jwt: JwtService, private dialog: MatDialog) {
  }

  checkIfUserProfileIsEnabled(): Promise<boolean> {

    return new Promise<boolean>(resolve => {

      const completionStatus: CompletionStatusEnum | null = this.jwt.getCompletionStatus();

      switch (completionStatus) {

        case CompletionStatusEnum.MISSING_BASE_INFORMATIONS:

          this.dialog
            .open(CompletePersonalDataEntryComponent);
          resolve(false);

          break;

        case CompletionStatusEnum.OK:
        default:
          resolve(true);
          break;

      }

    })

  }

}

