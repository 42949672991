import {Component, Inject, OnInit} from '@angular/core';
import {Product, RateTimeDTO} from 'src/app/core/classes/product';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {CtButtonParameters} from "@ctsolution/ct-framework";

@Component({
  selector: 'app-shop-time-table',
  templateUrl: './shop-time-table.component.html',
  styleUrls: ['./shop-time-table.component.scss']
})
export class ShopTimeTableComponent implements OnInit {

  viewModel: any = {

    openingDays: [],
    openingTime: ''

  }

  closeDialog: CtButtonParameters = CtButtonParameters.create(() => this.dialogRef.close()).setCSS('generalButton').setContent('QR.CUSTOM_CLOSE')

  constructor(@Inject(MAT_DIALOG_DATA) public product: Product, private dialogRef: MatDialogRef<ShopTimeTableComponent>) {
  }

  ngOnInit(): void {

    this.setModel()

  }

  setModel(): void {

    if (this.product.OpeningRatesTime && this.product.OpeningRatesTime.length > 0) {

      const orderedRateTimes: RateTimeDTO[] = this.product.OpeningRatesTime.sort((rateTimeA: RateTimeDTO, rateTimeB: RateTimeDTO) => (rateTimeA.Position ?? 0) - (rateTimeB.Position ?? 0));

      const firstItem: RateTimeDTO = orderedRateTimes[0];

      if (firstItem) {

        if (firstItem.OpeningDays) {

          this.viewModel.openingDays = firstItem.OpeningDays.split(',').map((element: string) => this.getDayName(element))

        }

      }

      orderedRateTimes.forEach((rateTime: RateTimeDTO, index: number) => {

        if (rateTime.Start) {

          this.viewModel.openingTime += this.getFormattedTime(rateTime.Start);

          if (rateTime.End) {

            this.viewModel.openingTime += ` - ${this.getFormattedTime(rateTime.End)}`;
          }

        }

        if (orderedRateTimes[index + 1]) {

          this.viewModel.openingTime += ' <br/> '
        }

      })

    }

  }

  private getFormattedTime(value: string) {

    let splittedValue: string[] = value.split(':');

    if (splittedValue.length > 2) {

      return `${splittedValue[0]}:${splittedValue[1]}`;

    }

    return value;

  }

  private getDayName = (dayIndex: string) => {

    const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    return days[+dayIndex];

  }
}
