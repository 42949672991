import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {CouponRequestService} from "./VPoint-coupon-request/coupon-request.service";
import {AppGeneralService} from "../../../../../core/library/app-general.service";
import {ProductCoupon} from "../coupon-section.component";

const ICON_PATH: string = '/assets/images/icons/';

@Component({
  selector: 'app-vpoint-card',
  templateUrl: './VPoint-card.component.html',
  styleUrls: ['./VPoint-card.component.scss']
})
export class VPointCardComponent implements OnInit {

  @Input() productCoupon: ProductCoupon | null = null;
  @Input() balance: number = 0;
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

  redeem: CtButtonParameters = CtButtonParameters
    .create(() => this.confirmPurchase())
    .setContent("CTBUTTON.REDEEM")
    .setCSS("purchase-button generalButton");

  detail: CtButtonParameters = CtButtonParameters
    .create(() => this.shopDetail())
    .setContent('CT-BUTTON.SHOW-SHOP')
    .setCSS("generalButton");

  constructor(
    private couponRequest: CouponRequestService,
    private appGeneralService: AppGeneralService
  ) {

  }

  ngOnInit() {

    if (this.productCoupon) {

      this.redeem
        .setDisabled(!this.productCoupon.Coupon?.checkIfPurchasableWithVPoints(this.balance));


    }

  }

  getVPointIcon = () => `${ICON_PATH}vPoints-box${!this.productCoupon?.Coupon?.checkIfPurchasableWithVPoints(this.balance) ? '-grey' : ''}.svg`

  confirmPurchase() {

    if (!this.productCoupon?.Coupon) return;

    this.couponRequest
      .request(this.productCoupon.Coupon)
      .afterClosed()
      .subscribe((result?: boolean) => {

        if (result) this.refresh.emit();

      })
  }

  shopDetail() {

    this.appGeneralService.navigateTo('/research/list/detail', {queryParams: {id: this.productCoupon?.ProductOid}});

  }

}
