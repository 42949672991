<div class="text-area-cntr">

  <ng-container *ngIf="textAreaViewModel">
  
    <mat-form-field class="w-100 b-radius" appearance="outline">
  
      <mat-label> {{ textAreaViewModel.label }} </mat-label>
      <textarea matInput [formControl]="textAreaViewModel.control" rows="4" cols="25"
        (ngModelChange)="change.emit()"></textarea>
      <mat-error>* <span [innerHTML]="['FORM.REQUIRED'|translate]"></span></mat-error>
  
    </mat-form-field>
  
  </ng-container>
  
  </div>