import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CtButtonParameters, FormHelperService, passwordStrengthValidator} from '@ctsolution/ct-framework';
import {LoginController} from 'src/app/core/controllers/login.controller';
import {SnackbarService} from 'src/app/core/library/snackbar.service';
import {AppGeneralService} from "../../../core/library/app-general.service";

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss'],
  providers: [LoginController]
})
export class SetNewPasswordComponent implements OnInit {

  form!: FormGroup;

  btnSubmit: CtButtonParameters = CtButtonParameters
    .create(() => {
    })
    .setCSS("generalButton box-shadow", true)
    .setContent("CTBUTTON.SAVE");

  viewModel: any = {

    passwords: {

      type1: 'password',
      type2: 'password'

    }

  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private loginController: LoginController,
    private snackBarService: SnackbarService,
    public formHelper: FormHelperService,
    private general: AppGeneralService
  ) {

    this.form = this.formBuilder.group({
      userEmail: new FormControl('', [Validators.required]),
      userToken: new FormControl('', [Validators.required]),
      newPsw: new FormControl('', [Validators.required, Validators.minLength(6), passwordStrengthValidator]),
      confirmPsw: new FormControl('', [Validators.required])
    });

    this.activatedRoute
      .queryParams
      .subscribe(params => {

        if (params['t']) {

          this.form.get('userToken')?.setValue(params['t']);

        }

        if (params['e']) {

          this.form.get('userEmail')?.setValue(params['e']);

        }

      });

  }

  ngOnInit() {
  }


  get passwordNotEqual() {

    const formValue = this.form.value;

    return this.form.touched && !(formValue.newPsw === formValue.confirmPsw);

  }

  passwordMatch() {

    let newPsw = this.form.get('newPsw')?.value;
    let confirmPsw = this.form.get('confirmPsw')?.value;

    return newPsw === confirmPsw;

  }

  onSubmit() {

    if (this.form.valid && this.passwordMatch()) {

      this.loginController
        .setNewPassword(this.form.value.userEmail, this.form.value.userToken, this.form.value.newPsw)
        .then(() => {

          this.snackBarService.generalMessage('RECOVERY-PASSWORD.SET_SNACKBAR');
          this.general.navigateTo('/authentication/sign-in');

        });

    }

  }

}
