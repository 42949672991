<div class="change-psw-cntr p-footer">

  <h2 class="text-center mt-4"><b [innerHtml]="'RECOVERY-PASSWORD.SET_NEW_PWD_TITLE' | translate"></b></h2>

  <ng-container *ngIf="form">

    <form [formGroup]="form" (submit)="onSubmit()" class="container">

      <mat-form-field class="w-100 b-radius mb-2" appearance="outline">

        <mat-label> {{ 'ACCOUNT.NEW_PSW' | translate }} </mat-label>
        <input matInput [type]="viewModel.passwords.type1" autocomplete="off" formControlName="newPsw" required>
        <button mat-icon-button matSuffix
                (click)="viewModel.passwords.type1 = formHelper.password.toggleType(viewModel.passwords.type1)"
                type="button">
          <mat-icon>{{formHelper.password.toggleMaterialIcon(viewModel.passwords.type1)}}</mat-icon>
        </button>
        <mat-error *ngIf="form.get('newPsw')?.hasError('required')">
          * {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>
        <mat-error *ngIf="form.get('newPsw')?.hasError('passwordStrength')">
          * {{ 'FORM.PASSWORD_STRENGTH_VALIDATOR' | translate }}</mat-error>


      </mat-form-field>

      <mat-form-field class="w-100 b-radius mb-2" appearance="outline">

        <mat-label> {{ 'ACCOUNT.YOUR_PSW' | translate }} </mat-label>
        <input matInput [type]="viewModel.passwords.type2" autocomplete="off" formControlName="confirmPsw" required>
        <button mat-icon-button matSuffix
                (click)="viewModel.passwords.type2 = formHelper.password.toggleType(viewModel.passwords.type2)"
                type="button">
          <mat-icon>{{formHelper.password.toggleMaterialIcon(viewModel.passwords.type2)}}</mat-icon>
        </button>
        <mat-error *ngIf="form.get('confirmPsw')?.hasError('required')">
          * {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>

      </mat-form-field>

      <mat-hint class="mt-0" *ngIf="passwordNotEqual && !form.get('confirmPsw')?.hasError('required')">
        * {{ 'GENERAL.PASSWORD_NOT_MATCH' | translate }}</mat-hint>

      <div class="mt-4 col-11 m-auto">

        <ct-button [parameters]="btnSubmit"></ct-button>

      </div>

    </form>

  </ng-container>

</div>
