<div class="business-cntr p-footer">

  <app-images-section [productData]="viewModel.productData"></app-images-section>

  <div class="container mt-4">

    <app-general-data [productData]="viewModel.productData"></app-general-data>

    <hr>

    <div [hidden]="subCategoriesList.length === 0">

      <h2 [innerHTML]="['FORM.SUBCATEGORY_TITLE'|translate]"></h2>

      <app-subcategories-list
        [productData]="viewModel.productData"
        [subCategoriesList]="subCategoriesList"></app-subcategories-list>

      <hr>

    </div>

    <app-opening-datas [productData]="viewModel.productData "></app-opening-datas>

    <hr class="mt-4">

    <app-price-filter
      #PriceFilter></app-price-filter>

    <hr>

    <h2 [innerHTML]="[ 'FORM.UPLOAD_BROCHURE'|translate] "></h2>

    <div class="w-75 m-auto">

      <app-file-upload [typeToAccept]="viewModel.fileTypeToAccept " (change)="uploadBrochure($event) ">
      </app-file-upload>

    </div>

  </div>

  <ng-container *ngIf="viewModel.btnSubmit ">

    <div class=" mt-4 container mb-5 w-75 m-auto ">

      <ct-button [parameters]="viewModel.btnSubmit "></ct-button>

    </div>

  </ng-container>


</div>
