<style scoped>
  .menu-sidenav-style {
    width: 80%;
    padding-left: 2%;
    padding-right: 2%;
  }

  @media screen and (min-width: 767px) and (max-width: 1024px) {
    .menu-sidenav-style {
      width: 35%;
    }
  }

  @media screen and (min-width: 1025px) {
    .menu-sidenav-style {
      width: 25%;
    }
  }
</style>

<div style="height: 100%">

  <app-navbar #navbar></app-navbar>

  <mat-sidenav-container fxFlexFill [hasBackdrop]="true">

    <mat-sidenav class="background-grey-1 menu-sidenav-style" #drawer fxLayout="column" mode="over"
                 position="end">

      <div class="container">

        <ng-container *ngIf="ctAuthenticationService.userValue">

          <app-profile-button></app-profile-button>

        </ng-container>

      </div>

      <app-menu-navigation-buttons></app-menu-navigation-buttons>

    </mat-sidenav>

    <mat-sidenav-content fxFlexFill #mainContent>

      <router-outlet (activate)="onActivate($event, outlet)" #outlet></router-outlet>

      <app-footer></app-footer>

    </mat-sidenav-content>

  </mat-sidenav-container>

</div>
