import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OpeningDatasComponent} from './opening-datas.component';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {TimeSelectModule} from './opening-rate-time/time-select/time-select.module';
import {OpeningDaysModule} from './opening-days/opening-days.module';
import {OpeningRateTimeModule} from './opening-rate-time/opening-rate-time.module';
import {CtFrameworkModule} from '@ctsolution/ct-framework';
import {TimeRadioButtonModule} from "../../_components/time-radio-button/time-radio-button.module";
import {SlideToggleModule} from "../../../../../../components/ct-framework/slide-toggle/slide-toggle.module";

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    OpeningDaysModule,
    OpeningRateTimeModule,
    SlideToggleModule,
    TimeSelectModule,
    TimeRadioButtonModule,
    CtFrameworkModule
  ],
  declarations: [OpeningDatasComponent],
  exports: [OpeningDatasComponent]
})
export class OpeningDatasModule {
}
