import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import {CtFrameworkModule} from "@ctsolution/ct-framework";

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    CtFrameworkModule
  ],
  declarations: [LoaderComponent],
  exports: [LoaderComponent]
})
export class LoaderModule { }
