<div class="maps-section-cntr">

  <div class="map-search-bar-cntr container">

    <app-search-bar></app-search-bar>

  </div>

  <div #mapContainer fxLayout="column" fxFlexFill
       [style.height]="(isMapExpanded === null ? HEIGHT_INITIAL : (isMapExpanded ? HEIGHT_EXPANDED : HEIGHT_COLLAPSED))"
       [@mapResize]="(isMapExpanded === null ? 'initial' : (isMapExpanded ? 'expanded' : 'collapsed'))">

    <div class="map" fxFlex="auto">

      <agm-map
        #map
        [style.height.px]="mapContainer.offsetHeight"
        [latitude]="viewModel.coordinates.lat"
        [longitude]="viewModel.coordinates.lng"
        [zoom]="viewModel.zoom"
        [disableDefaultUI]="true"
        [styles]="viewModel.style"
        (boundsChange)="onBoundsChange($event)">

        <agm-marker
          *ngFor="let product of products"
          [latitude]="product.Place?.Latitude ?? 0"
          [longitude]="product.Place?.Longitude  ?? 0"
          [iconUrl]="getMarkerData(product)"
          (markerClick)="focus(product)">

        </agm-marker>

        <agm-marker
          [latitude]="viewModel.coordinates.lat"
          [longitude]="viewModel.coordinates.lng"
          [iconUrl]="getCurrentLocationMarkerIcon()"
          [animation]="'BOUNCE'"
        ></agm-marker>

      </agm-map>

    </div>
  </div>

</div>
