import {Injectable} from '@angular/core';
import {BusinessProductController} from '../controllers/business-product.controller';
import {FileType} from '../enum/file-type.enum';
import {SnackbarService} from "./snackbar.service";
import {CtFileManagementService, IFile} from "@ctsolution/ct-file-management";
import {Product} from "../classes/product";
import {map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private businessProductController: BusinessProductController, private snackbar: SnackbarService, private CtFileService: CtFileManagementService) {
  }

  uploadFile(file: any, fileType: FileType, productData: Product, position: number | null = null) {

    const fileData: FileData = new FileData(file, fileType, productData.Id);

    const existingData: IFile | undefined = this.getExistingFileByType(productData.Files ?? [], fileType, position);

    if (existingData) {

      fileData.IdUpdateFile = existingData.Id;

    } else if (position || position === 0) {

      fileData.Position = position;

    }

    return this.businessProductController
      .setFile(fileData.getFormData())
      .pipe(map((res: any) => this.snackbar.generalMessage("SNACKBAR.FILE_SUCCESS")));

  }

  getExistingFileByType = (files: IFile[] = [], fileType: FileType, position: number | null = null) => (this.CtFileService.getFilesFromListByType(files, fileType) ?? []).find((element: IFile) => element.Position === position);

  deleteFile(data: IFile) {

    if (!data.Id) return;

    return this.businessProductController
      .deleteFile(data.Id)
      .pipe(map((res: any) => this.snackbar.generalMessage("SNACKBAR.FILE_DELETE")));

  }

}

export class FileData {

  Position?: number;

  constructor(
    public File: any,
    public FileType: FileType,
    public IdProduct: number,
    public IdUpdateFile?: number
  ) {

  }

  getFormData() {

    const formData: FormData = new FormData();

    const value: any = this;

    for (let key of Object.keys(value)) {

      if (value[key] || value[key] === 0) {

        if (key === 'File') {

          formData.append(key, value[key], value[key].name);

        } else {

          formData.append(key, value[key]);

        }

      }

    }

    return formData;

  }

}


