import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BusinessReportComponent} from './business-report.component';
import {CardRecapModule} from './card-recap/card-recap.module';
import {CtFrameworkModule} from '@ctsolution/ct-framework';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {FormsModule} from '@angular/forms';
import {ChartModule} from "../../_components/chart/chart.module";
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    CardRecapModule,
    CtFrameworkModule,
    ChartModule,
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    MatCardModule,
    RouterModule.forChild([
      {
        path: '',
        component: BusinessReportComponent
      }
    ])
  ],
  declarations: [BusinessReportComponent]
})
export class BusinessReportModule {
}
