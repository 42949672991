<div class="no-vpoints-dialog-cntr">


  <div class="row mb-2">

    <div class="col-4"></div>
    <div class="col-4 text-center">
      
      <mat-icon class="primary-color sad-icon">
        sentiment_very_dissatisfied</mat-icon>

    </div>
    <div class="col-4 text-end">

      <mat-icon class="primary-color" (click)="close()">close</mat-icon>

    </div>    

  </div>


  <h1 class="text-center" [innerHtml]="'NO_VPOINTS_TITLE' | translate"></h1>
  
  <h2  [innerHtml]="'NO_VPOINTS_CONTENT' | translate"></h2>

  <ct-button [parameters]="btnRedirectHome"></ct-button>

</div>
