<div class="favorites-products-cntr p-footer">

  <div class="container products-list-cntr">

    <h1 CLASS="text-center primary-color mt-3 mb-4" [innerHTML]="'CTBUTTON.FAVORITE' | translate"></h1>

    <ng-container
      *ctSkeleton="viewModel.loading;
           repeat: 6;
           className: 'card-detail-skeleton';">

      <ng-container *ngIf="list.length > 0; else emptyListTplt">

        <app-search-card [product]="product" *ngFor="let product of list"></app-search-card>

      </ng-container>

      <ng-template #emptyListTplt>

        <p class="text-center mt-5"> {{ 'NO_AVAILABLE_ELEMENTS' | translate }} </p>

      </ng-template>

    </ng-container>

  </div>

</div>
