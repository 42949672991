<div class="notification-content-cntr mt-3 mb-3">

  <div class="row">

    <div class="col-11">

      <div class="mb-1 color-blue">
        <span>
          <b>{{date}}</b>
        </span>
      </div>
      <div class="mb-1">
        <span>
          <b>{{content.Subject}}</b>
        </span>
      </div>
      <div class="mb-1 color-grey-2">
        <small>
          <b>{{content.Message}}</b>
        </small>
      </div>

    </div>

    <div class="col-1">
    
    <mat-icon *ngIf="!content.IsRead" class="notification-circle-style primary-color mb-5">circle</mat-icon>

    </div>

  </div>


</div>