import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CtAuthenticationService } from '@ctsolution/ct-authentication';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { DrawerService } from 'src/app/layout/drawer.service';

@Component({
  selector: 'app-sign-out-dialog',
  templateUrl: './sign-out-dialog.component.html',
  styleUrls: ['./sign-out-dialog.component.scss']
})
export class SignOutDialogComponent implements OnInit {

  deleteButton = CtButtonParameters
  .create((event) => {this.signOutProfile()})
  .setCSS("warning-button", true)

  .setContent("CTBUTTON.EXIT")


annulla = CtButtonParameters
  .create((event) => {
    this.close()
  })
  .setCSS("generalButton", true)

  .setContent("BUTTON.CANCEL")

  constructor(  
    public dialogRef: MatDialogRef<SignOutDialogComponent>,
    private ctAuthenticationService: CtAuthenticationService,
    private drawerService: DrawerService
    ) {}

  ngOnInit() {
  }

  close() {

    this.dialogRef.close();

  }

  signOutProfile(){
    
    this.dialogRef.close();
    this.dialogRef.afterClosed()

    sessionStorage.clear();
    this.ctAuthenticationService.signOut();
    this.drawerService.toggle();

  }

}
