import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapInfoWindowComponent } from './map-info-window.component';
import { CtButtonModule, CtFrameworkModule } from '@ctsolution/ct-framework';
import { CtFileManagementModule } from '@ctsolution/ct-file-management';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  imports: [

    CommonModule,
    CtButtonModule,
    MatIconModule,
    CtFileManagementModule,
    CtFrameworkModule,
    MatDialogModule

  ],
  declarations: [MapInfoWindowComponent],
  exports: [MapInfoWindowComponent]
})
export class MapInfoWindowModule { }
