import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StepTwoComponent} from './step-two/step-two.component';
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";
import {QRCodeModule} from "angularx-qrcode";
import {CtFrameworkModule} from "@ctsolution/ct-framework";
import {MatStepperModule} from "@angular/material/stepper";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";

@NgModule({
  declarations: [
    StepTwoComponent
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    QRCodeModule,
    CtFrameworkModule,
    MatStepperModule,
    MatProgressSpinnerModule
  ],
  exports: [StepTwoComponent]
})
export class Step2Module {
}
