import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { AppGeneralService } from 'src/app/core/library/app-general.service';

@Component({
  selector: 'app-complete-personal-data-entry',
  templateUrl: './complete-personal-data-entry.component.html',
  styleUrls: ['./complete-personal-data-entry.component.scss']
})
export class CompletePersonalDataEntryComponent implements OnInit {

  
  editProfileButton = CtButtonParameters
    .create((event) => {
      this.editProfileRedirect();
    })
    .setCSS("generalButton", true)
    .setContent("CTBUTTON.COMPLETE-PROFILE")
 

  constructor(
    public dialogRef: MatDialogRef<CompletePersonalDataEntryComponent>,
    private general: AppGeneralService
    ) { }

  ngOnInit() {
  }

  close() {

    this.dialogRef.close();

  }

  
editProfileRedirect = () => {

    this.dialogRef
      .afterClosed()
      .subscribe(() => this.general.navigateTo('/personal-area/profile/edit'));

    this.dialogRef.close();

  };


}
