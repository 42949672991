import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PriceFilterComponent} from './price-filter.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    CtFrameworkModule
  ],
  declarations: [PriceFilterComponent],
  exports: [PriceFilterComponent]
})
export class PriceFilterModule {
}
