import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {ImagesSectionComponent} from './images-section.component';
import {LogoBusinessCreateModule} from './logo-business-create/logo-business-create.module';
import {CtCarouselIvyModule} from "../../../../../../components/ct-framework/ct-carousel-ivy/ct-carousel-ivy.module";

@NgModule({
  imports: [
    CommonModule,
    CtCarouselIvyModule,
    CtButtonModule,
    LogoBusinessCreateModule,
    CtFrameworkModule
  ],
  declarations: [ImagesSectionComponent],
  exports: [ImagesSectionComponent]
})
export class ImagesSectionModule {
}
