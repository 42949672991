<div class="step-two-cntr">

  <section class="mt-5 mb-5 qr-code-section w-100 d-flex">

    <mat-spinner
      class="m-auto"
      *ngIf="!transaction?.TransactionCode; else qrTemplate"></mat-spinner>

    <ng-template #qrTemplate>

      <qrcode
        [width]="220"
        class="m-auto heartbeat"
        [qrdata]="this.getEncodedTransactionCode()!"
        [errorCorrectionLevel]="'M'"></qrcode>

    </ng-template>

    <span class="top"></span>
    <span class="right"></span>
    <span class="bottom"></span>
    <span class="left"></span>

  </section>

  <div class="text-center">

    <p><strong>{{ 'QR.CUSTOM_CODE_SUBTITLE' | translate }}</strong></p>

  </div>

</div>
