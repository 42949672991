import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScannerComponent} from './scanner/scanner.component';
import {CtButtonModule, CtFrameworkModule, CtSkeletonModule} from "@ctsolution/ct-framework";
import {ZXingScannerModule} from "@zxing/ngx-scanner";
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";

@NgModule({
  declarations: [
    ScannerComponent
  ],
  exports: [
    ScannerComponent
  ],
  imports: [
    CommonModule,
    CtFrameworkModule,
    ZXingScannerModule,
    MatProgressBarModule,
    CtSkeletonModule,
    CtButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class ScannerModule {
}
