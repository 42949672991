import { Component, Input, OnInit } from '@angular/core';
import { CtCarouselIvyParameter } from './class/CtCarouselIvyParameter';

@Component({
  selector: 'ct-carousel-ivy',
  templateUrl: './ct-carousel-ivy.component.html',
  styleUrls: ['./ct-carousel-ivy.component.scss']
})
export class CtCarouselIvyComponent implements OnInit {
  
  @Input() parameter!: CtCarouselIvyParameter

  constructor() { }

  ngOnInit() {
  }

}
