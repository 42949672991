import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Product } from 'src/app/core/classes/product';
import { OpeningDaysComponent } from './opening-days/opening-days.component';
import { OpeningRateTimeComponent } from './opening-rate-time/opening-rate-time.component';

@Component({
  selector: 'app-opening-datas',
  templateUrl: './opening-datas.component.html',
  styleUrls: ['./opening-datas.component.scss']
})
export class OpeningDatasComponent implements OnInit {

  @Input() productData: Product | undefined;

  @ViewChild(OpeningDaysComponent, { static: false })
  openingDaysComponent!: OpeningDaysComponent;

  @ViewChild(OpeningRateTimeComponent, { static: false })
  openingRateTimeComponent!: OpeningRateTimeComponent;

  // selectedRatingTimesData!: SelectedRatingTimes;
  // selectedRatingTimesArray: Array<SelectedRatingTimes> = [];

  constructor() { }

  ngOnInit() {  }

  // buildSelectedTimesArray(event: any) {

  //     if (this.openingDaysComponent) {




  //     this.selectedRatingTimesData = SelectedRatingTimes.create(event.start, event.end, event.position, this.openingDaysComponent.selectedDays);

  // controlla se all'interno dell'array esista già una rateTime con la medesima posizione
  // in quel caso cancella la precedente e pusha quella nuova
  //     this.filterArray(this.selectedRatingTimesArray, this.selectedRatingTimesData.position);

  //     this.selectedRatingTimesArray.push(this.selectedRatingTimesData);

  //   }

  // }

  // removeTimeFromArray(position: number) {

  //   this.filterArray(this.selectedRatingTimesArray, position);

  // }

  // filterArray(array: any, position: number) {

  //   const indexOfObject = array.findIndex((obj: any) => {
  //     return obj.position === position
  //   })

  //   if (indexOfObject != -1) {
  //     array.splice(indexOfObject, 1);
  //   }

  // }

}

export class SelectedRatingTimes {
  start!: string;
  end!: string;
  position!: number;
  openDays?: Array<number>;
  private constructor(start: string, end: string, position: number, openDays: number[]) {
    this.start = start;
    this.end = end;
    this.position = position;
    this.openDays = openDays
  }

  public static create(start: string, end: string, position: number, openDays: number[]): SelectedRatingTimes {
    return new SelectedRatingTimes(start, end, position, openDays);
  }

}