export enum CouponTemplateProcessTypeVO {
  RangeDateTime = 0,
  NoLimit = 1,
  Stock = 2,
  RangeDateTimeStock = 3
}

export enum CouponTemplateTypeOfUseVO {
  SingleUse = 1,
  MultipleUse = 2
}

export enum CouponTemplateStatus {
  Draft = 0,
  Active = 5,
  Rejected = 9,
  Cancelled = 10
}

export enum CouponTemplateType {
  Special = 0,
  Purchasable = 1,
  MyVictoriaCard = 100,
  Standard = 10
}
