import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TransactionCardComponent} from './transaction-card.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {DialogLeaveReviewComponent} from './dialog-leave-review/dialog-leave-review.component';
import {MatIconModule} from '@angular/material/icon';
import {InputTextAreaModule} from "../../../../components/ct-framework/input-text-area/input-text-area.module";
import {CtCardModule} from "../../../../components/ct-framework/ct-card/ct-card.module";

@NgModule({
  imports: [
    CommonModule,
    CtCardModule,
    CtButtonModule,
    MatDialogModule,
    InputTextAreaModule,
    CtFrameworkModule,
    MatIconModule,
    CtCardModule
  ],
  declarations: [TransactionCardComponent, DialogLeaveReviewComponent],
  exports: [TransactionCardComponent, DialogLeaveReviewComponent]
})
export class TransactionCardModule {
}
