<div class="qr-generator-cntr h-100 d-flex"   [@slideInOut]>

  <div class="mt-auto mb-auto w-100 container">

    <div class="text-center ">

      <h1 class="primary-color" [innerHTML]="['QR.STEP_TITLE'|translate]"></h1>

      <p> {{ 'QR.STEP-1-DESCRIPTION' | translate }}</p>

    </div>

    <mat-stepper #Stepper labelPosition="end" (selectionChange)="selectionChange($event)">

      <ng-template matStepperIcon="edit">
        <mat-icon>done</mat-icon>
      </ng-template>

      <mat-step *ngIf="!viewModel.skipNumericInput" [label]="'QR.LABEL-IMPORT'|translate">

        <app-step-one (setupAmount)="setupAmount($event)"></app-step-one>

      </mat-step>

      <mat-step [label]="'QR.LABEL-SCAN-QR'|translate">

        <app-step-two #StepTwo [(transaction)]="transaction"></app-step-two>

      </mat-step>

      <mat-step [label]="'QR.LABEL-DISCOVER-RESULT'|translate">

        <app-step-three [(transaction)]="transaction"></app-step-three>

      </mat-step>

    </mat-stepper>

    <ct-button [parameters]="close"></ct-button>

  </div>

</div>
