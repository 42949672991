import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-delete-image-confirm',
  templateUrl: './delete-image-confirm.component.html',
  styleUrls: ['./delete-image-confirm.component.scss']
})
export class DeleteImageConfirmComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<DeleteImageConfirmComponent>) { }

  ngOnInit(): void {
  }


}
