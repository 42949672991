import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { LoginController } from 'src/app/core/controllers/login.controller';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss'],
  providers: [LoginController]
})
export class RecoveryPasswordComponent implements OnInit {

  form!: FormGroup;

  viewModel: any = {

    btnSubmit: CtButtonParameters
    .create((event)=>{ this.onSubmit() })
    .setCSS("generalButton box-shadow", true)
    .setContent("RECOVERY-PASSWORD.TITLE")

  };

  constructor(
    private loginController: LoginController,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {

    this.form = this.formBuilder.group({

      mailRecovery: new FormControl('', [Validators.required, Validators.email])

    });

  }

  onSubmit(): void {

    if (this.form.valid) {

      const callbackUrl = window.location.origin + '/authentication/recovery-password/set-new-password';

      this.loginController.forgotPassword(this.form.get('mailRecovery')?.value, callbackUrl);

    }

  }

}
