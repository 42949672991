import {Component, Input, OnInit} from '@angular/core';
import {BusinessDiscount} from "../../../../../../core/controllers/business-discount.controller";
import {
  CouponTemplateStatus,
  CouponTemplateType,
  CouponTemplateTypeOfUseVO
} from "../../../../../../core/enum/coupons-enums";

@Component({
  selector: 'app-discount-list-item',
  templateUrl: './discount-list-item.component.html',
  styleUrls: ['./discount-list-item.component.scss']
})
export class DiscountListItemComponent implements OnInit {

  @Input() item!: BusinessDiscount;
  TYPES = CouponTemplateType;

  constructor() {
  }

  ngOnInit(): void {
  }

  getTypeOfUse() {

    switch (this.item.TypeOfUse) {

      case CouponTemplateTypeOfUseVO.MultipleUse:
        return 'BUSINESS.DISCOUNT_MULTIPLE_MODE';
      case CouponTemplateTypeOfUseVO.SingleUse:
      default:
        return 'BUSINESS.DISCOUNT_SINGLE_MODE';

    }

  }

  getStatus(): string {

    const currentDate: Date = new Date();

    const expirationDate: Date = new Date(<string>this.item.End);

    if (expirationDate.getTime() < currentDate.getTime() && !(this.item.Type === CouponTemplateType.Standard)) return 'STATUS.EXPIRED';

    switch (this.item.Status) {

      case CouponTemplateStatus.Rejected:
        return 'STATUS.REJECTED';
      case CouponTemplateStatus.Active:
        return 'STATUS.ACTIVE';
      case CouponTemplateStatus.Draft:
      default:
        return 'STATUS.WAITING_FOR_APPROVAL';

    }

  }

  getDiscountType = () => this.item.Type === CouponTemplateType.Special ? 'DISCOUNT.SPECIAL_DISCOUNT' : 'DISCOUNT.STANDARD_DISCOUNT';

}
