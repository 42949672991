import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {FileService} from 'src/app/core/library/file.service';
import {FileType} from "../../../../../../../core/enum/file-type.enum";
import {Product} from "../../../../../../../core/classes/product";
import {IFile} from "@ctsolution/ct-file-management";

@Component({
  selector: 'app-logo-business-create',
  templateUrl: './logo-business-create.component.html',
  styleUrls: ['./logo-business-create.component.scss']
})
export class LogoBusinessCreateComponent implements OnChanges {

  @Input() product?: Product;
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

  viewModel: any = {

    logoBusinessButton: CtButtonParameters
      .create(() => {
      })
      .setImageCss("w-100")
      .setCSS("w-100 fake-logo-image box-shadow mat-icon-button", true),

    fileTypeToAccept: ".jpg, .jpeg, .png",
    backgroundUrl: null

  }

  constructor(private fileService: FileService) {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['product']) {

      this.viewModel.backgroundUrl = this.fileService.getExistingFileByType(this.product?.Files ?? [], FileType.LOGO)?.PreviewUrl;

    }

  }

  manageFile(event: any) {

    if (this.product?.Id) {

      this.fileService
        .uploadFile(event, FileType.LOGO, this.product)
        .subscribe(() => this.refresh.emit());

    }

  }

  delete() {

    const logo: IFile | undefined = this.fileService.getExistingFileByType(this.product?.Files ?? [], FileType.LOGO);

    if (!logo) return;

    this.fileService
      .deleteFile(logo)
      ?.subscribe(() => this.refresh.emit());

  }

}
