import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {TransactionResultDto} from "../../../../../../../../core/classes/transaction-result-dto";
import {ScannerQrHelperService} from "../../../scanner-qr-helper.service";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {SnackbarService} from "../../../../../../../../core/library/snackbar.service";
import {TransactionController} from "../../../../../../../../core/controllers/transaction.controller";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {QrScannerComponent} from "../../../qr-scanner/qr-scanner.component";
import {Subscription} from "rxjs";
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {RoleHelperService} from "../../../../../../../../core/library/role-helper.service";
import {environment} from "../../../../../../../../../environments/environment";
import {Coupon} from "../../../../../../../../core/classes/coupon";
import {CtWebviewService} from "@ctsolution/ct-webview";

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
  providers: [TransactionController]
})
export class ScannerComponent implements OnInit {

  @Input() transaction: TransactionResultDto | null = null;
  @Output() transactionChange = new EventEmitter<TransactionResultDto | null>();

  askPermissionsButton: CtButtonParameters = CtButtonParameters
    .create(() => this.enableCamera())
    .setCSS("generalButton box-shadow mb-2", true)
    .setContent("GENERAL.ALLOW_CAMERA_PERMISSION");

  close: CtButtonParameters = CtButtonParameters
    .create(() => this.dialog.close())
    .setCSS("generalButton box-shadow mb-2", true)
    .setContent("QR.CUSTOM_CLOSE");

  subscription?: Subscription;

  viewModel: any = {

    cameraPermissionEnabled: false,
    latestCode: null,
    production: environment.production

  }

  scannerModel: any = {

    availableDevices: [],
    currentDevice: null

  }

  constructor(
    private businessScanService: ScannerQrHelperService,
    private snackbar: SnackbarService,
    private transactionController: TransactionController,
    private dialog: MatDialogRef<QrScannerComponent>,
    private ctAuthenticationService: CtAuthenticationService,
    private webview: CtWebviewService,
    private roleHelper: RoleHelperService) {

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => {

        if (this.ctAuthenticationService.userValueContainsClaimsRegistry()) {

          if (this.roleHelper.isEmployee()) this.setEmployeeSettings();

        }

      })

  }

  ngOnInit(): void {

    this.enableCamera();

  }

  changeCamera() {

    const indexOfSelectedCamera: number = this.scannerModel.availableDevices.indexOf(this.scannerModel.currentDevice);
    let nextIndex: number = indexOfSelectedCamera + 1;

    if (nextIndex > (this.scannerModel.availableDevices.length - 1)) nextIndex = 0;

    this.snackbar.generalMessage("SNACKBAR.LOADING_CAMERA");

    this.scannerModel.currentDevice = this.scannerModel.availableDevices[nextIndex];

  }

  camerasFoundHandler(devices: MediaDeviceInfo[]): void {

    this.scannerModel.availableDevices = devices;

  }

  private setEmployeeSettings() {

    this.close = CtButtonParameters
      .create(() => {

        this.ctAuthenticationService.signOut()
        this.dialog.close();

      })
      .setCSS("generalButton box-shadow mb-2", true)
      .setContent("CTBUTTON.EXIT");

  }

  private enableCamera() {

    this.businessScanService
      .checkCameraPermission()
      .then((active: boolean) => this.startScanning(active))

  }

  scanSuccessHandler(result: string): any {

    if (this.viewModel.latestCode !== result) {

      this.snackbar.generalMessage("SCANNER.SUCCESSFULL_READING");

      this.viewModel.latestCode = result;

      const decodedTransactionCode: string = atob(this.viewModel.latestCode);

      this.transactionController
        .bind(decodedTransactionCode)
        .then((data: CtWebapiGenericResponse<any>) => {

          const transaction: TransactionResultDto = <TransactionResultDto>data.Result;

          transaction.Coupons = transaction.Coupons.map((coupon: Coupon) => new Coupon(coupon));

          this.transactionChange.emit(transaction);

        })
        .catch(() => this.snackbar.generalMessage('SNACKBAR.GENERIC_ERROR'));

    }

  }

  private startScanning = (enabled: boolean) => this.viewModel.cameraPermissionEnabled = enabled;

}
