<div class="location-search-bar-cntr">

  <div class="text-center">

    <div class="search-bar-cntr container">

      <div class="input-container">

        <form [formGroup]="form" autocomplete="off">

          <mat-form-field appearance="outline" class="search-input b-radius w-100">

            <img src="/assets/images/icons/map_filter.svg" class="map-filter-icon me-2">
            <input matInput type="text" autocomplete="off" matGoogleMapsAutocomplete
                   [placeholder]="'SEARCH.BY_PLACE'|translate" [types]="['geocode']" [country]="['it']"
                   (onLocationSelected)="getCoordinates($event)" [value]="locationName"
                   (onAutocompleteSelected)="onAutocompleteSelected($event)"
                   (change)="onEditChange($event)"
                   formControlName="location">

          </mat-form-field>

        </form>

        <div>

        </div>

      </div>

    </div>

  </div>

</div>
