<mat-accordion>

  <mat-expansion-panel [expanded]="parameter.expand" hideToggle>

    <mat-expansion-panel-header *ngIf="parameter.headerShow()" (click)="icon = !icon">

      <mat-panel-title *ngIf="parameter.title" [innerHTML]="parameter.title | translate"></mat-panel-title>

      <mat-icon  class="primary-color" >{{icon ? 'expand_less' : 'expand_more'}}</mat-icon>

      <mat-panel-description *ngIf="parameter.description" [innerHTML]="parameter.description | translate"></mat-panel-description>

    </mat-expansion-panel-header>

    <ng-content></ng-content>

    <ng-template #accordionTemplate [ngTemplateOutlet]="parameter.templateRef"></ng-template>

  </mat-expansion-panel>

</mat-accordion>