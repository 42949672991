import {Component, OnInit} from '@angular/core';
import {CtAuthenticationService} from '@ctsolution/ct-authentication';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {AppGeneralService} from 'src/app/core/library/app-general.service';
import {DrawerService} from "../../drawer.service";
import {BackButtonService} from "../../_components/back-button/back-button.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  logoButton: CtButtonParameters = CtButtonParameters
    .create(() => this.appGeneralService.redirectToHome())
    .setImage("/assets/images/logo/Vlogo.svg")
    .setImageCss("vLogoCustom");

  toggleButton: CtButtonParameters = CtButtonParameters
    .create(() => this.toggleMenu())
    .setImage("/assets/images/icons/menu_icon.svg").setImageCss('menu-icon-header');

  constructor(
    public backButtonService: BackButtonService,
    public ctAuthenticationService: CtAuthenticationService,
    private appGeneralService: AppGeneralService,
    private drawerService: DrawerService
  ) {
  }

  ngOnInit() {
  }

  changeToggleDrawerIcon = (opened: boolean) => this.toggleButton.setImage(`/assets/images/icons/${opened ? 'close_icon' : 'menu_icon'}.svg`);

  toggleMenu = () => this.drawerService.toggle();

}
