<div class="chart-wrapper">
    
    <ng-container *ngIf="chartData.labels && chartData.labels.length > 0; else noChartDataTpl">

        <canvas baseChart [data]="chartData" [options]="chartOptions" [type]="chartType" [plugins]="barChartPlugins" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>

    </ng-container>

    <ng-template #noChartDataTpl>

        <p class="mt-4 text-center" [innerHTML]="'CHART.NODATA' | translate"></p>

    </ng-template>

</div>