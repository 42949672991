import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AddressResult, Coordinates, CtMapsService} from '@ctsolution/ct-maps';
import {Product} from 'src/app/core/classes/product';
import PlaceResult = google.maps.places.PlaceResult;
import {LANGUAGE_CODES} from "../../../../../../app.module";

@Component({
  selector: 'app-general-data',
  templateUrl: './general-data.component.html',
  styleUrls: ['./general-data.component.scss']
})
export class GeneralDataComponent implements OnInit, OnChanges {

  @Input() productData: Product | undefined;

  form: FormGroup;

  constructor(private ctMapsService: CtMapsService, private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({

      name: new FormControl('', Validators.required),
      phone: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^((00|\+)39[\. ]??)??3\d{2}[\. ]??\d{6,7}$/),
      ]),
      description: this.formBuilder.array([])

    });

  }

  ngOnInit() {

    LANGUAGE_CODES.forEach((lang: string) => this.addLanguageDescriptionControl(lang));

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['productData']) {

      if (this.productData) {

        this.setupProduct(this.productData);

      }

    }

  }

  get descriptions(): FormArray {

    return this.form.get('description') as FormArray;

  }

  castAbstractControlToFormControl(control: any): FormGroup {

    return control as FormGroup

  }

  addLanguageDescriptionControl(LanguageCode: string) {

    const form = {
      Id: new FormControl(null),
      LabelCode: new FormControl('description'),
      Value: new FormControl(null, Validators.required),
      LanguageCode: new FormControl(LanguageCode)
    }

    const descriptionForm = this.formBuilder.group(form);

    this.descriptions.push(descriptionForm);

  }

  setupProduct(product: Product) {

    if (product.Name) {

      this.form.get('name')?.setValue(product.Name);

    }

    if (product.PhoneNumber) {

      this.form.get('phone')?.setValue(product.PhoneNumber);

    }

    if (product.Localizations && product.Localizations.length > 0) {

      this.setupDescriptions(product.Localizations);

    }

  }

  setupDescriptions(localizations: any) {

    const currentFormValue = this.descriptions.value;

    const descriptions = localizations.filter((element: any) => element.LabelCode === 'description');

    currentFormValue.forEach((element: any, index: number) => {

      let toFind = descriptions.find((item: any) => item.LanguageCode === element.LanguageCode);

      if (toFind) {

        const form = this.descriptions.at(index);

        form.get('Id')?.setValue(toFind.Id);
        form.get('Value')?.setValue(toFind.Value);

      }

    });

  }

}
