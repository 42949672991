import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActiveFiltersListComponent} from './active-filters-list/active-filters-list.component';
import {MatLegacyChipsModule as MatChipsModule} from "@angular/material/legacy-chips";
import {MatIconModule} from "@angular/material/icon";
import {CtFrameworkModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    ActiveFiltersListComponent
  ],
  imports: [
    CommonModule,
    MatChipsModule,
    MatIconModule,
    CtFrameworkModule
  ],
  exports: [
    ActiveFiltersListComponent
  ]
})
export class ActiveFiltersListModule {
}
