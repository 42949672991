import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {
  SearchService
} from "../../pages/research/search-list/search-list/_components/search-filters/search-filters/search.service";

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  control: FormControl = new FormControl(null);

  constructor(private searchService: SearchService) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {

    if (this.searchService.filters.Name) {

      this.control.setValue(this.searchService.filters.Name);

    }

  }

  search() {

    const value: string | null = this.control.value;

    setTimeout(() => {

      const lastValue: string | null = this.control.value;

      if (value === lastValue) {

        this.searchService.filters.setValue('Name', lastValue);
        this.searchService.search();

      }

    }, 500);

  }

}
