import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OpeningRateTimeComponent} from './opening-rate-time.component';
import {TimeSelectModule} from './time-select/time-select.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {TimeRadioButtonModule} from "../../../_components/time-radio-button/time-radio-button.module";
import {SlideToggleModule} from "../../../../../../../components/ct-framework/slide-toggle/slide-toggle.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SlideToggleModule,
    TimeRadioButtonModule,
    TimeSelectModule,
    CtFrameworkModule,
    CtButtonModule
  ],
  declarations: [OpeningRateTimeComponent],
  exports: [OpeningRateTimeComponent]
})
export class OpeningRateTimeModule {
}
