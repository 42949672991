import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QrGeneratorComponent} from './qr-generator/qr-generator.component';
import {Step1Module} from "./steps/step-1/step-1.module";
import {Step2Module} from "./steps/step-2/step-2.module";
import {Step3Module} from "./steps/step-3/step-3.module";
import {MatStepperModule} from "@angular/material/stepper";
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [
    QrGeneratorComponent
  ],
  imports: [
    CommonModule,
    Step1Module,
    Step2Module,
    Step3Module,
    MatStepperModule,
    CtButtonModule,
    MatDialogModule,
    CtFrameworkModule,
    MatIconModule
  ],
  exports: [QrGeneratorComponent]
})
export class QrGeneratorModule {
}
