import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/core/classes/product';
import { PriceCategoryEnum } from 'src/app/core/enum/price-category.enum';

const CURRENCY: string = '€';

@Component({
  selector: 'app-price-category-button',
  templateUrl: './price-category-button.component.html',
  styleUrls: ['./price-category-button.component.scss']
})
export class PriceCategoryButtonComponent implements OnInit {

  @Input() price!: Product;

  constructor() { }

  ngOnInit() {
  }

  getPriceIcon() {

    switch(this.price.PriceCategory) {

      case PriceCategoryEnum.ReallyCheap:
      default:
        return CURRENCY;

      case PriceCategoryEnum.Economic:
        return CURRENCY + CURRENCY;

      case PriceCategoryEnum.Moderate:
        return CURRENCY + CURRENCY + CURRENCY;

    }

  }

}
