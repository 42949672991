<div class="notifications-cntr fixed-container-height p-footer">

    <div class="container mt-5">

        <img src="/assets/images/icons/menu/notifications.svg" alt="notifications">

        <h1 class="ms-3 mb-4" [innerHTML]="['GENERAL.NOTIFICATIONS' | translate]"></h1>

        <app-notification-area></app-notification-area>

    </div>

</div>