<div class="actions-cntr mb-4">

    <h1 class="text-center mb-4" [innerHTML]="['GENERAL.YOUR_ACTIONS' | translate]"></h1>

    <ng-container *ngIf="transactionCards.length > 0; else noTransactionsTplt">

        <app-transaction-card *ngFor="let param of transactionCards" [parameter]="param"></app-transaction-card>

    </ng-container>

</div>


<ng-template #noTransactionsTplt>

    <mat-list-item role="listitem">

     <small><strong [innerHTML]="'GENERAL.NO_AVAILABLE_ACTIONS' | translate"></strong></small>   

    </mat-list-item>

</ng-template>