import {Injectable} from '@angular/core';
import {AppGeneralService} from "./app-general.service";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {
  QrGeneratorComponent
} from "../../pages/personal-area/customer-area/_components/qr-generator/qr-generator/qr-generator.component";
import {
  ScannerQrHelperService
} from "../../pages/personal-area/business-area/_components/qr-scanner/scanner-qr-helper.service";
import {Coupon} from "../classes/coupon";
import {CouponRequestDTO} from "../interfaces/coupon-request";

@Injectable({
  providedIn: 'root'
})
export class CouponHelperService {

  constructor(private generalService: AppGeneralService, private scannerQrHelperService: ScannerQrHelperService) {
  }

  useCoupon = (coupon: CouponRequestDTO): MatDialogRef<QrGeneratorComponent> => this.scannerQrHelperService.openQrGenerator(coupon)

  getMaxDiscountValueFromCouponList = (list: Coupon[]): number => Math.max(...list.map((discount: Coupon) => discount.Discount ?? 0))

}
