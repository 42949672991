<form [formGroup]="form">

    <div class="row time-picker-cntr">

        <div class="col-6">

            <p class="ps-2" [innerHtml]="'FORM.OPENING_TIME' | translate"></p>

            <mat-form-field>
                <input matInput [ngxMatTimepicker]="pickerA" name="Start" [format]="24" formControlName="Start" readonly />
                <mat-icon matSuffix (click)="pickerA.open()">
                    watch_later
                </mat-icon>
            </mat-form-field>
            <ngx-mat-timepicker [minutesGap]="10" color="accent" #pickerA></ngx-mat-timepicker>

        </div>

        <div class="col-6">

            <p class="ps-2" [innerHtml]="'FORM.CLOSE_TIME' | translate"></p>

            <mat-form-field>
                <input matInput [ngxMatTimepicker]="pickerB" name="End" [format]="24" formControlName="End" readonly />
                <mat-icon matSuffix (click)="pickerB.open()">
                    watch_later
                </mat-icon>
            </mat-form-field>
            <ngx-mat-timepicker [minutesGap]="10" color="accent" #pickerB></ngx-mat-timepicker>

        </div>

    </div>

</form>