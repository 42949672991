<div class="search-active-filters" #target>

  <div class="container">

    <hr (click)="onAccordionClick()">

    <div class="active-filters-buttons">

      <ct-button [parameters]="btnShowAllFilters"></ct-button>

      <app-active-filters-list></app-active-filters-list>

    </div>

  </div>

</div>
