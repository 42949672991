import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceCategoryButtonComponent } from './price-category-button.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PriceCategoryButtonComponent],
  exports:[PriceCategoryButtonComponent]
})
export class PriceCategoryButtonModule { }
