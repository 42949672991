import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {MatDrawer} from "@angular/material/sidenav";
import {DrawerService} from "../drawer.service";
import {NavbarComponent} from "./navbar/navbar.component";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html'
})
export class FullComponent implements OnInit {

  @ViewChild('drawer') public drawer!: MatDrawer;
  @ViewChild('navbar') public navbar!: NavbarComponent;

  subscription?: Subscription;

  constructor(
    public ctAuthenticationService: CtAuthenticationService,
    private drawerService: DrawerService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {

    this.drawerService.setDrawer(this.drawer);
    this.subscribeToDrawerState();

  }

  subscribeToDrawerState() {

    this.subscription = this.drawerService
      .drawer
      ?.openedChange
      .subscribe((o: boolean) => this.navbar.changeToggleDrawerIcon(o));

  }

  onActivate(e: any, outlet: { scrollTop: number; }) {

    outlet.scrollTop = 0;
    document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0, 0)

  }

  ngAfterViewChecked(): void {

    this.cdr.detectChanges();

  }

}
