import {Injectable} from '@angular/core';
import {MatLegacyDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {CtWebviewService} from "@ctsolution/ct-webview";
import {MatDialogConfig, NgDialogAnimationService} from "ng-dialog-animation";

import {CouponRequestDTO} from "../../../../../core/interfaces/coupon-request";
import {
  QrGeneratorComponent
} from "../../../customer-area/_components/qr-generator/qr-generator/qr-generator.component";
import {QrScannerComponent} from "./qr-scanner/qr-scanner.component";

const BUSINESS_SCAN_DIALOG_CONFIGURATION: any = {
  maxWidth: '100vw',
  maxHeight: '100vh',
  height: '100%',
  width: '100%',
  disableClose: true,
  animation: {to: "aside"}
};

@Injectable({
  providedIn: 'root'
})
export class ScannerQrHelperService {

  constructor(private dialog: MatLegacyDialog, private ctWebViewService: CtWebviewService) { // TODO: Marghe, devi trovare un sostituto a NgDialogAnimationService che serviva per dare l'animazione di entrata.
  }

  openScanner() {

    if (this.ctWebViewService.isCtWebView() && this.ctWebViewService.isAndroidWebView()) {

      this.ctWebViewService.AndroidFunctions.checkCameraPermissions();

    }

    this.dialog.open(QrScannerComponent, BUSINESS_SCAN_DIALOG_CONFIGURATION);

  }

  openQrGenerator(coupon?: CouponRequestDTO): MatDialogRef<QrGeneratorComponent> {

    const configuration: MatDialogConfig = BUSINESS_SCAN_DIALOG_CONFIGURATION;

    configuration.data = coupon;

    return this.dialog.open(QrGeneratorComponent, configuration);

  }

  checkCameraPermission(): Promise<boolean> {

    return new Promise<boolean>((resolve) => {

      if (this.ctWebViewService.isAndroidDevice()) {

        resolve(true);
        return;

      }

      // @ts-ignore
      navigator.permissions.query({name: "camera"})
        .then((res: { state: any }) => {

          const enabled: boolean = res.state == "granted";

          if (enabled) {

            resolve(enabled);
            return;

          }

          resolve(this.askForCameraPermission());

        })

    });

  }

  private askForCameraPermission(): Promise<boolean> {

    return new Promise<boolean>((resolve) => {

      navigator.mediaDevices.getUserMedia({video: true})
        .then((res: { active: boolean }) => resolve(res.active))
        .catch(() => console.log("Camera Permission denied"));

    });

  }

}
