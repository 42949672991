import { FormControl, ValidatorFn } from "@angular/forms";

export class SlideToggleConfiguration {

  private _label: string = '';
  get label(): string {

    return this._label;

  }

  private set label(value: string) {

    this._label = value;

  }

  private _prefixLabel: string | null = null;
  get prefixLabel(): string | null {

    return this._prefixLabel;

  }

  private set prefixLabel(value: string | null) {

    this._prefixLabel = value;

  }

  private _control: FormControl = new FormControl(false);
  get control(): FormControl {

    return this._control;

  }

  constructor() {
  }

  public static create = () => new SlideToggleConfiguration();

  /**
   * It sets the label property of the SlideToggleConfiguration object.
   * @param {string} value - The value of the slide toggle.
   * @returns The SlideToggleConfiguration object.
   */
  setLabel(value: string): SlideToggleConfiguration {

    this.label = value;
    return this;

  }

  /**
   * It sets the prefix label.
   * @param {string} value - string - The value of the slide toggle.
   * @returns The SlideToggleConfiguration object.
   */
  setPrefixLabel(value: string): SlideToggleConfiguration {

    this.prefixLabel = value;
    return this;

  }

  /**
   * @deprecated: non necessaria per il toggle
   */
  setValidators(validators: ValidatorFn | ValidatorFn[]): SlideToggleConfiguration {

    this.control.setValidators(validators);
    this.control.updateValueAndValidity();

    return this;

  }

  /**
   * "Set the value of the control to the value passed in, and then update the value and validity of the control."
   *
   * The first line of the function sets the value of the control to the value passed in. The second line updates the value
   * and validity of the control
   * @param {boolean} value - boolean - The value to set the control to.
   * @returns The SlideToggleConfiguration object.
   */
  setControlValue(value: boolean): SlideToggleConfiguration {

    this.control.setValue(value);
    this.control.updateValueAndValidity();

    return this;

  }

  setControlDisabled(value: boolean): SlideToggleConfiguration {

    if (value) {
      this.control.disabled;
      this.control.updateValueAndValidity();
    }
    return this;
  }

}
