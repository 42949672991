import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {LocalizationSwitchSelectComponent} from "./localization-switch-select.component";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CtFrameworkModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    LocalizationSwitchSelectComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    CtFrameworkModule
  ],
  exports: [LocalizationSwitchSelectComponent]
})
export class LocalizationSwitchSelectModule {
}
