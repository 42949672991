import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ILocalization } from 'src/app/core/classes/localization';

@Component({
  selector: 'app-content-area',
  templateUrl: './content-area.component.html',
  styleUrls: ['./content-area.component.scss']
})
export class ContentAreaComponent implements OnInit {

  @Input() configuration!: ContentAreaParameter;

  viewModel: any = {

    expanded: false,
    expandable: false,
    content: '',

    setup: (lang: string) => {

      let toFind: ILocalization | undefined = this.configuration.data.find((e: ILocalization) => e.LanguageCode === lang);
      if (!toFind) { toFind = this.configuration.data[0]; }

      this.viewModel.content = toFind.Value;
      this.viewModel.expanded = false;
      this.viewModel.expandable = this.viewModel.content.length > 450

    }

  }

  constructor(private translate: TranslateService) {

  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {

     this.viewModel.setup(this.translate.currentLang)

  }

  showMore() {

    this.viewModel.expanded = !this.viewModel.expanded

  }

}

export class ContentAreaParameter {

  class?: string;

  constructor(
    public title: string | null,
    public data: ILocalization[] = []
  ) { }

}
