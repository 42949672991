export class CtCarouselIvyParameter {
  //Mandatory
  pictures!: Array<IvyPathImage>;
  componentSettings!: ComponentSettings;
  private _picturesPaths!: Array<string>

  //Optional


  constructor(picturesPaths: Array<string>) {

    this.componentSettings = new ComponentSettings();

    this._picturesPaths = picturesPaths;
    this.pictures = new Array<IvyPathImage>();
    this._picturesPaths?.forEach(path => this.pictures.push(IvyPathImage.Create(path)));

  }

  static Create(picturesPaths: Array<string>): CtCarouselIvyParameter {
    return new CtCarouselIvyParameter(picturesPaths);
  }


  setArrows(value: boolean): CtCarouselIvyParameter {
    this.componentSettings.arrows = value;
    return this;
  }

  setPictures(pictures: Array<string>) {

    this._picturesPaths = pictures;
    this.pictures = new Array<IvyPathImage>();
    this._picturesPaths?.forEach(path => this.pictures.push(IvyPathImage.Create(path)));

    return this;

  }

  setArrowsOutside(value: boolean): CtCarouselIvyParameter {
    this.componentSettings.arrowsOutside = value;
    return this;
  }
  setDots(value: boolean): CtCarouselIvyParameter {
    this.componentSettings.dots = value;
    return this;
  }
  setLoop(value: boolean): CtCarouselIvyParameter {
    this.componentSettings.loop = value;
    return this;
  }
  setAutoplay(value: boolean): CtCarouselIvyParameter {
    this.componentSettings.autoplay = value;
    return this;
  }
  setPauseOnHover(value: boolean): CtCarouselIvyParameter {
    this.componentSettings.pauseOnHover = value;
    return this;
  }
  setCellsToShow(value: number): CtCarouselIvyParameter {
    this.componentSettings.cellsToShow = value;
    return this;
  }
  setCellWidth(value: number | "100%"): CtCarouselIvyParameter {
    this.componentSettings.cellWidth = value;
    return this;
  }
  setHeight(value: number): CtCarouselIvyParameter {
    this.componentSettings.height = value;
    return this;
  }
  setCellsToScroll(value: number): CtCarouselIvyParameter {
    this.componentSettings.cellsToScroll = value;
    return this;
  }
  overflowCellsLimit(value: number): CtCarouselIvyParameter {
    this.componentSettings.overflowCellsLimit = value;
    return this;
  }

  autoplayInterval(value: number): CtCarouselIvyParameter {
    this.componentSettings.autoplayInterval = value;
    return this;
  }

  setTransitionDuration(value: number): CtCarouselIvyParameter {
    this.componentSettings.transitionDuration = value;
    return this;
  }



  setObjectFit(value: 'contain' | 'cover' | 'none'): CtCarouselIvyParameter {
    this.componentSettings.objectFit = value;
    return this;
  }

}
export class IvyPathImage {
  path!: string;
  constructor(path: string) {
    this.path = path;
  }

  static Create(path: string): IvyPathImage {
    return new IvyPathImage(path);
  }
}

export class ComponentSettings {
  arrows!: boolean;
  arrowsOutside!: boolean;
  cellsToShow!: number;
  cellWidth!: number | "100%";
  height!: number;
  dots!: boolean;
  loop!: boolean;
  cellsToScroll!: number;
  autoplay!: boolean;
  objectFit!: 'contain' | 'cover' | 'none';
  transitionDuration!: number;
  autoplayInterval!: number;
  overflowCellsLimit!: number;
  pauseOnHover!: boolean;

  constructor() {
    this.arrows = true;
    this.cellsToShow = 1;
    this.cellWidth = "100%";
    this.height = 200;
    this.dots = false;
    this.loop = true;
    this.cellsToScroll = 1;
    this.autoplay = false;
    this.objectFit = "cover";
    this.transitionDuration = 500;
    this.overflowCellsLimit = 1;
    this.autoplayInterval = 7000;
    this.arrowsOutside = false;
    this.pauseOnHover = true;
  }
}
