import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {TransactionResultDto} from "../../../../../../core/classes/transaction-result-dto";
import {MatStepper} from "@angular/material/stepper";
import {StepTwoComponent} from "../steps/step-2/step-two/step-two.component";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {CouponRequestDTO} from "../../../../../../core/interfaces/coupon-request";
import {CouponRequestEnum} from "../../../../../../core/enum/coupon-request";
import { animate, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'app-qr-generator',
  templateUrl: './qr-generator.component.html',
  styleUrls: ['./qr-generator.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('400ms', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('400ms', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class QrGeneratorComponent implements OnInit {

  @ViewChild('Stepper') stepper?: MatStepper;
  @ViewChild('StepTwo') QrCodeGenerator?: StepTwoComponent;

  transaction: TransactionResultDto | null = null;
  viewModel = {

    skipNumericInput: false

  }

  close: CtButtonParameters = CtButtonParameters
    .create(() => this.cancel())
    .setCSS("generalButton box-shadow mb-2", true)
    .setContent("BUTTON.CANCEL");

  constructor(private dialog: MatDialogRef<QrGeneratorComponent>, @Inject(MAT_DIALOG_DATA) public coupon?: CouponRequestDTO) {

    if (coupon) {

      this.transaction = <TransactionResultDto>{CouponCode: coupon.Code};
      this.viewModel.skipNumericInput = coupon.CouponRequest !== CouponRequestEnum.AMOUNT_MANDATORY;

    }

  }

  ngAfterViewInit() {

    if (this.viewModel.skipNumericInput) {

      this.QrCodeGenerator?.initializeTransaction();

    }

  }

  setupAmount(value: number) {

    this.transaction = <TransactionResultDto>{Amount: value};

    if (this.coupon?.Code) this.transaction.CouponCode = this.coupon.Code;

    this.stepper?.next();

  }

  selectionChange(event: StepperSelectionEvent) {

    this.QrCodeGenerator?.resetPolling();

    if (!this.viewModel.skipNumericInput) {

      switch (event.selectedIndex) {

        case 1:
          this.QrCodeGenerator?.initializeTransaction();
          break;

      }

      this.close.setContent(event.selectedIndex === 2 ? "QR.CUSTOM_CLOSE" : "BUTTON.CANCEL");

    } else {

      this.close.setContent(event.selectedIndex === 1 ? "QR.CUSTOM_CLOSE" : "BUTTON.CANCEL");

    }

  }

  ngOnInit(): void {
  }

  cancel() {

    this.QrCodeGenerator?.resetPolling();

    if (this.stepper?.selectedIndex == 1) {

      this.stepper.previous();

    } else {

      this.dialog.close();

    }

  }

}
