import {UserCoupon} from "../../../../../../../core/classes/user-coupon";

export class MyCouponConfiguration {

  private _coupon: UserCoupon | null = null;
  private _class: string | null = null;

  get coupon(): UserCoupon | null {

    return this._coupon;

  }

  private set coupon(value: UserCoupon | null) {

    this._coupon = value;

  }

  get class(): string | null {

    return this._class;

  }

  private set class(value: string | null) {

    this._class = value;

  }

  constructor(coupon: UserCoupon) {

    this.setCoupon(coupon);

  }

  public static create = (coupon: UserCoupon): MyCouponConfiguration => new MyCouponConfiguration(coupon);

  setCoupon(value: UserCoupon): MyCouponConfiguration {

    this.coupon = value;
    return this;

  }

  setClass(value: string): MyCouponConfiguration {

    this.class = value;
    return this;

  }

}
