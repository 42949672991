import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchFiltersComponent} from './search-filters/search-filters.component';
import {OrderFilterModule} from "./_components/order-filter/order-filter.module";
import {DistanceFilterModule} from "./_components/distance-filter/distance-filter.module";
import {PriceFilterModule} from "./_components/price-filter/price-filter.module";
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {CategoriesListModule} from "../../../../categories/categories-list/categories-list.module";
import { RemoveFilterButtonComponent } from './search-filters/remove-filter-button/remove-filter-button.component';

@NgModule({
  declarations: [
    SearchFiltersComponent,
    RemoveFilterButtonComponent
  ],
  imports: [
    CommonModule,
    OrderFilterModule,
    DistanceFilterModule,
    PriceFilterModule,
    CtButtonModule,
    MatIconModule,
    CtFrameworkModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    CategoriesListModule
  ]
})
export class SearchFiltersModule {
}
