import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShopTimeTableComponent } from './shop-time-table.component';
import {MatIconModule} from "@angular/material/icon";
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";


@NgModule({
  declarations: [
    ShopTimeTableComponent
  ],
  imports: [
    CommonModule,
    CtFrameworkModule,
    MatIconModule,
    CtButtonModule
  ],
  exports: [
    ShopTimeTableComponent
  ]
})
export class ShopTimeTableModule { }
