<div class="registration-welcome-cntr d-flex pt-5 pb-5">

  <div class="m-auto text-center container">

    <div class="h-100 mb-5">

      <img class="m-auto w-25" src='/assets/images/logo/Vlogo.svg'>

    </div>

    <h2 [innerHTML]="['WELCOME_TO_VCARD.TITLE' | translate]"></h2>


    <img src="/assets/images/welcome-vCard.png" height="250" class="mt-3 mb-5">

    <p [innerHTML]="['WELCOME_TO_VCARD.CONTENT' | translate]"></p>

    <div class="mt-5 container mb-5">

      <!-- <small class="text-center" [innerHTML]="['WELCOME_TO_VCARD.SUB_CONTENT' | translate]"></small> -->

      <ct-button  [parameters]="viewModel.btnMore"></ct-button>

    </div>

  </div>

</div>