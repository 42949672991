<div class="delete-profile-cntr container">

  <div class="row">

    <div class="col-3">
      <mat-icon class="alert-icon">notification_important</mat-icon>
    </div>
    <div class="col-9">
      <h1 class="primary-color" [innerHTML]="['DELETE-PROFILE.TITLE'|translate]"></h1>
    </div>

  </div>

      <!-- <mat-icon class="alert-icon">notification_important</mat-icon>

      <h1 class="primary-color" [innerHTML]="['DELETE-PROFILE.TITLE'|translate]"></h1> -->

    <h2><strong [innerHTML]="['DELETE-PROFILE.SUB-TITLE'|translate]"></strong></h2>

    <p [innerHTML]="['DELETE-PROFILE.CONTENT'|translate]"></p>

    <div class="row mt-4">

      <div class="col-6">

        <ct-button [parameters]="deleteButton"></ct-button>

      </div>

      <div class="col-6">

        <ct-button [parameters]="annulla"></ct-button>

      </div>
    
    </div>

</div>