import {Component, OnInit} from '@angular/core';
import {CtAuthenticationService} from '@ctsolution/ct-authentication';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {Subscription} from 'rxjs';
import {AppGeneralService} from 'src/app/core/library/app-general.service';
import {JwtService} from "../../../../../../../core/library/jwt.service";

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit {

  subscription: Subscription;
  viewModel = {

    profileImgUrl: ''

  }

  editButton: CtButtonParameters = CtButtonParameters
    .create(() => this.appGeneralService.navigateTo('/personal-area/profile/avatar'))
    .setMaterialIcon("edit")
    .setCSS("box-shadow edit-button", true);

  constructor(
    private appGeneralService: AppGeneralService,
    private ctAuthenticationService: CtAuthenticationService,
    private jwt: JwtService
  ) {

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => this.viewModel.profileImgUrl = this.jwt.getUserAvatarSrc());

  }

  ngOnInit() {

  }

  ngOnDestroy(): void {

    this.subscription.unsubscribe();

  }

}
