import {Component, Input, OnInit} from '@angular/core';
import {Product} from "../../../../../core/classes/product";
import {AppGeneralService} from "../../../../../core/library/app-general.service";
import {CtFileManagementService, IFile} from "@ctsolution/ct-file-management";
import {DEFAULT_LOGO, ShopHelperService} from "../../../../../core/library/shop-helper.service";
import {RoleHelperService} from "../../../../../core/library/role-helper.service";
import {CouponHelperService} from "../../../../../core/library/coupon-helper.service";
import {FileType} from "../../../../../core/enum/file-type.enum";
import {CouponTemplateType} from "../../../../../core/enum/coupons-enums";

@Component({
  selector: 'app-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss'],
  providers: [CtFileManagementService]
})
export class SearchCardComponent implements OnInit {

  @Input() product: Product | null = null;

  viewModel: any = {
    coverImg: undefined,
    logoImg: undefined,
    coupon: false,
    specialSale: false,
    maxDiscount: 0

  }

  constructor(
    private appGeneralService: AppGeneralService,
    private ctFileManagementService: CtFileManagementService,
    private shopHelperService: ShopHelperService,
    public roleHelperService: RoleHelperService,
    private couponHelper: CouponHelperService
  ) {
  }

  ngOnInit() {

    if (this.product && this.product.Files && this.product.Files?.length > 0) {

      this.viewModel.coverImg = this.ctFileManagementService.getFilesFromListByType(this.product.Files, FileType.COVER)?.shift();
      this.viewModel.logoImg = this.ctFileManagementService.getFilesFromListByType(this.product.Files, FileType.LOGO)?.shift();

    }

    if (this.product?.Category) {

      if (!this.viewModel.coverImg) {

        this.viewModel.coverImg = this.shopHelperService.getDefaultPicturesByCategory(this.product.Category)[0] ?? null;

      }

    }

    if (!this.viewModel.logoImg) this.viewModel.logoImg = <IFile>{PreviewUrl: DEFAULT_LOGO}

    if (this.product?.Coupons) {

      this.viewModel.coupon = this.product.Coupons.some(data => data.Type == CouponTemplateType.Purchasable);
      this.viewModel.specialSale = this.product.Coupons.some(data => data.Type == CouponTemplateType.Special);

    }

    if (this.product?.Coupons) {

      this.viewModel.maxDiscount = this.couponHelper.getMaxDiscountValueFromCouponList(this.product?.Coupons ?? []);

    }

  }

  goInfoDetail() {

    sessionStorage.setItem('infoDetail', `${this.product?.Id}`)
    this.appGeneralService.navigateTo('/research/list/detail', {queryParams: {id: this.product?.Id}});

  }

}
