import {Injectable} from "@angular/core";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {Observable} from "rxjs";
import {Product} from "../classes/product";
import {
  FilterProduct
} from "../../pages/research/search-list/search-list/_components/search-filters/search-filters/search.service";

const PRODUCT_CONTROLLER: string = 'Product';

@Injectable({
  providedIn: 'root'
})
export class ProductController {

  private listCache: ProductListCache[] = [];

  constructor(private webapi: CtWebapiService) {
  }

  list(params: FilterProduct = new FilterProduct(), bypassCache: boolean = false): Promise<Array<Product>> {

    const request: DataRequest = new DataRequest(`/${PRODUCT_CONTROLLER}/List`, {hideSpinner: true});
    const CACHE_KEY: string = JSON.stringify(params);

    return new Promise<Array<Product>>((resolve) => {

      const cachedElement: ProductListCache | undefined = this.listCache.find((elm: ProductListCache) => elm.key === CACHE_KEY);

      if (cachedElement && !bypassCache) {

        resolve(cachedElement.list);
        return;

      }

      if (!params.CategoryCodes) params.CategoryCodes = []; // Le API hanno bisogno di un array per forza

      this.webapi
        .post(request, params)
        .subscribe((response: CtWebapiGenericResponse<any>) => {

          if (params.CategoryCodes?.length === 0) params.CategoryCodes = null; // se il parametro in ingresso è del SearchService, lo rimetto a null essendo che viene passato per riferimento

          this.listCache.push(new ProductListCache(CACHE_KEY, response.Result));
          resolve(<Array<Product>>response.Result)

        });

    });

  }

  get(productId: number, bypassCache: boolean = false): Promise<Product> {

    const request: DataRequest = new DataRequest(`/${PRODUCT_CONTROLLER}/Get`, {productId, hideSpinner: true});

    return new Promise<Product>((resolve) => {

      const cachedElement: Product | undefined = this.listCache.map((productListCache: ProductListCache) => productListCache.list).flat().find((product: Product) => product.Id === productId);

      if (cachedElement && !bypassCache) {

        resolve(cachedElement);
        return;

      }

      this.webapi
        .get(request)
        .subscribe((response: CtWebapiGenericResponse<any>) => {

          resolve(<Product>response.Result)

        })

    });

  }

  updateFavorite(productId: number): Promise<CtWebapiGenericResponse<any>> {

    const request: DataRequest = new DataRequest(`/${PRODUCT_CONTROLLER}/UpdateFavorite`, {productId, hideSpinner: true});

    return new Promise<CtWebapiGenericResponse<any>>((resolve) => {

      this.webapi
        .get(request)
        .subscribe((response: CtWebapiGenericResponse<any>) => resolve(response))

    });

  }

  purchasedSpecialDiscount(discountId: number): Observable<CtWebapiGenericResponse<any>> {

    const request: DataRequest = new DataRequest(`/${PRODUCT_CONTROLLER}/PurchasedSpecialDiscount`, {discountId});

    return this.webapi.get(request);

  }

}

class ProductListCache {

  constructor(
    public key: string,
    public list: Product[]
  ) {
  }

}
