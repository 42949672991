import {LOCALE_ID, NgModule} from '@angular/core';
import {routes} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LayoutModule} from './layout/layout.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {AgmCoreModule} from '@agm/core';
import {CategoryController} from './core/controllers/category.controller';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {AccountController} from './core/controllers/account.controller';
import {ProductController} from './core/controllers/product.controller';
import {
  CtAuthenticationModule,
  CtAuthenticationSetup,
  FcmConfiguration,
  SocialOAuthConfiguration
} from '@ctsolution/ct-authentication';
import {ProfileController} from './core/controllers/profile.controller';
import {environment} from 'src/environments/environment';
import {LoaderModule} from './components/loader/loader.module';
import {SubCategoryController} from './core/controllers/subcategory.controller';
import {BusinessProductController} from './core/controllers/business-product.controller';
import {CtWebapiModule} from '@ctsolution/ct-webapi';
import {CtFrameworkModule, HttpLoaderFactory} from '@ctsolution/ct-framework';
import {NotificationController} from './core/controllers/notification.controller';
import {StatisticsController} from './core/controllers/statistics.controller';
import {ApplicationConfig, CtWebviewModule} from "@ctsolution/ct-webview";
import {MarketPlaceController} from './core/controllers/market-place.controller';
import '@angular/common/locales/global/it'
import {NgDialogAnimationService} from "ng-dialog-animation";
import {LocationController} from "./core/controllers/location.controller";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {CtFaqModule} from "./components/ct-framework/ct-faq/ct-faq.module";
import {HomeModule} from "./pages/home/home.module";
import {SupportModule} from './pages/support/support.module';
import {CtFileManagementService} from "@ctsolution/ct-file-management";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {GuideModule} from './pages/guide/guide.module';

const AUTHENTICATION_CONFIG = CtAuthenticationSetup
  .create(environment.projectName, environment.dev.REST_API_SERVER)
  .setRedirectUrl('/authentication/sign-in')
  .setFCMConfiguration(
    FcmConfiguration
      .create()
      .setEnabled(true)
  )
  .setFacebookOAuthConfiguration(new SocialOAuthConfiguration('2444088245742260'))
  .setGoogleOAuthConfiguration(new SocialOAuthConfiguration('723850279276-37hh3hf8japgid8921g4lljknmraj844.apps.googleusercontent.com'))
  .setAppleOAuthConfiguration(new SocialOAuthConfiguration('com.victoriacard.bundle.signin'))

const APPLICATION_CONFIG: ApplicationConfig = {
  Android: {
    package: 'com.digitech.victoriacardapp'
  },
  Apple: {
    scheme: 'victoriacard',
    storeLink: 'https://apps.apple.com/it/app/victoria-card/id1565852147'
  }
}

export const APP_CONTACTS = {

  VAT: '02719420990',
  address: 'Via Corsica 6, 16128 Genova',
  tel: 3452115795,
  email: 'info@victoriacard.it'

};

export const LANGUAGE_CODES: string[] = ['it', 'en'];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    MatDialogModule,
    LayoutModule,
    FlexLayoutModule,
    LoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    CtFrameworkModule.forRoot(),
    CtAuthenticationModule.setup(AUTHENTICATION_CONFIG),
    CtWebapiModule.setup(environment.dev.REST_API_SERVER),
    CtWebviewModule.setup(APPLICATION_CONFIG),
    RouterModule.forRoot(routes),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDNRpF4rs2aSlaeJd4XKVPb_ppqHSAxwMA',
      libraries: ["places"]
    }),
    CtFaqModule,
    SupportModule,
    GuideModule,
    HomeModule
  ],
  providers: [
    AccountController,
    BusinessProductController,
    CategoryController,
    ProductController,
    ProfileController,
    SubCategoryController,
    NotificationController,
    StatisticsController,
    MarketPlaceController,
    LocationController,
    {provide: LOCALE_ID, useValue: "it-IT"},
   // NgDialogAnimationService,
    CtFileManagementService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}


