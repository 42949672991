import {Component, Input, OnInit} from '@angular/core';
import { BusinessReport } from 'src/app/core/interfaces/business-report';

@Component({
  selector: 'app-card-recap',
  templateUrl: './card-recap.component.html',
  styleUrls: ['./card-recap.component.scss']
})
export class CardRecapComponent implements OnInit {

  @Input() reportData: Array<BusinessReport> = new Array<BusinessReport>();


  constructor() {
  }

  ngOnInit() {
  }

}


