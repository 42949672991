import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CtMapsService} from '@ctsolution/ct-maps';
import {Product} from 'src/app/core/classes/product';
import {GOOGLE_MAPS_STYLE} from "./maps.style";
import {ILocation, LocationController} from "../../../../../../core/controllers/location.controller";
import {JwtService} from "../../../../../../core/library/jwt.service";
import {animate, animateChild, query, state, style, transition, trigger} from "@angular/animations";

// Dichiarazione della costante delle altezze
const HEIGHT_INITIAL = '450px';
const HEIGHT_EXPANDED = 'calc(100vh - 140px)';
const HEIGHT_COLLAPSED = '250px';

export interface GoogleAreaBounce {
  north: any;
  east: any;
  south: any;
  west: any;
}

export const DEFAULT_COORDINATES = {
  lat: 44.405650,
  lng: 8.946256
};

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
  providers: [CtMapsService],
  animations: [
    trigger('mapResize', [
      state('initial', style({height: HEIGHT_INITIAL})), // Altezza standard (default)
      state('expanded', style({height: HEIGHT_EXPANDED})), // Altezza quasi fullscreen
      state('collapsed', style({height: HEIGHT_COLLAPSED})), // Altezza minima
      transition('initial <=> expanded', [
        animate('300ms ease-in-out'),
        query('.map', animateChild())
      ]),
      transition('initial <=> collapsed', [
        animate('300ms ease-in-out'),
        query('.map', animateChild())
      ]),
      transition('expanded <=> collapsed', [
        animate('300ms ease-in-out'),
        query('.map', animateChild())
      ])])
  ]
})

export class MapsComponent implements OnInit {

  @Input() products: Array<Product> = new Array<Product>();
  @Input() isMapExpanded: boolean | null = null;

  @Output() onFocus: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() boundsChange: EventEmitter<GoogleAreaBounce> = new EventEmitter<GoogleAreaBounce>();

  protected readonly HEIGHT_INITIAL = HEIGHT_INITIAL;
  protected readonly HEIGHT_EXPANDED = HEIGHT_EXPANDED;
  protected readonly HEIGHT_COLLAPSED = HEIGHT_COLLAPSED;

  private timeoutId: any;

  viewModel: any = {

    coordinates: DEFAULT_COORDINATES,
    zoom: 14,
    style: GOOGLE_MAPS_STYLE,
    selected: null

  }

  constructor(
    private ctMapsService: CtMapsService,
    private locationController: LocationController,
    private jwt: JwtService) {
  }

  ngOnInit() {

    this.setCurrentLocation();

  }

  private setCurrentLocation() {

    this.ctMapsService
      .getPosition()
      .then((result: any | null) => {

        this.viewModel.coordinates.lat = result?.lat ?? 0;
        this.viewModel.coordinates.lng = result?.lng ?? 0;

        this.setup();

      });

  }

  private async setup() {

    const locationOid = await this.jwt.getLocationOid();

    this.locationController
      .list()
      .then((locations: ILocation[]) => {

        let location: ILocation | undefined = locations.find((location: ILocation) => location.Oid === locationOid);

        if (location) {

          this.viewModel.coordinates.lat = location.Latitude;
          this.viewModel.coordinates.lng = location.Longitude;

        }

      });

  }

  getMarkerData = (product: Product): string => {

    if (this.viewModel.selected === product) {

      return '/assets/images/icons/marker-category/selected/' + product.Category?.Code + '-marker.svg';

    }

    return '/assets/images/icons/marker-category/' + product.Category?.Code + '-marker.svg'

  };

  getCurrentLocationMarkerIcon = () => '/assets/images/icons/location.svg'

  focus = (product: Product) => {

    this.viewModel.selected = product;
    this.onFocus.emit(product);

  }


  lastCenter: any | null = null;

  onBoundsChange(event: any): void {

    const center = event.getCenter().toUrlValue();

    if (this.lastCenter === center) return;

    this.lastCenter = center;

    const ne = event.getNorthEast();
    const sw = event.getSouthWest();

    const area = {
      north: ne.lat(),
      east: ne.lng(),
      south: sw.lat(),
      west: sw.lng()
    };

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.boundsChange.emit(area);
    }, 1000);

  }

}




