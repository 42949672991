import {Component} from '@angular/core';
import {CtOAuthService} from '@ctsolution/ct-authentication';
import {TranslateService} from '@ngx-translate/core';
import {CtWebviewService} from "@ctsolution/ct-webview";
import {JwtService} from "./core/library/jwt.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../environments/environment";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private CTWebviewService: CtWebviewService,
    private jwt: JwtService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private _oauth: CtOAuthService,
    private snackbar: MatSnackBar
  ) {

    this.CTWebviewService.subscribeToInAppOpeningIntents();
    this.setLang();

  }

  ngOnInit() {

    if (!environment.production) {

      this.snackbar.open(`Modalità di Sviluppo Attiva`, "X", {duration: 3000});

    }

  }

  async setLang() {

    //lingua di default it
    const lang = await this.jwt.getDefaultLang();

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(lang);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(lang);

  }

}
