import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CtButtonParameters} from '@ctsolution/ct-framework';


@Component({
  selector: 'app-dialog-leave-review',
  templateUrl: './dialog-leave-review.component.html',
  styleUrls: ['./dialog-leave-review.component.scss']
})
export class DialogLeaveReviewComponent implements OnInit {

  form!: FormGroup;

  saveReview: CtButtonParameters = CtButtonParameters
    .create(() => this.matDialogRef.close())
    .setCSS("cardButton box-shadow", true)
    .setContent("CTBUTTON.SEND")

  constructor(
    private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<DialogLeaveReviewComponent>,
  ) {
  }

  ngOnInit() {

    this.form = this.formBuilder.group({

      description: new FormControl('')

    });
  }


}
