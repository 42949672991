import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QrScannerComponent} from './qr-scanner/qr-scanner.component';
import {CtButtonModule, CtSkeletonModule} from "@ctsolution/ct-framework";
import {ScannerModule} from "./steps/scanner/scanner.module";
import {DiscountsManagerModule} from "./steps/discounts-manager/discounts-manager.module";
import {ScannerResultModule} from "./steps/scanner-result/scanner-result.module";

@NgModule({
  declarations: [
    QrScannerComponent
  ],
  imports: [
    CommonModule,
    CtButtonModule,
    ScannerModule,
    DiscountsManagerModule,
    ScannerResultModule,
    CtSkeletonModule
  ],
  exports: [QrScannerComponent]
})
export class QrScannerModule {
}
