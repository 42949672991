import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {ChangePswModule} from "./change-psw/change-psw.module";
import {PersonalAvatarModule} from "./personal-avatar/personal-avatar.module";
import {ProfileComponent} from './profile/profile.component';
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacySnackBarModule as MatSnackBarModule} from "@angular/material/legacy-snack-bar";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatIconModule} from "@angular/material/icon";
import {ProfileFormModule} from "./profile/_components/profile-form/profile-form.module";
import {ProfileMenuModule} from "./profile/_components/profile-menu/profile-menu.module";
import {PersonalDataModule} from "./profile/_components/personal-data/personal-data.module";
import {ProfileFormComponent} from "./profile/_components/profile-form/profile-form.component";
import {DeleteProfileComponent} from "./profile/_components/delete-profile/delete-profile.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {FormInputModule} from "../../../components/ct-framework/form-input/form-input.module";

@NgModule({
  declarations: [
    ProfileComponent,
    DeleteProfileComponent
  ],
  imports: [
    CommonModule,
    PersonalDataModule,
    ProfileMenuModule,
    CtButtonModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    FormInputModule,
    CtFrameworkModule,
    ProfileFormModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RouterModule.forChild([
      {
        path: 'edit',
        component: ProfileFormComponent
      },
      {
        path: 'avatar',
        loadChildren: () => PersonalAvatarModule
      },
      {
        path: 'password',
        loadChildren: () => ChangePswModule
      },
      {
        path: '',
        component: ProfileComponent
      }
    ])
  ]
})
export class ProfileModule {
}
