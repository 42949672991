<div class="info-detail-card-cntr p-footer container">

  <ng-container
    *ctSkeleton="!productViewModel;
           repeat: 6;
           className: 'search-detail-skeleton';">

    <div class="mb-5 box-shadow position-relative carousel-cntr">

      <app-manage-product-button
        *ngIf="roleHelperService.isCustomer()"
        [product]="productViewModel!"></app-manage-product-button>

      <ng-container *ngIf="logo && logo.length > 0">

        <div [ngStyle]="{'background': 'url(' + this.logo[0].PreviewUrl +') no-repeat center'}"
             class="logo-image box-shadow"></div>

      </ng-container>

      <ct-carousel-ivy [parameter]="carouselParameter"></ct-carousel-ivy>

    </div>

    <div class="mb-4">

      <app-info-section [product]="productViewModel!"></app-info-section>

      <app-content-area
        *ngIf="contentAreaViewModel"
        [configuration]="contentAreaViewModel"></app-content-area>

      <div *ngIf="productViewModel?.SubCategories">

        <mat-chip-list>

          <mat-chip disableRipple *ngFor="let subCategory of productViewModel?.SubCategories">

            {{'SUBCATEGORY.' + subCategory.Code | translate}}

          </mat-chip>

        </mat-chip-list>

      </div>

    </div>

  </ng-container>

</div>
