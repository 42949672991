import {Component, OnInit} from '@angular/core';
import {MatLegacyTabChangeEvent as MatTabChangeEvent} from '@angular/material/legacy-tabs';
import {ChartConfiguration, ChartData, ChartType} from 'chart.js';
import {StatisticsController} from 'src/app/core/controllers/statistics.controller';
import {BusinessReport, IReportingPeriod} from 'src/app/core/interfaces/business-report';

const SELECT_SEPARATOR: string = "|";
const TODAY_CODE: string = "TODAY";
const DATASET_STANDARD_VALUES = {
  backgroundColor: [
    'rgba(255, 108, 0, 0.2)'
  ],
  borderColor: [
    'rgb(255, 108, 0)'
  ],
  borderWidth: 1,
  borderRadius: 5,
  label: '€'
}

@Component({
  selector: 'app-business-report',
  templateUrl: './business-report.component.html',
  styleUrls: ['./business-report.component.scss']
})
export class BusinessReportComponent implements OnInit {

  matTabLabels: Array<ITabConfiguration> = [
    {
      code: TODAY_CODE,
      labelKey: "TODAY",
      enableSelect: false
    },
    {
      code: "MONTHS",
      labelKey: "MONTHS",
      enableSelect: true
    }
  ];

  viewModel: IInternalViewmodel = {

    gainOverTimeData: [],
    todayData: [],
    totalEarnings: 0,
    totalCustomers: 0,
    statisticTransactions: [],
    monthsFilter: [],
    monthFilterSelected: undefined

  }

  //charts

  chartType: ChartType = 'bar';

  chartData: ChartData = {
    labels: [],
    datasets: []
  }

  chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false
        },
      },
      y: {
        grid: {
          display: false
        },
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };

  constructor(private statisticsController: StatisticsController) {
  }

  ngOnInit() {

    this.getStatistics();

  }

  getStatistics() {

    this.statisticsController
      .gainOverTime()
      .then((data: { Days: Array<BusinessReport>; Today: Array<any> }) => {

        this.viewModel.gainOverTimeData = data.Days;
        this.viewModel.todayData = this.viewModel.statisticTransactions = data.Today;

        this.setupGraph(this.viewModel.gainOverTimeData);
        this.setupOverviewCards(this.viewModel.gainOverTimeData);

        this.viewModel.gainOverTimeData.forEach(data => {

          const month = this.viewModel.monthsFilter.find((months: IReportingPeriod) => months.month == data.MonthToUpper && months.year == data.Year);

          if (!month) {

            this.viewModel.monthsFilter.push({month: data.MonthToUpper, year: data.Year});

          }

        });

        if (this.viewModel.monthsFilter && this.viewModel.monthsFilter.length > 0) {

          const lastMoth = this.viewModel.monthsFilter[this.viewModel.monthsFilter.length - 1];

          if (lastMoth) {

            this.viewModel.monthFilterSelected = this.createSelectValue(lastMoth);

          }

        }

      });

  }

  createSelectValue = (data: IReportingPeriod): string => `${data.month}${SELECT_SEPARATOR}${data.year}`;

  //cambio dati al click delle tab

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {

    const config = this.matTabLabels[tabChangeEvent.index]

    const month: IReportingPeriod | undefined = this.parseSelectValue(this.viewModel.monthFilterSelected);

    if (month) {

      this.setStatisticTransactionList(month.month, month.year, config.code == TODAY_CODE);

    }

  }

  private parseSelectValue(value?: string): IReportingPeriod | undefined {

    if (value == undefined || value.includes(SELECT_SEPARATOR) == false) {

      return undefined;

    }

    const split = value.split(SELECT_SEPARATOR);

    if (split.length != 2) {

      return undefined;

    }

    return {month: split[0], year: Number(split[1])};

  }

  changeMonthFilterSelect(event: any) {

    this.viewModel.monthFilterSelected = event;
    const month = this.parseSelectValue(this.viewModel.monthFilterSelected);

    this.setStatisticTransactionList(month?.month, month?.year);

  }

  private setStatisticTransactionList(month?: string, year?: number, isToday: boolean = false) {

    if (isToday) {

      this.viewModel.statisticTransactions = this.viewModel.todayData;
      return;

    }

    this.viewModel.statisticTransactions = this.viewModel.gainOverTimeData.filter(data => data.Year == year && data.MonthToUpper == month);

  }

  setupGraph(data: Array<BusinessReport>) {

    this.chartData.labels = this.viewModel.gainOverTimeData.map((x: BusinessReport) => new Date(x.CreateAt).toLocaleDateString());

    const newObject = {
      data: this.viewModel.gainOverTimeData.map((x: BusinessReport) => x.Amount),
      ...DATASET_STANDARD_VALUES
    }

    this.chartData.datasets.push(newObject);

  }

  setupOverviewCards(data: Array<BusinessReport>) {

    this.viewModel.totalEarnings = data.map((x: BusinessReport) => x.Amount).reduce((prev: number, curr: number) => prev + curr, this.viewModel.totalEarnings);
    this.viewModel.totalCustomers = data.map((x: BusinessReport) => x.CustomersReached).reduce((prev: number, curr: number) => prev + curr, this.viewModel.totalCustomers);

  }

}

interface IInternalViewmodel {
  gainOverTimeData: Array<BusinessReport>;
  todayData: Array<BusinessReport>;
  totalEarnings: number;
  totalCustomers: number;
  statisticTransactions: Array<BusinessReport>;
  monthsFilter: Array<IReportingPeriod>;
  monthFilterSelected?: string;
}

interface ITabConfiguration {
  enableSelect: boolean;
  code: string;
  labelKey: string;
}
