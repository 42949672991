import {Injectable} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {

  drawer?: MatDrawer

  constructor() {
  }

  public setDrawer = (drawer: MatDrawer) => this.drawer = drawer;

  public toggle = () => this.drawer?.toggle();

}
