<div class="container h-100">

  <ng-container
    *ctSkeleton="!viewModel.scannerEnabled;
           repeat: 3;
           className: 'qr-scanner-skeleton';">

    <div class="qr-scanner-cntr">

      <div class="flipper-container" [class.flipped]="transaction">

        <div class="front-side">

          <app-scanner [(transaction)]="transaction"></app-scanner>

        </div>

        <div class="back-side">

          <ng-container
            *ngIf="STATUS.Bind === transaction?.Status || STATUS.Purchased === transaction?.Status; else resultTemplate">

            <app-discounts-manager [(transaction)]="transaction"></app-discounts-manager>

          </ng-container>

          <ng-template #resultTemplate>

            <app-scanner-result [(transaction)]="transaction"></app-scanner-result>

          </ng-template>

        </div>

      </div>

    </div>

  </ng-container>

</div>
