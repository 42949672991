<div class="guide-cntr">

  <div class="d-flex justify-content-end p-4">

    <mat-icon class="primary-white close-icon" (click)="close()">close</mat-icon>

  </div>

  <div class="guide-card-cntr">

    <ct-carousel-ivy *ngIf="carouselParameter" [parameter]="carouselParameter"></ct-carousel-ivy>

  </div>

</div>

